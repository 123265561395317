import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { AdminTopMenu } from 'components/common';
import { DatasetsSearchPanel, DatasetsTable, EditDatasetModal, UploadDatasetModal } from 'components/admin/datasets';

import { isEmpty } from 'helpers';
import { useStore } from 'store';

const DatasetsPage = observer(() => {
  const { AuthStore, DatasetStore, GroupStore } = useStore();
  const { isAdmin } = AuthStore;
  const { datasets, getDatasets, getUploadOptions, uploadOptions } = DatasetStore;
  const { groupOptions, getGroups } = GroupStore;

  // Get data for datasets table
  useEffect(() => {
    getDatasets();
  }, [getDatasets]);

  // Get upload dataset options
  useEffect(() => {
    if (isEmpty(uploadOptions.dataset)) {
      getUploadOptions();
    }
  }, [getUploadOptions, uploadOptions]);

  // Get group options
  useEffect(() => {
    if (isEmpty(groupOptions)) {
      getGroups();
    }
  }, [getGroups, groupOptions]);

  if (!datasets || !isAdmin) {
    return;
  }

  return (
    <div className='datasets_page_container page_container'>
      <AdminTopMenu />
      <EditDatasetModal />
      <UploadDatasetModal />
      <div className='page_wrapper max_width'>
        <div className='page_main'>
          <DatasetsSearchPanel />
          <DatasetsTable />
        </div>
      </div>
    </div>
  );
});

export default DatasetsPage;
