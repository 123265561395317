import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

import Confirmation from 'components/common/modals/confirmation/Confirmation';

import { MODALS } from 'constants';
import { isEmpty } from 'helpers';
import { useStore } from 'store';

import './GroupsTable.scss';

const GroupsTable = observer(() => {
  const { GroupStore, ModalStore } = useStore();
  const { groups, groupSearch, deleteGroup, setActiveGroup } = GroupStore;
  const { isCreateGroupOpen, isEditGroupOpen, toggleModal } = ModalStore;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [renderData, setRenderData] = useState([]);
  const [deleteId, setDeleteId] = useState();

  const openConfirmationModal = (id) => () => {
    setDeleteId(id);
    toggleModal(MODALS.CONFIRMATION);
  };

  const onDeleteGroup = () => () => {
    deleteGroup(deleteId);
    toggleModal(MODALS.CONFIRMATION);
  };

  const onSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditModal = (groupId) => () => {
    setActiveGroup(groupId);
    toggleModal(MODALS.EDIT_GROUP);
  };

  const prepareData = useCallback(() => {
    const dataCopy = [...Object.values(groups)];
    const filteredGroups = dataCopy.filter((group) => group.name.toLowerCase().includes(groupSearch));
    if (order === 'asc') {
      filteredGroups.sort((a, b) => a[orderBy].localeCompare(b[orderBy], undefined, { numeric: true }));
    } else {
      filteredGroups.sort((a, b) => b[orderBy].localeCompare(a[orderBy], undefined, { numeric: true }));
    }
    setRenderData(filteredGroups);
  }, [groups, groupSearch, order, orderBy]);

  const renderUsers = (data) => {
    if (isEmpty(data)) {
      return <Typography variant='small_title'>No Users Attached</Typography>;
    }
    return (
      <ul className='group_table_details_column'>
        <Typography variant='small_title'>Users</Typography>
        {data.map((user, index) => {
          return <li key={index}>{user.email}</li>;
        })}
      </ul>
    );
  };

  const renderDatasets = (data) => {
    if (isEmpty(data)) {
      return <Typography variant='small_title'>No Datasets Attached</Typography>;
    }
    return (
      <ul className='group_table_details_column'>
        <Typography variant='small_title'>Datasets</Typography>
        {data.map((dataset, index) => {
          return <li key={index}>{dataset.display_name}</li>;
        })}
      </ul>
    );
  };

  useEffect(() => {
    if (!isEditGroupOpen) {
      prepareData();
    }
  }, [isEditGroupOpen, prepareData]);

  useEffect(() => {
    if (!isCreateGroupOpen) {
      prepareData();
    }
  }, [isCreateGroupOpen, prepareData]);

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow className='group_table_row' hover>
          <TableCell>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon color='primary' /> : <KeyboardArrowRightOutlinedIcon color='primary' />}
            </IconButton>
          </TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell align='center'>{row.users_count}</TableCell>
          <TableCell align='center'>{row.datasets_count}</TableCell>
          <TableCell align='center' className='group_table_action_cell'>
            {!row.admin_group && !row.default_group && (
              <>
                <Button
                  className='group_table_edit'
                  color='primary'
                  onClick={openEditModal(row.id)}
                  startIcon={<EditIcon color='primary' />}
                >
                  Edit
                </Button>
                <Button
                  className='group_table_delete'
                  color='error'
                  onClick={openConfirmationModal(row.id)}
                  startIcon={<DeleteIcon color='error' />}
                >
                  Delete
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
        <TableRow className='group_table_row'>
          <TableCell className='group_table_collapsible_cell' colSpan={6}>
            <Collapse in={open} unmountOnExit>
              <div className='group_table_details_container'>
                {renderUsers(row.users)}
                {renderDatasets(row.datasets)}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Confirmation
        confirmText='Delete'
        onConfirm={onDeleteGroup()}
        title='Are you sure you want to delete this record?'
      />
      <TableContainer className='group_table_container' component={Paper}>
        <Table className='group_table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={onSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'># Users</TableCell>
              <TableCell align='center'># Datasets</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default GroupsTable;
