import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { Collapse, Field } from 'components/common';

import { COLUMN_NAMES, FOUR_WAY_SELECTED_GENES_ROWS as ROWS } from 'constants';
import { createCsv, downloadFile, isEmpty } from 'helpers';
import { useStore } from 'store';

import './FourWayGeneTable.scss';

const FourWayGeneTable = observer(() => {
  const { FourWayStore } = useStore();
  const { selectedGenes, values } = FourWayStore;

  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('GENE');
  const [renderData, setRenderData] = useState([]);
  const [dataLength, setDataLength] = useState(0);

  const onFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const onSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const renderCellValue = (value) => {
    if (value === 0 || typeof value !== 'number') {
      return value;
    }
    if (!value) {
      return '';
    }
    return value.toFixed(2);
  };

  const downloadTable = () => () => {
    const fileName = values.xComparison + ' vs. ' + values.yComparison + '.csv';
    const tableData = createCsv(selectedGenes);
    downloadFile(tableData, fileName);
  };

  const renderHeader = () => {
    const tableHeader = Object.keys(selectedGenes[0]);
    const geneNameKey = tableHeader.includes('GENE') ? 'GENE' : 'GENE';
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'GENE'}
              direction={orderBy === 'GENE' ? order : 'asc'}
              onClick={onSort('GENE')}
            >
              Gene
            </TableSortLabel>
          </TableCell>
          <TableCell align='center' colSpan={tableHeader.length - 1}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Field label='Filter...' onChange={onFilterChange} value={filter} />
          </TableCell>
          {tableHeader.map((column, index) => {
            if (column === 'QUADRANT' || column === geneNameKey) {
              return null;
            }
            return (
              <TableCell align='center' key={index}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={onSort(column)}
                >
                  {COLUMN_NAMES[column] || column}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const renderBody = () => {
    return renderData.map((row, index) => {
      const rowData = Object.keys(row);
      const geneNameKey = row.hasOwnProperty('Gene_Name') ? 'Gene_Name' : 'GENE';
      return (
        <TableRow key={index}>
          <TableCell className='gene_name_cell'>{row[geneNameKey]}</TableCell>
          {rowData.map((cell, cellIndex) => {
            if (cell === 'QUADRANT' || cell === geneNameKey) {
              return null;
            }
            return (
              <TableCell align='center' key={cellIndex}>
                {renderCellValue(row[cell])}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  const prepareData = useCallback(() => {
    const dataCopy = [...selectedGenes];
    const filteredGenes = dataCopy.filter((gene) => gene.GENE.toLowerCase().includes(filter.toLowerCase()));
    setDataLength(filteredGenes.length);
    if (typeof parseFloat(filteredGenes[0]?.[orderBy]) === 'number' && !isNaN(filteredGenes[0]?.[orderBy])) {
      if (order === 'asc') {
        filteredGenes.sort((a, b) => a[orderBy] - b[orderBy]);
      } else {
        filteredGenes.sort((a, b) => b[orderBy] - a[orderBy]);
      }
    } else {
      if (order === 'asc') {
        filteredGenes.sort((a, b) => a[orderBy].localeCompare(b[orderBy]));
      } else {
        filteredGenes.sort((a, b) => b[orderBy].localeCompare(a[orderBy]));
      }
    }
    const pageData = filteredGenes.slice(startIndex, endIndex);
    setRenderData(pageData);
  }, [endIndex, filter, order, orderBy, selectedGenes, startIndex]);

  useEffect(() => {
    setPage(0);
  }, [selectedGenes]);

  useEffect(() => {
    setStartIndex(page * ROWS);
    setEndIndex(page * ROWS + ROWS);
  }, [page]);

  useEffect(() => {
    if (!isEmpty(selectedGenes)) {
      prepareData();
    }
  }, [selectedGenes, prepareData]);

  if (isEmpty(selectedGenes)) {
    return null;
  }

  return (
    <Collapse open title='Selected Genes'>
      <TableContainer className='four_way_table_container' component={Paper}>
        <Table className='four_way_table' size='small'>
          {renderHeader()}
          <TableBody>{renderBody()}</TableBody>
        </Table>
      </TableContainer>
      <div className='four_way_table_actions'>
        <Button color='primary' onClick={downloadTable()} startIcon={<DownloadIcon />} variant='outlined'>
          Download
        </Button>
        <TablePagination
          component='div'
          count={dataLength}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </Collapse>
  );
});

export default FourWayGeneTable;
