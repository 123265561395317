import { observer } from 'mobx-react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';

import { useStore } from 'store';

import './Snack.scss';

const Snack = observer(() => {
  const { SnackStore } = useStore();
  const { closeSnack, isSnackOpened, message, severity } = SnackStore;

  // const action = (
  //   <>
  //     <Button color='secondary' size='small' onClick={() => closeSnack()}>
  //       UNDO
  //     </Button>
  //     <IconButton size='small' aria-label='close' color='inherit' onClick={() => closeSnack()}>
  //       <CloseIcon fontSize='small' />
  //     </IconButton>
  //   </>
  // );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={() => closeSnack()}
      open={isSnackOpened}
    >
      <Alert onClose={() => closeSnack()} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
});

export default Snack;
