import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useOktaAuth } from '@okta/okta-react';

import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

import { useStore } from 'store';

const HomePage = observer(() => {
  const { authState, oktaAuth } = useOktaAuth();
  const { AuthStore } = useStore();
  const { isAdmin, setAccessToken } = AuthStore;

  useEffect(() => {
    if (authState?.isAuthenticated) {
      setAccessToken(authState?.accessToken?.accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const renderButtons = () => {
    return (
      <>
        {authState?.isAuthenticated ? (
          <Button className='login_button' onClick={() => logout()} variant='contained'>
            Logout
          </Button>
        ) : (
          <Button className='logout_button' onClick={() => login()} variant='contained'>
            Login
          </Button>
        )}
        {isAdmin ? (
          <div>
            <NavLink className='admin_button' to='/admin/groups' variant='contained'>
              Admin Page
            </NavLink>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className='home_page_container'>
      <div className='home_page_button_container'>{renderButtons()}</div>
    </div>
  );
});

export default HomePage;
