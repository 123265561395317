import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Collapse, Field } from 'components/common';

import { COLUMN_NAMES } from 'constants';
import { useStore } from 'store';

import './FourWayTable.scss';
import { createCsv, downloadFile, isEmpty } from 'helpers';

const FourWayTable = observer(({ tableFilter, onFilterChange, onPageChange, page }) => {
  const { FourWayStore } = useStore();
  const { downloadTableData, tableData, tableHeader, values } = FourWayStore;

  const downloadTable = () => async () => {
    const response = await downloadTableData(
      values.datatype,
      values.xDataset,
      values.xComparison,
      values.yDataset,
      values.yComparison,
      tableData.count
    );
    const data = createCsv(response.data.data);
    const fileName = values.xComparison + ' vs. ' + values.yComparison + '.csv';
    downloadFile(data, fileName);
  };

  const renderCellValue = (value) => {
    if (value === 0 || typeof value !== 'number') {
      return value;
    }
    if (!value) {
      return '';
    }
    return value.toFixed(2);
  };

  const renderHeader = () => {
    const geneNameKey = tableHeader?.includes('Gene_Name') ? 'Gene_Name' : 'GENE';
    return (
      <TableHead>
        <TableRow>
          <TableCell>Gene</TableCell>
          <TableCell align='center' colSpan={isEmpty(tableHeader) ? 2 : tableHeader?.length - 1}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Field label='Filter...' onChange={onFilterChange} value={tableFilter} />
          </TableCell>
          {tableHeader?.map((column, index) => {
            if (column === 'index' || column === geneNameKey) {
              return null;
            }
            return (
              <TableCell align='center' key={index}>
                {COLUMN_NAMES[column] || column}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const renderBody = () => {
    return tableData?.data?.map((row, index) => {
      const rowData = Object.keys(row);
      const geneNameKey = row.hasOwnProperty('Gene_Name') ? 'Gene_Name' : 'GENE';
      return (
        <TableRow key={index}>
          <TableCell className='gene_name_cell'>{row[geneNameKey]}</TableCell>
          {rowData.map((cell, cellIndex) => {
            if (cell === 'index' || cell === geneNameKey) {
              return null;
            }
            return (
              <TableCell align='center' key={cellIndex}>
                {renderCellValue(row[cell])}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  if (isEmpty(values.xComparison) || isEmpty(values.yComparison)) {
    return null;
  }

  return (
    <Collapse open title='4-Way Plot Data'>
      <TableContainer className='four_way_table_container' component={Paper}>
        <Table className='four_way_table' size='small'>
          {renderHeader()}
          <TableBody>{renderBody()}</TableBody>
        </Table>
      </TableContainer>
      <div className='four_way_table_actions'>
        <Button color='primary' onClick={downloadTable()} startIcon={<DownloadIcon />} variant='outlined'>
          Download
        </Button>
        <TablePagination
          component='div'
          count={tableData?.count || 0}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </Collapse>
  );
});

export default FourWayTable;
