import { observer } from 'mobx-react';
// import Plot from 'react-plotly.js';

import Typography from '@mui/material/Typography';
import { SourceTable } from 'components/main/target_snp';

import { EXCLUDED_CATEGORIES, SNP_PLOTS } from 'constants';
import { useStore } from 'store';

import './TableSection.scss';

const TableSection = observer(() => {
  const { TargetSnpStore } = useStore();
  const { tableData } = TargetSnpStore;

  const renderSource = (category) => {
    const sources = Object.keys(tableData[category]);
    return sources.map((source, index) => {
      const description = tableData[category][source].description;
      // const plot = tableData[category][source].plot;
      // const visualization = tableData[category][source].visualization;
      const table = tableData[category][source].data[0];
      return (
        <div className='target_snp_source_container' key={index}>
          <Typography>{source}</Typography>
          <div className='target_snp_description_container'>
            {description.map((item, index) => (
              <div className='target_snp_description' key={index}>
                <div>{item.explanation}</div>
                {item.link?.includes('http') ? (
                  <a className='target_snp_link' href={item.link} target='_blank' rel='noreferrer'>
                    {item.link}
                  </a>
                ) : (
                  item.link
                )}
              </div>
            ))}
          </div>
          <div className='target_snp_table_container'>
            <SourceTable data={table} fileName={category + '.' + source + '.csv'} />
            {/* {visualization.includes(SNP_PLOTS.BARPLOT) && (
              <Plot
                config={{ responsive: true }}
                data={plot?.data}
                key={index}
                layout={{ ...plot?.layout, autosize: true }}
                style={{ height: '1000px', width: '100%%' }}
                useResizeHandler
              />
            )} */}
          </div>
        </div>
      );
    });
  };

  const renderCategory = () => {
    const categories = Object.keys(tableData);
    return categories.map((category, index) => {
      if (EXCLUDED_CATEGORIES.includes(category)) {
        return null;
      }
      return (
        <div className='target_snp_category' key={index}>
          <Typography id={category} variant='title'>
            {category}
          </Typography>
          {renderSource(category)}
        </div>
      );
    });
  };

  if (!tableData) {
    return null;
  }

  return <div className='target_snp_table_section_container'>{renderCategory()}</div>;
});

export default TableSection;
