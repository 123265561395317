import { BACKEND_ROUTES } from 'constants';
import { backendUrlBuilder, isEmpty, makeRequest } from 'helpers';
import { makeAutoObservable, runInAction } from 'mobx';

export default class AuthStore {
  accessToken = '';
  accessTokenExpiresAt = null;
  groups = [];
  id = null;
  isAdmin = null;
  isAuthenticated = false;
  email = '';

  constructor() {
    makeAutoObservable(this);
  }

  getAccessToken = () => {
    return this.accessToken;
  };

  getUserData = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.USERS_INFO), { params: { email: this.email } });
    const data = response.data[0];
    const adminGroups = data.groups.filter((group) => group.admin_group);
    const isAdminGroupPresent = isEmpty(adminGroups) ? false : true;
    runInAction(() => {
      this.id = data.id;
      this.isAdmin = isAdminGroupPresent;
      this.groups = data.groups;
    });
    return response;
  };

  setAccessToken = (newToken) => {
    runInAction(() => {
      this.accessToken = newToken;
    });
  };

  setAccessTokenExpireDate = (newDate) => {
    runInAction(() => {
      this.accessTokenExpiresAt = newDate;
    });
  };

  setAuthStatus = (newStatus) => {
    runInAction(() => {
      this.isAuthenticated = newStatus;
    });
  };

  setUserEmail = (newEmail) => {
    runInAction(() => {
      this.email = newEmail;
    });
  };
}
