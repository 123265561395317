import Plot from 'react-plotly.js';

import { Collapse } from 'components/common';

import './Heatmap.scss';

const HeatmapPlot = ({ data, title }) => {
  if (!data) {
    return null;
  }

  return (
    <Collapse open title={title}>
      <div className='heatmap_container'>
        <Plot
          config={{ responsive: true }}
          data={data.data}
          layout={{ ...data.layout, autosize: false, ...{ margin: { b: 0, l: 0, r: 0, t: 0 } } }}
          style={{ height: '450px', width: '450px' }}
          useResizeHandler
        />
      </div>
    </Collapse>
  );
};

export default HeatmapPlot;
