import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const GroupIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <circle cx='10' cy='9' r='4' stroke={color} strokeWidth='2' fill='none' />
      <path
        d='M16 19C16 15.6863 13.3137 13 10 13C6.68629 13 4 15.6863 4 19'
        stroke={color}
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle cx='15.5' cy='12.5' r='2.5' stroke={color} strokeWidth='2' fill='none' />
      <path d='M20 19C20 16.7909 18.2091 15 16 15' stroke={color} strokeWidth='2' fill='none' strokeLinecap='round' />
    </SvgIcon>
  );
};
