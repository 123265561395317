import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const CynoIcon = ({ color = COLORS.MAIN, className }) => {
  return (
    <SvgIcon className={className}>
      <path
        d='M12.0701 18.225C10.9951 18.225 10.0826 17.9875 9.33258 17.5125C8.58258 17.0375 8.01008 16.3775 7.61508 15.5325C7.22508 14.6875 7.03008 13.71 7.03008 12.6C7.03008 11.49 7.22508 10.5125 7.61508 9.6675C8.01008 8.8225 8.58258 8.1625 9.33258 7.6875C10.0826 7.2125 10.9951 6.975 12.0701 6.975C13.3201 6.975 14.3476 7.2925 15.1526 7.9275C15.9626 8.5625 16.5201 9.4175 16.8251 10.4925L15.4676 10.8525C15.2476 10.0475 14.8551 9.4125 14.2901 8.9475C13.7251 8.4825 12.9851 8.25 12.0701 8.25C11.2651 8.25 10.5951 8.4325 10.0601 8.7975C9.52508 9.1625 9.12258 9.6725 8.85258 10.3275C8.58258 10.9775 8.44508 11.735 8.44008 12.6C8.43508 13.46 8.56758 14.2175 8.83758 14.8725C9.10758 15.5225 9.51258 16.0325 10.0526 16.4025C10.5926 16.7675 11.2651 16.95 12.0701 16.95C12.9851 16.95 13.7251 16.7175 14.2901 16.2525C14.8551 15.7825 15.2476 15.1475 15.4676 14.3475L16.8251 14.7075C16.5201 15.7775 15.9626 16.6325 15.1526 17.2725C14.3476 17.9075 13.3201 18.225 12.0701 18.225Z'
        fill={color}
      />
      <rect x='0.5' y='0.5' width='23' height='23' rx='4.5' stroke={color} fill='none' />
    </SvgIcon>
  );
};
