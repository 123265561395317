import { observer } from 'mobx-react';

import { ViolinPlot } from 'components/common';

import { isEmpty } from 'helpers';

const ViolinSection = observer(({ data, downloadViolin }) => {
  const renderViolins = () => {
    const renderData = Object.values(data).flat();
    return renderData.map((plot, index) => {
      let title = plot.gene.gene_name;
      if (plot.cell_type) {
        title += ' in ' + plot.cell_type;
      }
      return (
        <ViolinPlot
          cellType={plot.cell_type}
          data={plot.figure}
          downloadViolin={downloadViolin}
          geneId={plot.gene.gene_id}
          key={index}
          title={title}
        />
      );
    });
  };

  if (isEmpty(data)) {
    return null;
  }

  return <div className='violin_section_container'>{renderViolins()}</div>;
});

export default ViolinSection;
