import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Drawer from '@mui/material/Drawer';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';
import { EditGeneList, CreateGeneList } from 'components/common';

import { MODALS } from 'constants';
import { downloadFile, isEmpty } from 'helpers';
import { useStore } from 'store';

import './GeneList.scss';

const GeneList = observer(() => {
  const { GeneListStore, ModalStore } = useStore();
  const { deleteGeneList, genes, geneLists, getGeneListGenes, getGeneLists } = GeneListStore;
  const { isGeneListOpen, toggleGeneList, toggleModal } = ModalStore;

  const [activeList, setActiveList] = useState('');
  const [geneListSearch, setGeneListSearch] = useState('');
  const [geneSearch, setGeneSearch] = useState('');

  const onGeneListSearchChange = (event) => {
    setGeneListSearch(event.target.value);
  };

  const onGeneSearch = (event) => {
    setGeneSearch(event.target.value);
  };

  const onGeneListClick = (geneListName) => {
    getGeneListGenes(geneListName);
    setActiveList(geneListName);
  };

  const onCopyGeneList = () => {
    if (!isEmpty(genes)) {
      navigator.clipboard.writeText(genes);
    }
    return null;
  };

  const onDeleteGeneList = async () => {
    if (activeList) {
      const response = await deleteGeneList(activeList);
      if (response.status === 200) {
        setActiveList('');
      }
    }
  };

  const renderGeneLists = () => {
    if (isEmpty(geneLists)) {
      return null;
    }
    return geneLists
      .filter((list) => list.toLowerCase().includes(geneListSearch))
      .map((list, index) => {
        return (
          <div className='gene_list_name' key={index} onClick={() => onGeneListClick(list)}>
            {list}
          </div>
        );
      });
  };

  const renderGenes = () => {
    if (isEmpty(genes)) {
      return null;
    }
    return genes
      .filter((gene) => gene.toLowerCase().includes(geneSearch))
      .map((gene, index) => {
        return (
          <div className='gene_name' key={index}>
            {gene}
          </div>
        );
      });
  };

  const downloadList = () => {
    if (activeList) {
      const geneData = genes.join(',\n');
      const fileName = activeList + '.csv';
      downloadFile(geneData, fileName);
    }
  };

  const openEditModal = () => {
    if (activeList) {
      toggleModal(MODALS.EDIT_GENE_LIST);
    }
  };

  useEffect(() => {
    getGeneLists();
  }, [getGeneLists]);

  return (
    <Drawer anchor='right' open={isGeneListOpen} onClose={() => toggleGeneList()}>
      <EditGeneList initialName={activeList} />
      <CreateGeneList />
      <div className='gene_list_container'>
        <div className='gene_list_title'>
          <Typography variant='title'>Gene Lists</Typography>
        </div>
        <div className='gene_list_content'>
          <div className='gene_list_lists'>
            <div className='gene_list_lists_search_container'>
              <Field
                icon='search'
                iconPosition='end'
                label='Search for a list'
                onChange={onGeneListSearchChange}
                value={geneListSearch}
                variant='filled'
              />
            </div>
            <div className='gene_list_names'>{renderGeneLists()}</div>
            <div className='gene_list_add_container'>
              <Button
                className='gene_list_add'
                onClick={() => toggleModal(MODALS.CREATE_GENE_LIST)}
                startIcon={<AddIcon />}
                variant='outlined'
              >
                Add new list
              </Button>
            </div>
          </div>

          <div className='gene_list_info'>
            <div className='gene_list_info_search_container'>
              <Field
                className='grey_background'
                icon='search'
                iconPosition='end'
                label='Search for a gene'
                onChange={onGeneSearch}
                variant='filled'
                value={geneSearch}
              />
            </div>
            <div className='gene_list_info_action_container'>
              {!isEmpty(genes) && (
                <div className='gene_list_info_actions'>
                  <IconButton className='gene_list_action_icon' onClick={() => openEditModal()} color='primary'>
                    <EditIcon />
                  </IconButton>
                  <IconButton className='gene_list_action_icon' color='primary' onClick={() => downloadList()}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButton className='gene_list_action_icon' color='primary' onClick={() => onCopyGeneList()}>
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton className='gene_list_action_icon' color='primary' onClick={() => onDeleteGeneList()}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </div>
            <div className='gene_names_container'>{renderGenes()}</div>
          </div>
        </div>
      </div>
    </Drawer>
  );
});

export default GeneList;
