export const MODALS = {
  ADD_EXPERIMENT: 'add_experiment',
  CONFIRMATION: 'confirmation',
  CREATE_GROUP: 'create_group',
  EDIT_DATASET: 'edit_dataset',
  EDIT_GENE_LIST: 'edit_gene_list',
  EDIT_GROUP: 'edit_group',
  EDIT_QUERY_NAME: 'edit_query_name',
  EDIT_USER: 'edit_user',
  CREATE_GENE_LIST: 'create_gene_list',
  UPLOAD_DATASET: 'upload_dataset',
};
