import MainTheme from 'theme';

export const COLORS = {
  BACKGROUND_ACTIVE: MainTheme.palette.alectron.bacground_active,
  BACKGROUND: MainTheme.palette.alectron.background,
  BACKGROUND_PAGE: MainTheme.palette.alectron.background_page,
  ERROR: MainTheme.palette.alectron.error,
  GREY_BORDER: MainTheme.palette.alectron.grey_border,
  GREY_DARK: MainTheme.palette.alectron.grey_dark,
  GREY: MainTheme.palette.alectron.grey,
  MAIN_DARK: MainTheme.palette.alectron.main,
  MAIN_LIGHT: MainTheme.palette.primary.light,
  MAIN: MainTheme.palette.primary.main,
  TEXT_DARK: MainTheme.palette.alectron.text_dark,
  TEXT_LIGHT: MainTheme.palette.alectron.text_light,
  WHITE: MainTheme.palette.alectron.white,
};
