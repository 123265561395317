import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const TubeIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <path
        d='m6.2355 18.064-0.88361-0.4682 0.88361 0.4682zm11.656 0-0.8836 0.4682 0.8836-0.4682zm-3.817-7.2036 0.8836-0.4682-0.8836 0.4682zm-4.0223 0-0.88365-0.4682 0.88365 0.4682zm1.1163-4.86h1.7896v-2h-1.7896v2zm0 4.3918v-4.3918h-2v4.3918h2zm-4.0498 8.14 3.8171-7.2036-1.7672-0.9364-3.817 7.2036 1.7672 0.9364zm0.88361 1.4682c-0.75373 0-1.2365-0.8022-0.88361-1.4682l-1.7672-0.9364c-1.0587 1.998 0.38964 4.4046 2.6508 4.4046v-2zm8.122 0h-8.122v2h8.122v-2zm0.8836-1.4682c0.3529 0.666-0.1299 1.4682-0.8836 1.4682v2c2.2612 0 3.7096-2.4066 2.6508-4.4046l-1.7672 0.9364zm-3.817-7.2036 3.817 7.2036 1.7672-0.9364-3.817-7.2036-1.7672 0.9364zm-0.2328-5.3282v4.3918h2v-4.3918h-2zm2 4.3918h-2c0 0.3265 0.0799 0.648 0.2328 0.9364l1.7672-0.9364zm-5.7896 0 1.7672 0.9364c0.1528-0.2885 0.2327-0.61 0.2327-0.9364h-2zm3.7896-4.3918h2c0-1.1046-0.8954-2-2-2v2zm-1.7896-2c-1.1045 0-2 0.89543-2 2h2v-2z'
        fill={color}
      />
      <rect x='8.0621' y='4' width='8' height='2' rx='1' fill={color} />
      <path
        d='m11.343 1.5h1.4385c0.3252 0 0.5639 0.30569 0.485 0.62127l-0.5 2c-0.0556 0.22258-0.2556 0.37873-0.485 0.37873h-0.4385c-0.2294 0-0.4294-0.15615-0.4851-0.37873l-0.5-2c-0.0789-0.31558 0.1598-0.62127 0.4851-0.62127z'
        fill={color}
        stroke={color}
      />
      <path d='m15.812 16h-7.5l-1.25 1.2727v2.7273h10v-2.1818l-1.25-1.8182z' fill={color} stroke={color} />
    </SvgIcon>
  );
};
