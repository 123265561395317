import axios from 'axios';

import tokenHeaderInterceptor from 'interceptors/tokenHeader';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(tokenHeaderInterceptor, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
