import { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { Field } from 'components/common';

import { downloadFile, isEmpty } from 'helpers';

import './SourceTable.scss';

const SourceTable = ({ data, fileName }) => {
  const [filter, setFilter] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('column');
  const [page, setPage] = useState(0);
  const [renderData, setRenderData] = useState([]);
  const [tableLength, setTableLength] = useState(0);

  const formatValue = (value) => {
    if (typeof value === 'number' && !Number.isInteger(value)) {
      return value.toFixed(2).toString();
    }
    return value.toString();
  };

  const handleTablePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const prepareData = useCallback(() => {
    const dataCopy = [...Object.entries(data).map((item) => ({ column: item[0], value: formatValue(item[1]) }))];
    const filteredData = dataCopy.filter((row) => row.column.toLowerCase().includes(filter.toLowerCase()));
    if (typeof parseFloat(filteredData[0]?.[orderBy]) === 'number' && !isNaN(filteredData[0]?.[orderBy])) {
      if (order === 'asc') {
        filteredData.sort((a, b) => a[orderBy] - b[orderBy]);
      } else {
        filteredData.sort((a, b) => b[orderBy] - a[orderBy]);
      }
    } else {
      if (order === 'asc') {
        filteredData.sort((a, b) => a[orderBy].localeCompare(b[orderBy]));
      } else {
        filteredData.sort((a, b) => b[orderBy].localeCompare(a[orderBy]));
      }
    }
    setTableLength(filteredData.length);

    if (page === 0) {
      setRenderData(filteredData.slice(0, 10));
    } else {
      setRenderData(filteredData.slice(page * 10, page * 10 + 10));
    }
  }, [data, filter, order, orderBy, page]);

  const renderRows = () => {
    if (!isEmpty(renderData)) {
      return renderData.map((row, index) => (
        <TableRow key={index}>
          <TableCell align='center' className='source_table_number_column'>
            {page * 10 + index + 1}
          </TableCell>
          <TableCell align='left'>{row.column}</TableCell>
          <TableCell align='center'>{row.value}</TableCell>
        </TableRow>
      ));
    }
  };

  const downloadTable = () => {
    const tableData = Object.entries(data)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .join(',\n');
    downloadFile(tableData, fileName);
  };

  const onFilterChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    setRenderData(Object.entries(data).map((item) => ({ column: item[0], value: formatValue(item[1]) })));
    setTableLength(Object.entries(data).length);
  }, [data]);

  useEffect(() => {
    setPage(0);
  }, [order, orderBy, filter]);

  useEffect(() => {
    prepareData();
  }, [filter, order, orderBy, page, prepareData]);

  return (
    <div className='source_table_wrapper'>
      <TableContainer className='source_table_container' component={Paper}>
        <Table className='source_table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' className='source_table_number_column' rowSpan={2}>
                №
              </TableCell>
              <TableCell align='left' sortDirection={orderBy === 'column' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'column'}
                  direction={orderBy === 'column' ? order : 'asc'}
                  onClick={(event) => handleSort(event, 'column')}
                >
                  Column
                </TableSortLabel>
              </TableCell>
              <TableCell align='center' sortDirection={orderBy === 'value' ? order : false} rowSpan={2}>
                <TableSortLabel
                  active={orderBy === 'value'}
                  direction={orderBy === 'value' ? order : 'asc'}
                  onClick={(event) => handleSort(event, 'value')}
                >
                  Value
                </TableSortLabel>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Field label='Search...' onChange={onFilterChange} value={filter} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
      <div className='source_table_tools_container'>
        <Button
          className='source_table_download_data'
          color='primary'
          onClick={() => downloadTable()}
          startIcon={<DownloadIcon />}
          variant='outlined'
        >
          Download data
        </Button>
        <TablePagination
          component='div'
          count={tableLength}
          onPageChange={handleTablePageChange}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </div>
  );
};

export default SourceTable;
