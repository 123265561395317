import { createTheme } from '@mui/material/styles';

const MainTheme = createTheme({
  palette: {
    primary: {
      main: '#26C2B5',
      light: '#26C2B51A',
      dark: '#235158',
    },
    error: {
      main: '#D85151',
    },
    alectron: {
      background: '#262E3D',
      background_active: '#222A38',
      background_page: '#F4F8FB',
      error: '#D85151',
      grey: '#5D6470',
      grey_light: '#94999e',
      grey_dark: '#313947',
      grey_border: '#E1E1E1',
      main: '#26C2B5',
      main_dark: '#235158',
      main_light: '#26C2B51A',
      text_dark: '#303030',
      text_light: '#94999e',
      white: '#FFFFFF',
    },
  },
  typography: {
    small_title: {
      fontFamily: '"Manrope", sans-serif',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '24px',
    },
    title: {
      fontFamily: '"Manrope", sans-serif',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
    },
    great_title: {
      fontFamily: '"Manrope", sans-serif',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '24px',
    },
  },
});

export default MainTheme;
