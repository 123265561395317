import { makeAutoObservable, runInAction } from 'mobx';

import { BACKEND_ROUTES } from 'constants';
import { backendUrlBuilder, makeRequest } from 'helpers';

export default class TargetSnpStore {
  rsId = '';
  rsIds = [];
  tableData = null;

  constructor() {
    makeAutoObservable(this);
  }

  getRsIds = async (search) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.TARGET_SNP_RS_ID), {
      params: { search: search, limit: 10 },
    });
    if (response.status === 200) {
      const newOptions = response.data.rs_id.map((rsId) => ({ id: rsId, label: rsId }));
      runInAction(() => {
        this.rsIds = newOptions;
      });
    }
    return response;
  };

  getData = async (rsId) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.TARGET_DATA), {
      params: { gene_or_rs_id: rsId.id, data_resolution: 'snp' },
    });
    runInAction(() => {
      this.tableData = response.data;
    });
    return response;
  };

  setRsId = (rsId) => {
    this.rsId = rsId;
  };
}
