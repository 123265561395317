import { observer } from 'mobx-react';
import Plot from 'react-plotly.js';

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { Collapse } from 'components/common';

import { createCsv, downloadFile } from 'helpers';

const ViolinPlot = observer(({ cellType, data, downloadViolin, geneId, title }) => {
  const downloadPlotData = () => async () => {
    const response = await downloadViolin(geneId, cellType);
    if (response.status === 200) {
      const fileName = title + '.csv';
      const downloadData = createCsv(response.data);
      downloadFile(downloadData, fileName);
    }
  };

  return (
    <Collapse title={title} open>
      <Plot
        config={{ responsive: true }}
        data={data.data}
        layout={{ ...data.layout, autosize: true }}
        style={{ height: '450px', width: '100%' }}
        useResizeHandler
      />
      <Button color='primary' onClick={downloadPlotData()} startIcon={<DownloadIcon />} variant='outlined'>
        Download
      </Button>
    </Collapse>
  );
});

export default ViolinPlot;
