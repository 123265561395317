import { useState } from 'react';
import { observer } from 'mobx-react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Collapse, Dropdown } from 'components/common';

import { useStore } from 'store';

import './FourWaySidebar.scss';

const FourWaySidebar = observer(({ geneSearch, onGeneSearch }) => {
  const { FourWayStore } = useStore();
  const { cleanOnDatatypeChange, cleanOnXDatasetChange, cleanOnYDatasetChange, options, setValues, values } =
    FourWayStore;

  const [xDatasetSearch, setXDatasetSearch] = useState('');
  const [yDatasetSearch, setYDatasetSearch] = useState('');

  const onChange = (event, field) => {
    setValues(event.target.value, field);
  };

  const onDatatypeChange = (event, field) => {
    cleanOnDatatypeChange();
    setValues(event.target.value, field);
  };

  const onXDatasetSearch = (value) => {
    cleanOnXDatasetChange();
    setXDatasetSearch(value);
  };

  const onYDatasetSearch = (value) => {
    cleanOnYDatasetChange();
    setYDatasetSearch(value);
  };

  return (
    <div className='four_way_sidebar_container'>
      <Typography className='four_way_sidebar_title' variant='title'>
        4-Way Plot
      </Typography>
      <Dropdown
        field='datatype'
        label='Data Type'
        onChange={onDatatypeChange}
        options={options.datatype}
        value={values.datatype}
      />
      <Collapse title='X-axis' open>
        <Dropdown
          field='xDataset'
          groupBy={(option) => option.species}
          inputValue={xDatasetSearch}
          label='X-axis dataset'
          onChange={setValues}
          onInputChange={onXDatasetSearch}
          options={options.dataset}
          renderOption={(props, option, ownerState) => (
            <Tooltip key={ownerState.index} title={option.description} arrow placement='left'>
              <li {...props}>{option.label}</li>
            </Tooltip>
          )}
          value={values.xDataset}
          variant='autocomplete_single'
        />
        <Dropdown
          field='xComparison'
          label='X-axis comparisons'
          onChange={onChange}
          options={options.xComparison}
          value={values.xComparison}
        />
      </Collapse>
      <Collapse title='Y-axis' open>
        <Dropdown
          field='yDataset'
          groupBy={(option) => option.species}
          inputValue={yDatasetSearch}
          label='Y-axis dataset'
          onChange={setValues}
          onInputChange={onYDatasetSearch}
          options={options.dataset}
          renderOption={(props, option, ownerState) => (
            <Tooltip key={ownerState.index} title={option.description} arrow placement='left'>
              <li {...props}>{option.label}</li>
            </Tooltip>
          )}
          value={values.yDataset}
          variant='autocomplete_single'
        />
        <Dropdown
          field='yComparison'
          label='Y-axis comparisons'
          onChange={onChange}
          options={options.yComparison}
          value={values.yComparison}
        />
      </Collapse>
      <Collapse title='Annotations'>
        <Dropdown
          field='genes'
          inputValue={geneSearch}
          label='Annotate with genes'
          onChange={setValues}
          onInputChange={onGeneSearch}
          options={options.genes}
          value={values.genes}
          variant='autocomplete_multiple'
        />
        <Dropdown
          field='geneList'
          label='Annotate with gene list'
          onChange={onChange}
          options={options.geneList}
          value={values.geneList}
        />
      </Collapse>
    </div>
  );
});

export default FourWaySidebar;
