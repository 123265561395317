import { observer } from 'mobx-react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './GroupsSearchPanel.scss';

const GroupsSearchPanel = observer(() => {
  const { GroupStore, ModalStore } = useStore();
  const { groups, groupsSearch, setGroupSearch } = GroupStore;
  const { toggleModal } = ModalStore;

  const openCreateGroupModal = () => () => {
    toggleModal(MODALS.CREATE_GROUP);
  };

  const onSearch = (event) => {
    setGroupSearch(event.target.value);
  };

  return (
    <div className='group_search_panel_container'>
      <div className='group_search_panel_info'>
        <Typography variant='title'>Groups</Typography>
        <span className='group_search_panel_quantity'>{Object.keys(groups).length}</span>
      </div>
      <div className='group_search_panel_actions'>
        <Field
          icon='search'
          iconPosition='end'
          label='Search'
          onChange={onSearch}
          value={groupsSearch}
          variant='filled'
        />
        <Button
          className='group_search_panel_button'
          color='primary'
          onClick={openCreateGroupModal()}
          startIcon={<AddIcon />}
          variant='contained'
        >
          Create Group
        </Button>
      </div>
    </div>
  );
});

export default GroupsSearchPanel;
