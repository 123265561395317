import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { GeneList, SubpageTabs } from 'components/common';
import { FourWaySidebar, FourWayTable, PlotData } from 'components/main/four_way';

import { isEmpty, updateFourWayPlot } from 'helpers';
import { useStore } from 'store';

import 'scss/pages.scss';

const FourWayPage = observer(() => {
  const { FourWayStore } = useStore();
  const {
    getComparisons,
    getDatasets,
    getDatatypes,
    getGeneListGenes,
    getGeneLists,
    getGenes,
    getPlot,
    getTable,
    options,
    plot,
    setPlot,
    values,
  } = FourWayStore;
  const pageData = [
    // {
    //   link: '/analysis/multigene',
    //   label: 'Multi-gene Expression',
    // },
    {
      link: '/analysis/four-way',
      label: '4 Way Plot',
    },
  ];

  const [geneSearch, setGeneSearch] = useState('');
  const [page, setPage] = useState(0);
  const [tableFilter, setTableGeneFilter] = useState('');

  const handleGeneSearch = (value) => {
    setGeneSearch(value);
  };

  const handleTablePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleTableGeneFilterChange = (event) => {
    setTableGeneFilter(event.target.value);
  };

  useEffect(() => {
    if (isEmpty(options?.datatype)) {
      getDatatypes();
    }
  }, [options.datatype, getDatatypes]);

  useEffect(() => {
    if (!isEmpty(values.datatype)) {
      getDatasets(values.datatype);
    }
  }, [getDatasets, values.datatype]);

  useEffect(() => {
    if (!isEmpty(values.datatype) && !isEmpty(values.xDataset)) {
      getComparisons(values.datatype, values.xDataset, 'xComparison');
    }
  }, [getComparisons, values.datatype, values.xDataset]);

  useEffect(() => {
    if (!isEmpty(values.datatype) && !isEmpty(values.yDataset)) {
      getComparisons(values.datatype, values.yDataset, 'yComparison');
    }
  }, [getComparisons, values.datatype, values.yDataset]);

  useEffect(() => {
    if (values.xComparison && values.yComparison) {
      getPlot(values.datatype, values.xDataset, values.xComparison, values.yDataset, values.yComparison);
    }
  }, [getPlot, values.datatype, values.xDataset, values.xComparison, values.yDataset, values.yComparison]);

  useEffect(() => {
    if (plot) {
      const [updatedPlot, updatedAvailableQuadrants] = updateFourWayPlot(
        plot.figure,
        [Number(values.xSliderMin), Number(values.xSliderMax)],
        [Number(values.ySliderMin), Number(values.ySliderMax)],
        values.geneListGenes,
        values.genes.map((gene) => gene.label)
      );
      setPlot(updatedPlot, updatedAvailableQuadrants);
    }
    // eslint-disable-next-line
  }, [
    setPlot,
    values.xSliderMin,
    values.xSliderMax,
    values.ySliderMin,
    values.ySliderMax,
    values.geneListGenes,
    values.genes,
  ]);

  useEffect(() => {
    if (values.xComparison && values.yComparison) {
      getTable(
        values.datatype,
        values.xDataset,
        values.xComparison,
        values.yDataset,
        values.yComparison,
        page,
        tableFilter
      );
    }
  }, [
    getTable,
    page,
    tableFilter,
    values.datatype,
    values.xComparison,
    values.xDataset,
    values.yComparison,
    values.yDataset,
  ]);

  useEffect(() => {
    if (isEmpty(options?.geneList)) {
      getGeneLists();
    }
  }, [getGeneLists, options.geneList]);

  useEffect(() => {
    if (values.geneList) {
      getGeneListGenes(values.geneList);
    }
  }, [getGeneListGenes, values.geneList]);

  useEffect(() => {
    if (!isEmpty(geneSearch)) {
      getGenes(geneSearch);
    }
  }, [geneSearch, getGenes]);

  return (
    <div className='four_way_container page_container'>
      <GeneList />
      <SubpageTabs data={pageData} />
      <div className='page_wrapper with_sidebar'>
        <FourWaySidebar geneSearch={geneSearch} onGeneSearch={handleGeneSearch} />
        <div className='page_main'>
          <PlotData />
          <FourWayTable
            tableFilter={tableFilter}
            onFilterChange={handleTableGeneFilterChange}
            onPageChange={handleTablePageChange}
            page={page}
          />
        </div>
      </div>
    </div>
  );
});

export default FourWayPage;
