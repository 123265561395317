import { makeAutoObservable, runInAction } from 'mobx';

import { MODALS } from 'constants';

export default class ModalStore {
  isAddExperimentOpen = false;
  isConfirmationOpen = false;
  isCreateGeneListOpen = false;
  isCreateGroupOpen = false;
  isEditDatasetOpen = false;
  isEditGeneListOpen = false;
  isEditGroupOpen = false;
  isEditQueryNameOpen = false;
  isEditUserOpen = false;
  isGeneListOpen = false;
  isUploadDatasetOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  toggleGeneList = () => {
    this.isGeneListOpen = !this.isGeneListOpen;
  };

  toggleModal = (modal) => {
    switch (modal) {
      case MODALS.CREATE_GENE_LIST:
        this.isCreateGeneListOpen = !this.isCreateGeneListOpen;
        break;
      case MODALS.CONFIRMATION:
        this.isConfirmationOpen = !this.isConfirmationOpen;
        break;
      case MODALS.EDIT_GENE_LIST:
        this.isEditGeneListOpen = !this.isEditGeneListOpen;
        break;
      case MODALS.ADD_EXPERIMENT:
        this.isAddExperimentOpen = !this.isAddExperimentOpen;
        break;
      case MODALS.CREATE_GROUP:
        this.isCreateGroupOpen = !this.isCreateGroupOpen;
        break;
      case MODALS.EDIT_GROUP:
        this.isEditGroupOpen = !this.isEditGroupOpen;
        break;
      case MODALS.UPLOAD_DATASET:
        this.isUploadDatasetOpen = !this.isUploadDatasetOpen;
        break;
      case MODALS.EDIT_DATASET:
        runInAction(() => {
          this.isEditDatasetOpen = !this.isEditDatasetOpen;
        });
        break;
      case MODALS.EDIT_USER:
        this.isEditUserOpen = !this.isEditUserOpen;
        break;
      case MODALS.EDIT_QUERY_NAME:
        this.isEditQueryNameOpen = !this.isEditQueryNameOpen;
        break;
      default:
        break;
    }
  };
}
