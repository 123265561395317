import { makeAutoObservable, runInAction } from 'mobx';

import { backendUrlBuilder, makeRequest } from 'helpers';
import { BACKEND_ROUTES } from 'constants';

export default class GroupStore {
  activeGroup = null;
  groups = null;
  groupOptions = [];
  groupSearch = '';

  constructor() {
    makeAutoObservable(this);
  }

  createGroup = async (newData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GROUPS_POST), {
      data: newData,
      method: 'POST',
    });
    const { data } = response;
    runInAction(() => {
      if (response.status === 200) {
        const groupsCopy = { ...this.groups };
        groupsCopy[data.id] = data;
        this.groups = groupsCopy;
      }
    });
    return response;
  };

  deleteGroup = async (id) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GROUPS_DELETE), {
      data: { id },
      method: 'DELETE',
    });
    if (response.status === 200) {
      const groupsCopy = { ...this.groups };
      delete groupsCopy[id];
      this.groups = groupsCopy;
    }
    return response;
  };

  getGroups = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GROUPS_GET));
    const { data } = response;
    const groupsMap = {};
    data.forEach((group) => (groupsMap[group.id] = group));
    runInAction(() => {
      this.groups = groupsMap;
      this.groupOptions = data.map((group) => ({ id: group.id, label: group.name }));
    });
    return response;
  };

  setActiveGroup = (groupId) => {
    runInAction(() => {
      this.activeGroup = this.groups[groupId];
    });
  };

  setGroupSearch = (value) => {
    this.groupSearch = value;
  };

  setGroups = (data, index) => {
    if (index) {
      this.groups[index] = data;
      const filteredIndex = this.filteredGroups.findIndex((group) => group.id === data.id);
      this.filteredGroups[filteredIndex] = data;
    } else {
      this.groups = [data, ...this.groups];
      this.filteredGroups = [data, ...this.filteredGroups];
    }
  };

  updateGroup = async (updateData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GROUPS_PATCH), {
      data: updateData,
      method: 'PUT',
    });
    runInAction(() => {
      if (response.status === 200) {
        const { data } = response;
        this.groups[data.id] = data;
      }
    });
    return response;
  };
}
