import { Dropdown, Field } from 'components/common';
import { GENE_SELECT_OPTIONS } from 'constants';

import './GeneSelectTabs.scss';

const GeneSelectTabs = ({
  activeTab,
  all,
  specificGenes,
  errors,
  geneList,
  geneListOptions,
  onCustomGenesBlur,
  onCustomGenesChange,
  onGeneListChange,
  onTabChange,
}) => {
  const defineClassNames = (value, activeValue) => {
    return value === activeValue ? 'gene_select_tab active' : 'gene_select_tab';
  };

  const renderGeneInputs = () => {
    switch (activeTab) {
      case GENE_SELECT_OPTIONS.CUSTOM:
        return (
          <Field
            className='multigene_sidebar_genes_field'
            errorText='Genes should be comma separated, with no spaces, containing Latin letters, numbers and dashes'
            label='Comma delimited list of gene names'
            onBlur={onCustomGenesBlur}
            onChange={onCustomGenesChange}
            value={specificGenes}
          />
        );
      case GENE_SELECT_OPTIONS.LIST:
        return (
          <>
            <Dropdown
              // className={errors.list && 'error'}
              errorText='Select gene list'
              // error={errors.list}
              label='Gene lists'
              onChange={onGeneListChange}
              options={geneListOptions}
              value={geneList}
            />
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className='gene_select_tabs_container'>
      <div className='gene_select_tabs'>
        <div
          className={defineClassNames(GENE_SELECT_OPTIONS.CUSTOM, activeTab)}
          onClick={() => onTabChange(GENE_SELECT_OPTIONS.CUSTOM)}
        >
          Custom
        </div>
        <div
          className={defineClassNames(GENE_SELECT_OPTIONS.LIST, activeTab)}
          onClick={() => onTabChange(GENE_SELECT_OPTIONS.LIST)}
        >
          Gene list
        </div>
        {all && (
          <div
            className={defineClassNames(GENE_SELECT_OPTIONS.ALL, activeTab)}
            onClick={() => onTabChange(GENE_SELECT_OPTIONS.ALL)}
          >
            All
          </div>
        )}
      </div>
      {renderGeneInputs()}
    </div>
  );
};

export default GeneSelectTabs;
