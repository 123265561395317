import { isEmpty } from 'helpers';
import { observer } from 'mobx-react';

import { VolcanoView } from 'features/experiments';

import { useStore } from 'store';

const VolcanoSection = observer(() => {
  const { ExperimentStore } = useStore();
  const { volcanoData } = ExperimentStore;

  const renderVolcanoViews = () => {
    const renderData = Object.keys(volcanoData);
    return renderData.map((volcanoId, index) => {
      return <VolcanoView key={index} volcanoId={volcanoId} />;
    });
  };

  if (isEmpty(volcanoData)) {
    return null;
  }

  return <div className='volcano_section_container'>{renderVolcanoViews()}</div>;
});

export default VolcanoSection;
