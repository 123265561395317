import { observer } from 'mobx-react';

import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { useStore } from 'store';

import './UsersSearchPanel.scss';

const UsersSearchPanel = observer(() => {
  const { UserStore } = useStore();
  const { setUserSearch, users, userSearch } = UserStore;

  const onSearch = (event) => {
    setUserSearch(event.target.value);
  };

  return (
    <div className='user_search_panel_container'>
      <div className='user_search_panel_info'>
        <Typography variant='title'>Users</Typography>
        <span className='user_search_panel_quantity'>{Object.keys(users).length}</span>
      </div>
      <div className='user_search_panel_actions'>
        <Field
          icon='search'
          iconPosition='end'
          label='Search'
          onChange={onSearch}
          value={userSearch}
          variant='filled'
        />
      </div>
    </div>
  );
});

export default UsersSearchPanel;
