import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { VOLCANO_SELECTED_GENES_PAGE_ROWS as ROWS } from 'constants';
import { isEmpty } from 'helpers';
import { useStore } from 'store';

import './VolcanoGeneTable.scss';

const VolcanoGeneTable = observer(({ volcanoId }) => {
  const { ExperimentStore } = useStore();
  const { selectedVolcanoGenes } = ExperimentStore;

  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('gene');
  const [renderData, setRenderData] = useState([]);

  const selectedGenes = selectedVolcanoGenes[volcanoId];

  const onSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const prepareData = useCallback(() => {
    const dataCopy = [...selectedVolcanoGenes[volcanoId]];
    if (order === 'asc') {
      dataCopy.sort((a, b) => {
        return a[orderBy].toString().localeCompare(b[orderBy].toString(), undefined, { numeric: true });
      });
    } else {
      dataCopy.sort((a, b) => b[orderBy].toString().localeCompare(a[orderBy].toString(), undefined, { numeric: true }));
    }
    const pageData = dataCopy.slice(startIndex, endIndex);
    setRenderData(pageData);
  }, [endIndex, order, orderBy, startIndex, selectedVolcanoGenes, volcanoId]);

  useEffect(() => {
    setPage(0);
  }, [selectedGenes]);

  useEffect(() => {
    setStartIndex(page * ROWS);
    setEndIndex(page * ROWS + ROWS);
  }, [page]);

  useEffect(() => {
    if (!isEmpty(selectedGenes)) {
      prepareData();
    }
  }, [selectedGenes, prepareData]);

  if (isEmpty(selectedVolcanoGenes[volcanoId])) {
    return null;
  }

  return (
    <div className='volcano_gene_table_container'>
      <TableContainer className='volcano_gene_table_table_container'>
        <Table className='volcano_gene_table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'gene'}
                  direction={orderBy === 'gene' ? order : 'asc'}
                  onClick={onSort('gene')}
                >
                  Gene
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'log'}
                  direction={orderBy === 'log' ? order : 'asc'}
                  onClick={onSort('log')}
                >
                  log2(FC)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'p'}
                  direction={orderBy === 'p' ? order : 'asc'}
                  onClick={onSort('p')}
                >
                  P-value
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData.map((gene, index) => (
              <TableRow key={index}>
                <TableCell>{gene.gene}</TableCell>
                <TableCell>{gene.log.toFixed(2)}</TableCell>
                <TableCell>{gene.p.toExponential(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={selectedVolcanoGenes[volcanoId].length || 0}
        onPageChange={onPageChange}
        page={page}
        rowsPerPage={ROWS}
        rowsPerPageOptions={[ROWS]}
      />
    </div>
  );
});

export default VolcanoGeneTable;
