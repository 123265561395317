import { observer } from 'mobx-react';

import { useAxiosLoading } from 'hooks';
import LinearProgress from '@mui/material/LinearProgress';

import { useStore } from 'store';

import './Loader.scss';

const Loader = observer(() => {
  const { LoadingStore } = useStore();
  const { externalLoading } = LoadingStore;

  const [loading] = useAxiosLoading();

  if (!loading && !externalLoading) {
    return null;
  }

  return (
    <div className='global_loader_container'>
      <LinearProgress color='primary' />
    </div>
  );
});

export default Loader;
