import { Fragment } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { Collapse, Field } from 'components/common';

import { createCsv, downloadFile, isEmpty } from 'helpers';
import { useStore } from 'store';

import './ExperimentsTable.scss';

const GeneTable = observer(({ filterValue, onFilterChange, onPageChange, onSort, orderBy, order, page }) => {
  const { ExperimentStore } = useStore();
  const { downloadVolcanoTable, tableData, values } = ExperimentStore;

  const downloadTableData = () => async () => {
    const response = await downloadVolcanoTable();
    if (response.status === 200) {
      const fileName =
        values.dataset.label + ':' + values.comparisons.map((comparison) => comparison.label).join(',') + '.csv';
      const downloadData = createCsv(response.data);
      downloadFile(downloadData, fileName);
    }
  };

  const renderHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'name'}
              direction={orderBy === 'name' ? order : 'asc'}
              onClick={onSort('name')}
            >
              Gene
            </TableSortLabel>
          </TableCell>
          {values.comparisons.map((comparison) => (
            <TableCell key={comparison.id} align='center' colSpan={2}>
              {comparison.label}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell>
            <Field label='Filer...' onChange={onFilterChange} value={filterValue} />
          </TableCell>
          {values.comparisons.map((comparison) => (
            <Fragment key={comparison.id}>
              <TableCell align='center'>log2(FC)</TableCell>
              <TableCell align='center'>P-value</TableCell>
            </Fragment>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderBody = () => {
    return (
      <TableBody>
        {tableData?.gen_comp_data?.map((gene, index) => (
          <TableRow key={index}>
            <TableCell className='gene_name_cell' scope='row'>
              {gene.name}
            </TableCell>
            {gene.comparisons.slice().map((comparison) => (
              <Fragment key={comparison.comparison_id}>
                <TableCell align='center'>{comparison.log.toFixed(2)}</TableCell>
                <TableCell align='center'>{comparison.p.toExponential(2)}</TableCell>
              </Fragment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  if (isEmpty(values.comparisons)) {
    return null;
  }

  return (
    <Collapse open title='Volcano Plot Data'>
      <TableContainer className='experiments_table_container' component={Paper}>
        <Table className='experiments_table' size='small'>
          {renderHeader()}
          {renderBody()}
        </Table>
      </TableContainer>
      <div className='experiments_table_actions'>
        <Button color='primary' onClick={downloadTableData()} startIcon={<DownloadIcon />} variant='outlined'>
          Download
        </Button>
        <TablePagination
          component='div'
          count={tableData?.count || 0}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </div>
    </Collapse>
  );
});

export default GeneTable;
