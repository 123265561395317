import oktaAuth from 'index';

const tokenHeaderInterceptor = async (config) => {
  const tokenInfo = await oktaAuth.tokenManager.get('accessToken');
  let { accessToken, expiresAt } = tokenInfo;

  const currentTime = new Date().getTime();
  const expireTime = expiresAt * 1000;
  const timeLeft = expireTime - currentTime;

  if (currentTime < expireTime || timeLeft <= 300000) {
    await oktaAuth.authStateManager.updateAuthState();
    const newTokenInfo = await oktaAuth.tokenManager.get('accessToken');
    accessToken = newTokenInfo.accessToken;
  }

  if (!tokenInfo) {
    window.location.href = '/';
  }

  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
};

export default tokenHeaderInterceptor;
