import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const MouseIcon = ({ color = COLORS.MAIN, className }) => {
  return (
    <SvgIcon className={className}>
      <path
        d='M6.70723 18V7.2H7.92223L12.0022 15.8025L16.0597 7.2H17.2897V17.9925H16.0222V10.1325L12.3547 18H11.6422L7.97473 10.1325V18H6.70723Z'
        fill={color}
      />
      <rect x='0.5' y='0.5' width='23' height='23' rx='4.5' stroke={color} fill='none' />
    </SvgIcon>
  );
};
