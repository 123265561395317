import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { useStore } from 'store';
import { MODALS, SPECIFIC_GENES_REGEX } from 'constants';

import './EditGeneList.scss';
import { isEmpty } from 'helpers';

const EditGeneList = observer(({ initialName }) => {
  const { GeneListStore, ModalStore } = useStore();
  const { genes: initialGenes, updateGeneList } = GeneListStore;
  const { isEditGeneListOpen, toggleModal } = ModalStore;

  const [geneListName, setGeneName] = useState('');
  const [genes, setGenes] = useState('');
  const [genesError, setGenesError] = useState();

  const onGeneNameChange = (event) => {
    setGeneName(event.target.value);
  };

  const onGenesChange = (event) => {
    setGenesError(false);
    setGenes(event.target.value);
  };

  const onGenesBlur = () => {
    if (!isEmpty(genes) && !SPECIFIC_GENES_REGEX.test(genes)) {
      setGenesError(true);
    } else {
      setGenesError(false);
    }
  };

  const saveGeneList = async () => {
    if (geneListName && genes && !genesError) {
      const newData = {
        genes_group_name: geneListName,
        gene_names: genes.split(','),
        target: initialName,
      };
      const response = await updateGeneList(newData);
      if (response.status === 200) {
        toggleModal(MODALS.EDIT_GENE_LIST);
      }
    }
  };

  useEffect(() => {
    setGeneName(initialName);
  }, [initialName]);

  useEffect(() => {
    if (!isEmpty(initialGenes)) {
      setGenes(initialGenes.join(','));
    }
  }, [initialGenes]);

  return (
    <Modal open={isEditGeneListOpen} onClose={() => toggleModal(MODALS.EDIT_GENE_LIST)}>
      <div className='edit_gene_list_modal_container'>
        <div className='edit_gene_list_modal_title_container'>
          <Typography variant='title'>Edit List</Typography>
        </div>
        <div className='edit_gene_list_modal_content'>
          <Field
            className='create_gene_list_modal_name'
            label='Gene list name'
            onChange={onGeneNameChange}
            value={geneListName}
          />
          <Field
            className='create_gene_list_modal_genes'
            error={genesError}
            errorText='Genes should be comma separated, with no spaces, containing Latin letters, numbers and dashes'
            label='Comma delimited list of gene names'
            multiline
            rows={3}
            onBlur={onGenesBlur}
            onChange={onGenesChange}
            value={genes}
          />
        </div>
        <div className='edit_gene_list_modal_button_container'>
          <Button
            className='edit_gene_list_modal_cancel'
            onClick={() => toggleModal(MODALS.EDIT_GENE_LIST)}
            variant='outlined'
          >
            Cancel
          </Button>
          <Button className='edit_gene_list_modal_save' onClick={() => saveGeneList()} variant='contained'>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default EditGeneList;
