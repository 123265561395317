import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReplayIcon from '@mui/icons-material/Replay';

import { backendUrlBuilder } from 'helpers';
import { BACKEND_ROUTES } from 'constants';

import './FileTable.scss';

const FileTable = ({ data, onDelete, onRefresh, onOpenEditModal }) => {
  const renderStatus = (status) => {
    return <span className={`file_table_status_${status.toLowerCase()}`}>{status}</span>;
  };

  // Add zero to single digits
  const formatSmallNumbersInDates = (value) => {
    if (value <= 9) {
      return '0' + value;
    }
    return value;
  };

  const renderDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = formatSmallNumbersInDates(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = formatSmallNumbersInDates(date.getMinutes());
    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const DownloadButton = ({ location }) => {
    return (
      <IconButton color='primary'>
        <a
          className='file_table_link'
          href={backendUrlBuilder(BACKEND_ROUTES.MGE_DOWNLOAD, location)}
          target='_blank'
          rel='noreferrer'
        >
          <DownloadIcon color='primary' />
        </a>
      </IconButton>
    );
  };

  const EditButton = ({ queryName }) => {
    return (
      <IconButton color='primary' onClick={() => onOpenEditModal(queryName)}>
        <EditIcon />
      </IconButton>
    );
  };

  const RefreshButton = ({ taskId }) => {
    return (
      <IconButton color='primary' onClick={() => onRefresh(taskId)}>
        <ReplayIcon />
      </IconButton>
    );
  };

  const DeleteButton = ({ fileId }) => {
    return (
      <IconButton color='primary' onClick={() => onDelete(fileId)}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const renderActions = (row) => {
    switch (row.status) {
      case 'SUCCESS':
        return (
          <>
            <DownloadButton location={row.file_location} />
            <EditButton queryName={row.name} />
            <DeleteButton fileId={row.id} />
          </>
        );
      case 'PENDING':
        return <RefreshButton taskId={row.async_task_id} />;
      case 'ERROR':
        return <DeleteButton fileId={row.id} />;
      default:
        break;
    }
  };

  if (!data) {
    return null;
  }

  return (
    <TableContainer className='file_table_container' component={Paper}>
      <Table className='file_table'>
        <TableHead>
          <TableRow>
            <TableCell>Query name</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Genes/List</TableCell>
            <TableCell align='center'>Created</TableCell>
            <TableCell align='center'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className='file_table_query_cell'>{row.name}</TableCell>
              <TableCell align='center'>{renderStatus(row.status)}</TableCell>
              <TableCell align='center'>{row.genes}</TableCell>
              <TableCell align='center'>{renderDate(row.created_at)}</TableCell>
              <TableCell align='center'>{renderActions(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileTable;
