import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { MultigeneSidebar } from 'components/main/multigene';
import { EditQueryName, FileTable, GeneList, SubpageTabs } from 'components/common';

import { GENE_SELECT_OPTIONS, MODALS, SPECIFIC_GENES_REGEX } from 'constants';
import { isEmpty } from 'helpers';
import { useStore } from 'store';

import 'scss/pages.scss';

const MultigenePage = observer(() => {
  const { ModalStore, MultigeneStore } = useStore();
  const {
    addFile,
    createFile,
    datasetOptions,
    deleteFile,
    files,
    geneListOptions,
    getDatasets,
    getFiles,
    getGeneLists,
    getTreatments,
    refreshStatus,
    updateFile,
  } = MultigeneStore;
  const { toggleModal } = ModalStore;
  const pageData = [
    {
      link: '/analysis/multigene',
      label: 'Multi-gene Expression',
    },
    {
      link: '/analysis/four-way',
      label: '4 Way Plot',
    },
  ];

  const [activeQuery, setActiveQuery] = useState('');
  const [activeTab, setActiveTab] = useState(GENE_SELECT_OPTIONS.CUSTOM);
  const [specificGenes, setSpecificGenes] = useState('');
  const [specificGenesError, setSpecificGenesError] = useState(false);
  const [datasetTreatmentData, setDatasetTreatmentData] = useState([
    { dataset: '', treatment: '', treatmentOptions: [] },
  ]);
  const [geneList, setGeneList] = useState('');
  const [newQueryName, setNewQueryName] = useState('');
  const [queryName, setQueryName] = useState('');

  const handleQueryNameChange = (event) => {
    setQueryName(event.target.value);
  };

  const handleTabChange = (value) => {
    setGeneList('');
    setSpecificGenes('');
    setActiveTab(value);
  };

  const handleSpecificGenesChange = (event) => {
    setSpecificGenesError(false);
    setSpecificGenes(event.target.value);
  };

  const handleSpecificGenesBlur = () => {
    if (!SPECIFIC_GENES_REGEX.test(specificGenes)) {
      setSpecificGenesError(true);
    } else {
      setSpecificGenesError(false);
    }
  };

  const handleGeneListChange = (event) => {
    setGeneList(event.target.value);
  };

  const handleDatasetChange = async (event, index) => {
    const newValue = event.target.value;
    const treatmentOptions = (await getTreatments(newValue)).data.treatments.map((treatment) => ({
      id: treatment,
      label: treatment,
    }));
    const clonedData = [...datasetTreatmentData];
    clonedData[index].dataset = newValue;
    clonedData[index].treatmentOptions = treatmentOptions;
    setDatasetTreatmentData(clonedData);
  };

  const handleTreatmentChange = (event, index) => {
    const clonedData = [...datasetTreatmentData];
    clonedData[index].treatment = event.target.value;
    setDatasetTreatmentData(clonedData);
  };

  const addDatasetTreatmentData = () => {
    setDatasetTreatmentData([...datasetTreatmentData, ...[{ dataset: '', treatment: '', treatmentOptions: [] }]]);
  };

  const getDatasetsAndTreatments = () => {
    const datasetIds = [];
    const treatments = [];
    datasetTreatmentData.forEach((item) => {
      datasetIds.push(item.dataset);
      treatments.push(item.treatment);
    });
    return [datasetIds.join(','), treatments.join(',')];
  };

  const handleFileCreate = async () => {
    const [datasets, treatments] = getDatasetsAndTreatments();
    if (datasets && treatments && (geneList || specificGenes) && queryName) {
      const newData = {
        dataset_ids: datasets,
        treatments: treatments,
        gene_list_name: geneList,
        gene_list: specificGenes,
        query_name: queryName,
      };
      const response = await createFile(newData);
      if (response.status === 200) {
        addFile(response.data[0]);
      }
    }
  };

  const handleOpenEditModal = (queryName) => {
    setActiveQuery(queryName);
    setNewQueryName(queryName);
    toggleModal(MODALS.EDIT_QUERY_NAME);
  };

  const handleNewQueryNameChange = (event) => {
    setNewQueryName(event.target.value);
  };

  const handleFileUpdate = async () => {
    const taskId = files.find((file) => file.name === activeQuery).async_task_id;
    if (activeQuery !== newQueryName) {
      const newData = {
        name: newQueryName,
        async_task_id: taskId,
      };
      updateFile(newData);
    }
    toggleModal(MODALS.EDIT_QUERY_NAME);
  };

  useEffect(() => {
    if (isEmpty(files)) {
      getFiles();
    }
  }, [files, getFiles]);

  useEffect(() => {
    if (isEmpty(geneListOptions)) {
      getGeneLists();
    }
  }, [geneListOptions, getGeneLists]);

  useEffect(() => {
    if (isEmpty(datasetOptions)) {
      getDatasets();
    }
  }, [datasetOptions, getDatasets]);

  return (
    <div className='multigene_container page_container'>
      <GeneList />
      <SubpageTabs data={pageData} />
      <EditQueryName
        initialQueryName={activeQuery}
        onChange={handleNewQueryNameChange}
        onUpdate={handleFileUpdate}
        queryName={newQueryName}
      />
      <div className='page_wrapper with_sidebar'>
        <MultigeneSidebar
          activeTab={activeTab}
          addDatasetTreatmentData={addDatasetTreatmentData}
          specificGenes={specificGenes}
          specificGenesError={specificGenesError}
          datasetTreatmentData={datasetTreatmentData}
          geneList={geneList}
          onCustomGenesBlur={handleSpecificGenesBlur}
          onCustomGenesChange={handleSpecificGenesChange}
          onDatasetChange={handleDatasetChange}
          onFileCreate={handleFileCreate}
          onGeneListChange={handleGeneListChange}
          onQueryNameChange={handleQueryNameChange}
          onTabChange={handleTabChange}
          onTreatmentChange={handleTreatmentChange}
          queryName={queryName}
        />
        <div className='page_main'>
          <FileTable
            data={files}
            onDelete={deleteFile}
            onRefresh={refreshStatus}
            onOpenEditModal={handleOpenEditModal}
          />
        </div>
      </div>
    </div>
  );
});

export default MultigenePage;
