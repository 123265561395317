const downloadFile = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.classList.add('one-time-link');
  link.click();
};

export default downloadFile;
