export const COLUMN_NAMES = {
  GENE: 'Gene',
  'SEQ ID_x': 'Seq Id X',
  'SEQ ID_y': 'Seq Id Y',
  QUADRANT: 'Quadrant',
  ZSCORE_x: 'X Axis Z-score',
  ZSCORE_y: 'Y Axis Z-score',
  Gene_Name: 'Gene',
  Ensembl_Gene_ID: 'Ensembl Gene ID',
  'ENSEMBLE ID_x': 'Ensembl Id X',
  'ENSEMBLE ID_y': 'Ensembl Id Y',
};

export const PLOT_PARAMS = {
  PCC: 'Pearson_correlation_coefficient_(r)',
  P: 'p-value',
  R: 'R-squared',
};

export const QUADRANT_SIGNS = {
  top_left: '><',
  top_right: '>>',
  bottom_right: '<>',
  bottom_left: '<<',
};
