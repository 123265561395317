import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

import { useStore } from 'store';

const SecureRoute = observer(({ children, history }) => {
  const { authState } = useOktaAuth();
  const { AuthStore } = useStore();
  const { isAdmin } = AuthStore;

  const navigate = useNavigate();

  useEffect(() => {
    if (history.location.pathname.includes('admin') && isAdmin !== null && !isAdmin) {
      navigate('/');
    }
  }, [history, isAdmin, navigate]);

  useEffect(() => {
    if (authState && authState?.isAuthenticated !== null && !authState?.isAuthenticated) {
      navigate('/');
    }
  }, [authState, authState?.isAuthenticated, children, navigate]);

  return children;
});

export default SecureRoute;
