const _cloneFigure = (figure) => {
  const data = [];
  for (const trace of figure.data) {
    const x = [...trace.x];
    const y = [...trace.y];
    const text = [...trace.text];
    const clonedTrace = Object.assign({}, trace, {
      x: x,
      y: y,
      text: text,
    });
    data.push(clonedTrace);
  }
  return {
    ...figure,
    ...{
      data: data,
      layout: {
        ...figure.layout,
        annotations: [],
      },
    },
  };
};

const _getAllGeneData = (data) => {
  const allGenes = {};
  data.forEach((list) => {
    const geneNames = [...list.text].map((gene) => gene.split('<br>GENE: ')[1]?.split('<br>')[0].toUpperCase());
    geneNames.forEach((name, index) => {
      if (!allGenes.hasOwnProperty(name)) {
        allGenes[name] = {
          x: list.x[index],
          y: list.y[index],
        };
      } else {
        if (Array.isArray(allGenes[name])) {
          allGenes[name].push({
            x: list.x[index],
            y: list.y[index],
          });
        } else {
          allGenes[name] = [allGenes[name]];
          allGenes[name].push({
            x: list.x[index],
            y: list.y[index],
          });
        }
      }
    });
  });
  return allGenes;
};

const _fillFigureWithSelectedGenes = (figure, hl_x, hl_y, hl_text) => {
  figure.layout.annotations = [];
  for (let i = 0; i < hl_x.length; i++) {
    if (!figure.layout?.annotation?.find((item) => item.text === hl_text[i])) {
      const annotation = {
        x: hl_x[i],
        y: hl_y[i],
        xref: 'x',
        yref: 'y',
        text: hl_text[i],
        showarrow: true,
        arrowhead: 7,
        ax: 0,
        ay: -20,
        font: {
          size: 12,
          color: '#37c22b',
        },
        bgcolor: '#000000',
        arrowcolor: '#37c22b',
      };
      figure.layout.annotations.push(annotation);
    }
  }
};

const volcanoAddAnnotations = function (figure, genes, geneList = [], clickedPoints = []) {
  const hl_x = [];
  const hl_y = [];
  const hl_text = [];
  const clonedFigure = _cloneFigure(figure);
  const allGenes = [...genes, ...geneList, ...clickedPoints];
  const geneData = _getAllGeneData(figure.data);
  for (const gene of allGenes) {
    const geneName = gene.toUpperCase();
    if (geneData.hasOwnProperty(geneName)) {
      if (Array.isArray(geneData[geneName])) {
        for (const gene of geneData[geneName]) {
          hl_x.push(gene.x);
          hl_y.push(gene.y);
          hl_text.push(geneName);
        }
      } else {
        hl_x.push(geneData[geneName].x);
        hl_y.push(geneData[geneName].y);
        hl_text.push(geneName);
      }
    }
  }
  _fillFigureWithSelectedGenes(clonedFigure, hl_x, hl_y, hl_text);
  return clonedFigure;
};

export default volcanoAddAnnotations;
