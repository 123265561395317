const cloneFigureWithAnnotations = (figure) => {
  const clonedFigure = JSON.parse(JSON.stringify(figure));
  clonedFigure.layout.annotations = [...(figure.layout?.annotations || [])];
  return clonedFigure;
};

const updateAxes = (shape, x_range, y_range) => {
  if (shape?.name) {
    switch (shape.name) {
      case 'h1':
      case 'h4':
        shape.y0 = x_range[1];
        shape.y1 = x_range[1];
        break;
      case 'h2':
      case 'h3':
        shape.y0 = x_range[0];
        shape.y1 = x_range[0];
        break;
      case 'v1':
      case 'v4':
        shape.x0 = y_range[1];
        shape.x1 = y_range[1];
        break;
      case 'v2':
      case 'v3':
        shape.x0 = y_range[0];
        shape.x1 = y_range[0];
        break;
      default:
        break;
    }
  }
};

const getGeneNamesFromCustomdata = (customdata) => {
  return customdata.map((item) => item[0].split('<br>')[0].toUpperCase().split(' ')[0]);
};

const getAllGenes = (data) => {
  const allGenes = {};
  data.forEach((list) => {
    const geneNames = getGeneNamesFromCustomdata(list.customdata);
    geneNames.forEach((name, index) => {
      if (!allGenes.hasOwnProperty(name)) {
        allGenes[name] = {
          x: list.x[index],
          y: list.y[index],
        };
      } else {
        if (Array.isArray(allGenes[name])) {
          allGenes[name].push({
            x: list.x[index],
            y: list.y[index],
          });
        } else {
          allGenes[name] = [allGenes[name]];
          allGenes[name].push({
            x: list.x[index],
            y: list.y[index],
          });
        }
      }
    });
  });
  return allGenes;
};

const getGenePointsFromFigure = (allGenes, geneList) => {
  const points = [];

  for (const gene of geneList) {
    const geneName = gene.toUpperCase();
    if (allGenes.hasOwnProperty(geneName)) {
      if (Array.isArray(allGenes[geneName])) {
        for (const coordinate of allGenes[geneName]) {
          points.push({
            text: gene,
            x: coordinate.x,
            y: coordinate.y,
          });
        }
      } else {
        points.push({
          text: gene,
          x: allGenes[geneName].x,
          y: allGenes[geneName].y,
        });
      }
    }
  }
  return points;
};

const fillFigureWithGenes = (figure, points) => {
  figure.layout.annotations = [];
  const hl_x = [];
  const hl_y = [];
  const hl_text = [];
  for (let i = 0; i < points.length; i++) {
    const annotation = {
      x: points[i].x,
      y: points[i].y,
      xref: 'x',
      yref: 'y',
      text: points[i].text,
      showarrow: true,
      arrowhead: 7,
      ax: 0,
      ay: -20,
      font: {
        size: 12,
        color: '#37c22b',
      },
      bgcolor: '#000000',
      arrowcolor: '#37c22b',
    };
    hl_x.push(points[i].x);
    hl_y.push(points[i].y);
    hl_text.push(points[i].text);
    figure.layout.annotations.push(annotation);
  }
};

const updateFourWayPlot = (figure, x_range = [-1.96, 1.96], y_range = [-1.96, 1.96], listGenes = [], genes = []) => {
  if (!figure) {
    return {};
  }
  const clonedFigure = cloneFigureWithAnnotations(figure);
  const geneList = [...(listGenes || []), ...(genes || [])];
  const x_max = Math.max.apply(null, y_range);
  const x_min = Math.min.apply(null, y_range);
  const y_max = Math.max.apply(null, x_range);
  const y_min = Math.min.apply(null, x_range);
  const newGeneList = {
    black: {
      customdata: [],
      x: [],
      y: [],
    },
    magenta: {
      customdata: [],
      x: [],
      y: [],
    },
    red: {
      customdata: [],
      x: [],
      y: [],
    },
    blue: {
      customdata: [],
      x: [],
      y: [],
    },
  };
  const quadrants = {
    top_left: false,
    top_right: false,
    bottom_right: false,
    bottom_left: false,
  };
  const allGenes = getAllGenes(figure.data);
  for (const shape of clonedFigure.layout.shapes) {
    updateAxes(shape, x_range, y_range);
  }

  for (const color of clonedFigure.data) {
    for (let i = 0; i < color.customdata.length; i++) {
      const gene = {
        customdata: color.customdata[i],
        x: color.x[i],
        y: color.y[i],
      };
      const { x, y } = gene;

      if (x > x_max && y < y_max && y > y_min) {
        newGeneList.red.customdata.push(gene.customdata);
        newGeneList.red.x.push(gene.x);
        newGeneList.red.y.push(gene.y);
      } else if (x < x_min && y < y_max && y > y_min) {
        newGeneList.red.customdata.push(gene.customdata);
        newGeneList.red.x.push(gene.x);
        newGeneList.red.y.push(gene.y);
      } else if (x > x_max && y > y_max) {
        newGeneList.magenta.customdata.push(gene.customdata);
        newGeneList.magenta.x.push(gene.x);
        newGeneList.magenta.y.push(gene.y);
        quadrants.top_right = true;
      } else if (x > x_max && y < y_min) {
        newGeneList.magenta.customdata.push(gene.customdata);
        newGeneList.magenta.x.push(gene.x);
        newGeneList.magenta.y.push(gene.y);
        quadrants.bottom_right = true;
      } else if (x < x_min && y > y_max) {
        newGeneList.magenta.customdata.push(gene.customdata);
        newGeneList.magenta.x.push(gene.x);
        newGeneList.magenta.y.push(gene.y);
        quadrants.top_left = true;
      } else if (x < x_min && y < y_min) {
        newGeneList.magenta.customdata.push(gene.customdata);
        newGeneList.magenta.x.push(gene.x);
        newGeneList.magenta.y.push(gene.y);
        quadrants.bottom_left = true;
      } else if (y > y_max) {
        newGeneList.blue.customdata.push(gene.customdata);
        newGeneList.blue.x.push(gene.x);
        newGeneList.blue.y.push(gene.y);
      } else if (y < y_min) {
        newGeneList.blue.customdata.push(gene.customdata);
        newGeneList.blue.x.push(gene.x);
        newGeneList.blue.y.push(gene.y);
      } else {
        newGeneList.black.customdata.push(gene.customdata);
        newGeneList.black.x.push(gene.x);
        newGeneList.black.y.push(gene.y);
      }
    }
    color.customdata = [];
    color.x = [];
    color.y = [];
  }

  for (const color of clonedFigure.data) {
    color.customdata = [...newGeneList[color.name.split(',')[0]].customdata];
    color.x = [...newGeneList[color.name.split(',')[0]].x];
    color.y = [...newGeneList[color.name.split(',')[0]].y];
  }

  const points = getGenePointsFromFigure(allGenes, geneList);
  fillFigureWithGenes(clonedFigure, points);
  return [clonedFigure, quadrants];
};

export default updateFourWayPlot;
