import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { SubpageTabs } from 'components/common';
import { SnpDetails, TableSection, TargetSnpSidebar } from 'components/main/target_snp';

import { isEmpty } from 'helpers';
import { useStore } from 'store';

const TargetSnpPage = observer(() => {
  const { TargetSnpStore } = useStore();
  const { rsId, getData, getRsIds, setRsId } = TargetSnpStore;

  const pageData = [
    {
      link: '/targets/genes',
      label: 'Gene',
    },
    {
      link: '/targets/snp',
      label: 'SNP',
    },
  ];

  const [rsIdSearch, setRsIdSearch] = useState();

  const handleRsIdSearch = (value) => {
    setRsIdSearch(value);
  };

  const handleRsIdChange = (newData) => {
    setRsId(newData);
  };

  useEffect(() => {
    if (!isEmpty(rsIdSearch)) {
      getRsIds(rsIdSearch);
    }
  }, [getRsIds, rsIdSearch]);

  useEffect(() => {
    if (rsId) {
      getData(rsId);
    }
  }, [getData, rsId]);

  return (
    <div className='targets_snp_container page_container'>
      <SubpageTabs data={pageData} />
      <div className='page_wrapper with_sidebar'>
        <TargetSnpSidebar onRsIdChange={handleRsIdChange} onSearch={handleRsIdSearch} searchValue={rsIdSearch} />
        <div className='page_main fixed_sidebar'>
          <SnpDetails />
          <TableSection />
        </div>
      </div>
    </div>
  );
});

export default TargetSnpPage;
