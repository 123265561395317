import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { AdminTopMenu } from 'components/common';
import { UsersTable } from 'components/admin';
import { UsersSearchPanel } from 'components/admin/users';

import { isEmpty } from 'helpers';
import { useStore } from 'store';

const UsersPage = observer(() => {
  const { AuthStore, UserStore } = useStore();
  const { isAdmin } = AuthStore;
  const { getUsers, setFilteredUsers, users, userSearch } = UserStore;

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (!isEmpty(userSearch)) {
      const filteredData = users?.filter((user) => user.email.toLowerCase().includes(userSearch));
      setFilteredUsers(filteredData);
    } else {
      setFilteredUsers(users);
    }
  }, [setFilteredUsers, users, userSearch]);

  if (!users || !isAdmin) {
    return;
  }

  return (
    <div className='users_page_container page_container'>
      <AdminTopMenu />
      <div className='page_wrapper max_width'>
        <div className='page_main'>
          <UsersSearchPanel />
          <UsersTable />
        </div>
      </div>
    </div>
  );
});

export default UsersPage;
