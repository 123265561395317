import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const HumanIcon = ({ color = COLORS.MAIN, className }) => {
  return (
    <SvgIcon className={className}>
      <path d='M7.82051 18V7.2H9.15551V11.9625H14.8405V7.2H16.1755V18H14.8405V13.23H9.15551V18H7.82051Z' fill={color} />
      <rect x='0.5' y='0.5' width='23' height='23' rx='4.5' stroke={color} fill='none' />
    </SvgIcon>
  );
};
