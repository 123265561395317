const isEmpty = (value) => {
  if (!value && value !== 0) {
    return true;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value) && value.length) {
      return false;
    }

    if (Object.keys(value).length) {
      return false;
    }

    return true;
  }

  return !Boolean(value);
};

export default isEmpty;
