import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { classConcat } from 'helpers';

import './Dropdown.scss';

const Dropdown = ({
  className,
  defaultValue,
  disabledChip,
  error,
  errorText,
  field,
  groupBy = () => {},
  inputValue = '',
  label,
  max = -1,
  onChange,
  onInputChange,
  optionLabel = 'label',
  options,
  renderOption,
  value,
  variant,
}) => {
  const handleChange = (value) => {
    onChange(value, field);
  };

  const handleInputChange = (value) => {
    onInputChange(value, field);
  };

  if (variant === 'autocomplete_multiple') {
    return (
      <FormControl className='dropdown_container' fullWidth variant='filled'>
        <Autocomplete
          defaultValue={defaultValue}
          disabled={value.length === max}
          filterSelectedOptions
          getOptionLabel={(option) => option[optionLabel]}
          inputValue={inputValue}
          label={label}
          multiple
          onChange={(event, newValue) => handleChange(newValue)}
          onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
          options={options}
          renderInput={(params) => <TextField {...params} label={label} placeholder={label} variant='filled' />}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option[optionLabel]}
                {...getTagProps({ index })}
                disabled={option[optionLabel] === disabledChip}
              />
            ))
          }
          value={value ?? ''}
        />
      </FormControl>
    );
  }

  if (variant === 'autocomplete_single') {
    return (
      <FormControl className='dropdown_container' fullWidth variant='filled'>
        <Autocomplete
          defaultValue={defaultValue}
          filterSelectedOptions
          freeSolo
          getOptionLabel={(option) => option[optionLabel] || ''}
          groupBy={groupBy}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={inputValue}
          label={label}
          onChange={(event, newValue) => handleChange(newValue)}
          onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
          options={options}
          renderInput={(params) => <TextField {...params} label={label} placeholder={label} variant='filled' />}
          renderOption={renderOption}
          value={value ?? ''}
        />
      </FormControl>
    );
  }

  return (
    <FormControl className={classConcat('dropdown_container', className)} fullWidth variant='filled' error={error}>
      <InputLabel id='dropdown_label'>{label}</InputLabel>
      <Select defaultValue={defaultValue} labelId='dropdown_label' onChange={handleChange} value={value ?? ''}>
        {options.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;
