import { VolcanoGeneTable, VolcanoParams, VolcanoPlot } from 'features/experiments';

import './VolcanoView.scss';

const VolcanoView = ({ volcanoId }) => {
  return (
    <div className='volcano_view_container'>
      <div className='volcano_plot_wrapper'>
        <VolcanoPlot volcanoId={volcanoId} />
        <VolcanoGeneTable volcanoId={volcanoId} />
      </div>
      <VolcanoParams volcanoId={volcanoId} />
    </div>
  );
};

export default VolcanoView;
