import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const DatasetIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <ellipse cx='12' cy='6.46154' rx='8' ry='2.46154' stroke={color} fill='none' strokeWidth='2' />
      <path
        d='M20 10.1538C20 11.5133 16.4183 12.6153 12 12.6153C7.58172 12.6153 4 11.5133 4 10.1538'
        stroke={color}
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M20 13.8462C20 15.2057 16.4183 16.3077 12 16.3077C7.58172 16.3077 4 15.2057 4 13.8462'
        stroke={color}
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M20 17.5385C20 18.898 16.4183 20 12 20C7.58172 20 4 18.898 4 17.5385'
        stroke={color}
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </SvgIcon>
  );
};
