import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './Confirmation.scss';

const Confirmation = observer(({ confirmText = 'Save', description, onConfirm, title }) => {
  const { ModalStore } = useStore();
  const { isConfirmationOpen, toggleModal } = ModalStore;

  const closeModal = () => () => {
    toggleModal(MODALS.CONFIRMATION);
  };

  return (
    <Modal open={isConfirmationOpen} onClose={closeModal()}>
      <div className='confirmation_modal_container'>
        <Typography className='confirmation_modal_title'>{title}</Typography>
        {description && <Typography className='confirmation_modal_description'>{description}</Typography>}
        <div className='confirmation_modal_button_container'>
          <Button className='confirmation_modal_cancel' onClick={closeModal()} variant='outlined'>
            Cancel
          </Button>
          <Button className='confirmation_modal_save' onClick={onConfirm} variant='contained'>
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default Confirmation;
