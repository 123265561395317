const VOLCANO_PARAMS = {
  effect_size_bounds: 'Effect',
  significance_threshold: 'Threshold',
  detection_level: 'Detection',
};

const SLIDER_VALUES = {
  effect_size_bounds: 1,
  significance_threshold: 4,
  detection_level: 0,
};

export { VOLCANO_PARAMS, SLIDER_VALUES };
