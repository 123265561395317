import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { GeneList, HeatmapPlot, TopMenu, ViolinSection } from 'components/common';
import { GeneInfo, GenesSidebar } from 'features/genes';

import { isEmpty } from 'helpers';
import { useStore } from 'store';

import 'scss/pages.scss';

const GenesPage = observer(() => {
  const { GeneStore } = useStore();
  const {
    activeDataset,
    datasets,
    downloadViolin,
    gene,
    getGenes,
    getGenesDetails,
    getHeatmap,
    getViolin,
    heatmap,
    resetPlots,
    setActiveDataset,
    setGene,
    violinData,
  } = GeneStore;

  const [geneSearch, setGeneSearch] = useState('');
  const [sortedDatasets, setSortedDatasets] = useState();

  const breadcrumbs = [
    {
      link: '/genes',
      text: 'Genes',
    },
    {
      text: 'Gene details',
    },
  ];

  const handleGeneSearch = (value) => {
    setGeneSearch(value);
  };

  const handleGeneChange = (newData) => {
    resetPlots();
    setGene(newData);
    if (newData) {
      getGenesDetails(newData.id);
      getHeatmap(newData.id);
    }
    setActiveDataset();
  };

  const sortDatasets = (datasetData) => {
    const result = {};
    datasetData.forEach((obj) => {
      result['All'] = result['All'] || {};
      result['All'][obj.experimental_data_type] = result['All'][obj.experimental_data_type] || [];
      result['All'][obj.experimental_data_type].push(obj);
      result[obj.species] = result[obj.species] || {};
      result[obj.species][obj.experimental_data_type] = result[obj.species][obj.experimental_data_type] || [];
      result[obj.species][obj.experimental_data_type].push(obj);
    });
    return result;
  };

  const handleDatasetChange = (value) => {
    setActiveDataset(value);
  };

  useEffect(() => {
    if (!isEmpty(geneSearch)) {
      getGenes(geneSearch);
    }
  }, [geneSearch, getGenes]);

  useEffect(() => {
    if (gene?.id && isEmpty(datasets)) {
      getGenesDetails(gene.id);
    }
  }, [datasets, getGenesDetails, gene?.id]);

  useEffect(() => {
    if (gene?.id && !heatmap) {
      getHeatmap(gene.id);
    }
  }, [getHeatmap, heatmap, gene]);

  useEffect(() => {
    if (!isEmpty(datasets)) {
      setSortedDatasets(sortDatasets(datasets));
    }
  }, [datasets]);

  useEffect(() => {
    if (gene && activeDataset) {
      getViolin(gene.id, activeDataset.display_name);
    }
  }, [activeDataset, getViolin, gene]);

  const renderHeatmaps = () => {
    if (heatmap) {
      return Object.values(heatmap).map((heatmapData) => {
        const key = Object.keys(heatmapData)[0];
        return <HeatmapPlot data={heatmapData[key]} title={`CRISPR (pooled) data ${key}`} />;
      });
    }
  };

  return (
    <div className='genes_container page_container'>
      <TopMenu breadcrumbs={breadcrumbs} />
      <div className='page_wrapper with_sidebar'>
        <GenesSidebar
          onGeneChange={handleGeneChange}
          onSearch={handleGeneSearch}
          searchValue={geneSearch}
          onDatasetChange={handleDatasetChange}
          sortedDatasets={sortedDatasets}
        />
        <div className='page_main'>
          <GeneInfo />
          {/* {renderHeatmaps()} */}
          <ViolinSection data={violinData} downloadViolin={downloadViolin} />
        </div>
      </div>
      <GeneList />
    </div>
  );
});

export default GenesPage;
