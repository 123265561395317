import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const ChartIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <rect x='4' y='4' width='16' height='16' rx='1' stroke={color} fill='none' strokeWidth='2' />
      <rect x='7' y='10' width='2' height='7' fill={color} />
      <rect x='15' y='13' width='2' height='4' fill={color} />
      <rect x='11' y='7' width='2' height='10' fill={color} />
    </SvgIcon>
  );
};
