import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const TargetIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <path d='M17 12H22' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
      <path d='M2 12H7' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
      <path d='M12 7V2' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
      <path d='M12 22V17' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
      <circle cx='12' cy='12' r='8' stroke={color} strokeWidth='2' fill='none' />
      <circle cx='12' cy='12' r='2' stroke={color} strokeWidth='2' fill='none' />
    </SvgIcon>
  );
};
