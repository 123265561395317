import { useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import './Collapse.scss';

const Collapse = ({ children, open = false, title }) => {
  const [expanded, setExpanded] = useState(open);

  const handleChange = (value) => {
    setExpanded(value);
  };

  return (
    <MuiAccordion className='collapse_container' expanded={expanded} onChange={() => handleChange(!expanded)}>
      <MuiAccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className='collapse_expanded_icon' sx={{ color: '#26C2B5' }} />
          ) : (
            <AddIcon className='collapse_collapsed_icon' />
          )
        }
      >
        {title}
      </MuiAccordionSummary>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default Collapse;
