import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const GeneIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <path
        d='M7 2L7.16686 3.83547C7.38396 6.22361 8.31159 8.49194 9.83009 10.3479L10.4521 11.1081C11.2523 12.0861 12.7477 12.0861 13.5479 11.1081L14.1699 10.3479C15.6884 8.49194 16.616 6.22361 16.8331 3.83546L17 2'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        fill='none'
      />
      <path d='M7 4H16' stroke={color} strokeWidth='2' fill='none' />
      <path d='M8 8H16' stroke={color} strokeWidth='2' fill='none' />
      <path
        d='M7 22L7.16686 20.1645C7.38396 17.7764 8.31159 15.5081 9.83009 13.6521L10.4521 12.8919C11.2523 11.9139 12.7477 11.9139 13.5479 12.8919L14.1699 13.6521C15.6884 15.5081 16.616 17.7764 16.8331 20.1645L17 22'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        fill='none'
      />
      <path d='M7 20H16' stroke={color} strokeWidth='2' fill='none' />
      <path d='M8 16H16' stroke={color} strokeWidth='2' fill='none' />
    </SvgIcon>
  );
};
