import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const GeneListIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <path
        d='M4 6L4.02412 6.16884C4.32805 8.29632 5.48942 10.2067 7.2382 11.4559V11.4559C7.69391 11.7814 8.30609 11.7814 8.7618 11.4559V11.4559C10.5106 10.2067 11.672 8.29632 11.9759 6.16884L12 6'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        fill='none'
      />
      <path d='M4 8H12' stroke={color} strokeWidth='2' fill='none' />
      <path
        d='M4 18L4.02412 17.8312C4.32805 15.7037 5.48942 13.7933 7.2382 12.5441V12.5441C7.69391 12.2186 8.30609 12.2186 8.7618 12.5441V12.5441C10.5106 13.7933 11.672 15.7037 11.9759 17.8312L12 18'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        fill='none'
      />
      <path d='M4 16H12' stroke={color} strokeWidth='2' fill='none' />
      <path d='M16 8H21' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
      <path d='M16 12H21' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
      <path d='M16 16H18' stroke={color} strokeWidth='2' strokeLinecap='round' fill='none' />
    </SvgIcon>
  );
};
