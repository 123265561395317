import { makeAutoObservable, runInAction } from 'mobx';

import { backendUrlBuilder, makeRequest } from 'helpers';
import { BACKEND_ROUTES } from 'constants';

export default class DatasetStore {
  activeDataset = null;
  datasets = null;
  datasetSearch = '';
  uploadOptions = {
    datatype: [],
    dataset: [],
    probes: [],
    species: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  checkCovariatesMapping = async (dataset, datatype) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_CHECK_MAPPING, dataset, datatype));
    return response;
  };

  deleteDataset = async (id) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_DELETE, id), {
      method: 'DELETE',
    });
    return response;
  };

  flushCache = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FLUSH_CACHE), { method: 'POST' });
    return response;
  };

  getDatasets = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_GET));
    const {
      data: { data },
    } = response;
    const datasetsMap = {};
    data.forEach((dataset) => (datasetsMap[dataset.dataset_id] = dataset));
    runInAction(() => {
      this.datasets = datasetsMap;
    });
    return datasetsMap;
  };

  getDatatypes = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_DATATYPES));
    return response.data.datatypes.map((item) => ({ id: item.id, label: item.data_type }));
  };

  getAvailableDatasets = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_DATASETS));
    return response.data.datasets.map((item) => ({ id: item.name, label: item.name }));
  };

  getProbes = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_PROBES));
    return response.data.probes.map((item) => ({ id: item.path, label: item.name }));
  };

  getSpecies = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_SPECIES));
    return response.data.species.map((item) => ({ id: item, label: item }));
  };

  getUploadOptions = async () => {
    const options = {
      datatype: await this.getDatatypes(),
      dataset: await this.getAvailableDatasets(),
      probes: await this.getProbes(),
      species: await this.getSpecies(),
    };
    runInAction(() => {
      this.uploadOptions = options;
    });
  };

  getCovariates = async (dataset) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_COVARIATES, dataset));

    return response;
  };

  setActiveDataset = (datasetId) => {
    runInAction(() => {
      this.activeDataset = this.datasets[datasetId];
    });
  };

  setDatasetSearch = (newValue) => {
    runInAction(() => {
      this.datasetSearch = newValue;
    });
  };

  updateDataset = async (updateData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_UPDATE), {
      data: updateData,
      method: 'PATCH',
    });
    if (response.status === 200) {
      const {
        data: { dataset_id, display_name, permissions },
      } = response;
      runInAction(() => {
        this.datasets[dataset_id].display_name = display_name;
        this.datasets[dataset_id].permissions = permissions;
      });
    }
    return response;
  };

  uploadDataset = async (uploadData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DATASETS_UPLOAD), {
      data: uploadData,
      method: 'POST',
    });
    return response;
  };
}
