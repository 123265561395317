import { makeAutoObservable, runInAction } from 'mobx';

import { backendUrlBuilder, makeRequest } from 'helpers';
import { BACKEND_ROUTES } from 'constants';

export default class UserStore {
  filteredUsers = null;
  users = null;
  userOptions = [];
  userSearch = '';

  constructor() {
    makeAutoObservable(this);
  }

  deleteUser = async (id) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.USERS_DELETE, id), { method: 'DELETE' });
    if (response.status === 200) {
      this.getUsers();
    }
    return response;
  };

  getUsers = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.USERS_GET));
    const { data } = response;
    runInAction(() => {
      this.users = data;
      this.filteredUsers = data;
      this.userOptions = data.map((user) => ({ id: user.id, label: user.email }));
    });
    return response;
  };

  setUserSearch = (value) => {
    this.userSearch = value;
  };

  setFilteredUsers = (data) => {
    this.filteredUsers = data;
  };
}
