// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Typography from '@mui/material/Typography';

import './AdminTopMenu.scss';

const AdminTopMenu = () => {
  return (
    <div className='admin_top_menu_container'>
      <Typography color='primary' variant='small_title'>
        Admin Panel
      </Typography>
      {/* <div className='admin_top_menu_controls'>
        <AccountCircleOutlinedIcon className='admin_top_menu_account' color='primary' />
        <Typography variant='small_title'>alector.dev@gmail.com</Typography>
      </div> */}
    </div>
  );
};

export default AdminTopMenu;
