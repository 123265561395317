import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { AdminTopMenu } from 'components/common';
import { CreateGroupModal, EditGroupModal, GroupsSearchPanel, GroupsTable } from 'components/admin/groups';

import { isEmpty } from 'helpers';
import { useStore } from 'store';

const GroupsPage = observer(() => {
  const { AuthStore, GroupStore, UserStore } = useStore();
  const { isAdmin } = AuthStore;

  const { getGroups, groups } = GroupStore;
  const { getUsers, users } = UserStore;

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    if (isEmpty(users)) {
      getUsers();
    }
  }, [getUsers, users]);

  if (!groups || !isAdmin) {
    return;
  }

  return (
    <div className='group_page_container page_container'>
      <AdminTopMenu />
      <CreateGroupModal />
      <EditGroupModal />
      <div className='page_wrapper max_width'>
        <div className='page_main'>
          <GroupsSearchPanel />
          <GroupsTable />
        </div>
      </div>
    </div>
  );
});

export default GroupsPage;
