import BreadcrumbsComponent from '../breadcrumbs/Breadcrumbs';
import { GeneListButton } from 'components/common';

import './TopMenu.scss';

const TopMenu = ({ breadcrumbs }) => {
  return (
    <div className='top_menu_container'>
      <BreadcrumbsComponent crumbs={breadcrumbs} />
      <GeneListButton />
    </div>
  );
};

export default TopMenu;
