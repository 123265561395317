import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Confirmation from 'components/common/modals/confirmation/Confirmation';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './UsersTable.scss';

const UsersTable = observer(() => {
  const { ModalStore, UserStore } = useStore();
  const { toggleModal } = ModalStore;
  const { deleteUser, users, userSearch } = UserStore;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [renderData, setRenderData] = useState([]);
  const [deleteId, setDeleteId] = useState();

  const openConfirmationModal = (id) => () => {
    setDeleteId(id);
    toggleModal(MODALS.CONFIRMATION);
  };

  const onDeleteUser = (id) => () => {
    deleteUser(id);
    toggleModal(MODALS.CONFIRMATION);
  };

  const onSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const prepareData = useCallback(() => {
    const dataCopy = [...Object.values(users)];
    const filteredUsers = dataCopy.filter((user) => user.email.toLowerCase().includes(userSearch));
    if (order === 'asc') {
      filteredUsers.sort((a, b) => a[orderBy].localeCompare(b[orderBy], undefined, { numeric: true }));
    } else {
      filteredUsers.sort((a, b) => b[orderBy].localeCompare(a[orderBy], undefined, { numeric: true }));
    }
    setRenderData(filteredUsers);
  }, [order, orderBy, userSearch, users]);

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  return (
    <>
      <Confirmation
        confirmText='Delete'
        onConfirm={onDeleteUser()}
        title='Are you sure you want to delete this record?'
      />
      <TableContainer className='user_table_container' component={Paper}>
        <Table className='user_table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={onSort('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>Group</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData.map((row, index) => {
              return (
                <TableRow className='user_table_row' hover key={index}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.groups.map((group) => group.name).join(', ')}</TableCell>
                  <TableCell className='user_table_action_cell'>
                    <Button
                      className='user_table_delete'
                      color='error'
                      onClick={openConfirmationModal(row.id)}
                      startIcon={<DeleteIcon color='error' />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default UsersTable;
