import { makeAutoObservable, runInAction } from 'mobx';

import { BACKEND_ROUTES, FILE_TYPES } from 'constants';
import { backendUrlBuilder, makeRequest } from 'helpers';

export default class MultigeneStore {
  datasetOptions = [];
  files = null;
  geneListOptions = [];

  constructor() {
    makeAutoObservable(this);
  }

  getGeneLists = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS)).catch((error) =>
      console.error(error)
    );
    const options = response.data.map((item) => ({ id: item, label: item }));
    runInAction(() => {
      this.geneListOptions = options;
    });
    return response;
  };

  getDatasets = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.MGE_DATASETS)).catch((error) =>
      console.error(error)
    );
    const options = response.data.datasets.map((dataset) => ({ id: dataset.id, label: dataset.display_name }));
    runInAction(() => {
      this.datasetOptions = options;
    });
    return response;
  };

  getFiles = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.MGE_FILES)).catch((error) =>
      console.error(error)
    );
    runInAction(() => {
      this.files = response.data;
    });
    return response;
  };

  getTreatments = async (datasetId) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.MGE_TREATMENTS, datasetId)).catch((error) =>
      console.error(error)
    );
    return response;
  };

  createFile = async (newData) => {
    const response = await makeRequest(
      backendUrlBuilder(BACKEND_ROUTES.MGE_CREATE),
      { data: newData, method: 'POST' },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).catch((error) => console.error(error));
    return response;
  };

  addFile = (newFile) => {
    this.files = [newFile, ...this.files];
  };

  deleteFile = async (fileId) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.MGE_DELETE), {
      data: { id: fileId },
      method: 'POST',
    }).catch((error) => console.error(error));
    if (response.status === 200) {
      const index = this.files.findIndex((file) => file.id === fileId);
      const clonedFiles = [...this.files];
      clonedFiles.splice(index, 1);
      this.files = clonedFiles;
    }
    return response;
  };

  refreshStatus = async (taskId) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.MGE_REFRESH), {
      params: { task_id: taskId, file_type: FILE_TYPES.MGE },
    }).catch((error) => console.error(error));
    if (response.status === 200) {
      const index = this.files.findIndex((file) => file.async_task_id === taskId);
      const clonedFiles = [...this.files];
      clonedFiles[index].status = response.data.status;
      runInAction(() => {
        this.files = clonedFiles;
      });
    }
    return response;
  };

  updateFile = async (newData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.TARGET_UPDATE), {
      data: newData,
      method: 'PATCH',
    }).catch((error) => console.error(error));
    if (response.status === 200) {
      const index = this.files.findIndex((file) => file.async_task_id === newData.async_task_id);
      const clonedFiles = [...this.files];
      clonedFiles[index].name = response.data.name;
      runInAction(() => {
        this.files = clonedFiles;
      });
    }
  };
}
