import './CategoryNavigation.scss';

const CategoryNavigation = ({ data }) => {
  const categories = Object.keys(data);

  const scrollToCategory = (category) => {
    const element = document.getElementById(category);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className='categories_container'>
      {categories.map((category, index) => (
        <span className='category' key={index} onClick={() => scrollToCategory(category)} href={'#' + category}>
          {category}
        </span>
      ))}
    </div>
  );
};

export default CategoryNavigation;
