import { useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { isEmpty } from 'helpers';
import { MODALS, SPECIFIC_GENES_REGEX } from 'constants';
import { useStore } from 'store';

import './CreateGeneList.scss';

const CreateGeneList = observer(() => {
  const { GeneListStore, ModalStore } = useStore();
  const { createGeneList } = GeneListStore;
  const { isCreateGeneListOpen, toggleModal } = ModalStore;

  const [geneListName, setGeneName] = useState('');
  const [genes, setGenes] = useState('');
  const [genesError, setGenesError] = useState();

  const onGeneNameChange = (event) => {
    setGeneName(event.target.value);
  };

  const onGenesChange = (event) => {
    setGenesError(false);
    setGenes(event.target.value);
  };

  const onGenesBlur = () => {
    if (!isEmpty(genes) && !SPECIFIC_GENES_REGEX.test(genes)) {
      setGenesError(true);
    } else {
      setGenesError(false);
    }
  };

  const onGeneListSave = async () => {
    if (geneListName && genes && !genesError) {
      const newData = {
        genes_group_name: geneListName,
        gene_names: genes.split(','),
      };
      const response = await createGeneList(newData);
      if (response.status === 200) {
        setGeneName('');
        setGenes('');
        toggleModal(MODALS.CREATE_GENE_LIST);
      }
    }
  };

  return (
    <Modal open={isCreateGeneListOpen} onClose={() => toggleModal(MODALS.CREATE_GENE_LIST)}>
      <div>
        <div className='create_gene_list_modal'>
          <div className='create_gene_list_modal_title_container'>
            <Typography variant='title'>New List</Typography>
          </div>
          <div className='create_gene_list_modal_content'>
            <Field
              className='create_gene_list_modal_name'
              label='Gene list name'
              onChange={onGeneNameChange}
              value={geneListName}
            />
            <Field
              className='create_gene_list_modal_genes'
              error={genesError}
              errorText='Genes should be comma separated, with no spaces, containing Latin letters, numbers and dashes'
              label='Comma delimited list of gene names'
              multiline
              rows={3}
              onBlur={onGenesBlur}
              onChange={onGenesChange}
              value={genes}
            />
          </div>
          <div className='create_gene_list_modal_button_container'>
            <Button
              className='create_gene_list_modal_cancel'
              onClick={() => toggleModal(MODALS.CREATE_GENE_LIST)}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button className='create_gene_list_modal_save' onClick={() => onGeneListSave()} variant='contained'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default CreateGeneList;
