import { observer } from 'mobx-react';

import Typography from '@mui/material/Typography';
import { CategoryNavigation } from 'components/main/target_genes';
import { Dropdown } from 'components/common';

import { useStore } from 'store';

import './TargetGenesSidebar.scss';

const TargetGenesSidebar = observer(({ onGeneChange, onSearch, searchValue }) => {
  const { TargetGenesStore } = useStore();
  const { genes, gene, tableData } = TargetGenesStore;

  return (
    <div className='target_genes_sidebar_container'>
      <Typography className='target_gene_sidebar_title' variant='title'>
        Gene
      </Typography>
      <Dropdown
        inputValue={searchValue}
        field='gene'
        label='Gene'
        onChange={onGeneChange}
        onInputChange={onSearch}
        options={genes}
        variant='autocomplete_single'
        value={gene}
      />
      {tableData && <CategoryNavigation data={tableData} />}
    </div>
  );
});

export default TargetGenesSidebar;
