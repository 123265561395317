import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const AnalysisIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <circle cx='4' cy='11' r='2' stroke={color} strokeWidth='2' fill='none' />
      <path d='M5.5 12L11 15.5' stroke={color} strokeWidth='2' fill='none' />
      <path d='M13 15L19 8.5' stroke={color} strokeWidth='2' fill='none' />
      <circle cx='20' cy='7' r='2' stroke={color} strokeWidth='2' fill='none' />
      <circle cx='12' cy='16' r='2' stroke={color} strokeWidth='2' fill='none' />
    </SvgIcon>
  );
};
