import { observer } from 'mobx-react';

import Typography from '@mui/material/Typography';
import { Dropdown } from 'components/common';
import { CategoryNavigation } from 'components/main/target_genes';

import { useStore } from 'store';

import './TargetSnpSidebar.scss';

const TargetSnpSidebar = observer(({ onRsIdChange, onSearch, searchValue }) => {
  const { TargetSnpStore } = useStore();
  const { rsIds, rsId, tableData } = TargetSnpStore;
  return (
    <div className='target_snp_sidebar_container'>
      <Typography className='target_snp_sidebar_title' variant='title'>
        SNP
      </Typography>
      <Dropdown
        inputValue={searchValue}
        field='rsId'
        label='RS Id'
        onChange={onRsIdChange}
        onInputChange={onSearch}
        options={rsIds}
        variant='autocomplete_single'
        value={rsId}
      />
      {tableData && <CategoryNavigation data={tableData} />}
    </div>
  );
});

export default TargetSnpSidebar;
