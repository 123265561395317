import { makeAutoObservable, runInAction } from 'mobx';

import { backendUrlBuilder, isEmpty, makeRequest, updateVolcano } from 'helpers';
import { BACKEND_ROUTES, SLIDER_VALUES } from 'constants';

export default class ExperimentStore {
  comparisons = null;
  datasets = null;
  datatypes = null;
  downloadLinks = null;
  geneLists = null;
  options = {
    comparisons: [],
    dataset: [],
    datatype: [],
    expressionGenes: [],
    geneList: [],
    species: [],
    volcanoGenes: [],
  };
  selectedVolcanoGenes = {};
  species = null;
  tableData = null;
  values = {
    comparisons: [],
    dataset: null,
    datatype: '',
    expressionGenes: [],
    geneList: '',
    geneListGenes: [],
    species: [],
    volcanoGenes: [],
  };
  violinData = {};
  volcanoData = {};

  constructor() {
    makeAutoObservable(this);
  }

  cleanOnSpeciesChange = () => {
    this.comparisons = null;
    this.options = {
      ...this.options,
      datatype: [],
      dataset: [],
      comparisons: [],
      expressionGenes: [],
      volcanoGenes: [],
    };
    this.tableData = null;
    this.values = {
      ...this.values,
      comparisons: [],
      datatype: '',
      dataset: '',
      expressionGenes: [],
      geneList: '',
      geneListGenes: [],
      volcanoGenes: [],
    };
    this.violinData = {};
    this.volcanoData = {};
  };

  cleanOnnDatatypeChange = () => {
    this.comparisons = null;
    this.options = {
      ...this.options,
      dataset: [],
      comparisons: [],
      expressionGenes: [],
      volcanoGenes: [],
    };
    this.tableData = null;
    this.values = {
      ...this.values,
      comparisons: [],
      dataset: '',
      expressionGenes: [],
      geneList: '',
      geneListGenes: [],
      volcanoGenes: [],
    };
    this.violinData = {};
    this.volcanoData = {};
  };

  clearOnDatasetChange = () => {
    this.comparisons = null;
    this.options = { ...this.options, comparisons: [], volcanoGenes: [] };
    this.tableData = null;
    this.values = {
      ...this.values,
      comparisons: [],
      volcanoGenes: [],
      expressionGenes: [],
      geneList: '',
      geneListGenes: [],
    };
    this.volcanoData = {};
    this.violinData = {};
  };

  cleanOnComparisonChange = () => {
    this.options = {
      ...this.options,
      volcanoGenes: [],
    };
    this.values = {
      ...this.values,
      volcanoGenes: [],
    };
    this.tableData = null;
    this.volcanoData = {};
  };

  clearVolcanoPlot = (comparisonId) => {
    runInAction(() => {
      this.values = { ...this.values, geneList: '', geneListGenes: [], volcanoGenes: [] };
      delete this.selectedVolcanoGenes[comparisonId];
    });
  };

  downloadViolin = async (geneId, cellType) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DOWNLOAD_VIOLIN), {
      params: {
        dataset_id: this.values.dataset.id,
        gene_id: geneId,
        cellType: cellType ?? '',
      },
    });
    return response;
  };

  downloadVolcanoTable = async () => {
    const comparisonIds = this.values.comparisons.map((comparison) => comparison.id);
    const response = await makeRequest(
      backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_DOWNLOAD_VOLCANO_TABLE, this.values.dataset.id, comparisonIds)
    );
    return response;
  };

  getSpecies = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_SPECIES));
    runInAction(() => {
      this.species = response.data.species;
      const speciesOptions = response.data.species.map((item) => ({ id: item, label: item }));
      this.options = { ...this.options, species: speciesOptions };
      const speciesValues = this.options.species.find((item) => item.label === 'All');
      this.values = { ...this.values, species: [speciesValues] };
    });
  };

  getDatatypes = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_DATATYPES), {
      params: {
        species_names: this.values.species.map((item) => item.id).join(','),
      },
    });
    const datatypeOptions = response.data.data_types.map((item) => ({ id: item, label: item }));
    runInAction(() => {
      this.datatypes = response.data.datatypes;
      this.options = { ...this.options, datatype: datatypeOptions };
    });
    return response;
  };

  getDatasets = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_DATASETS), {
      params: {
        species_names: this.values.species.map((item) => item.id).join(','),
        datatype: this.values.datatype,
      },
    });
    const datasetOptions = response.data.datasets
      .map((dataset) => ({
        description: dataset.description,
        id: dataset.id,
        label: dataset.display_name,
        species: dataset.species,
      }))
      .sort((a, b) => a.species.localeCompare(b.species));
    runInAction(() => {
      this.datasets = response.data.datasets;
      this.options = { ...this.options, dataset: datasetOptions };
    });
    return response;
  };

  getComparisons = async () => {
    const response = await makeRequest(
      backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_COMPARISONS, this.values.dataset.id)
    );
    const comparisonOptions = response.data.comparisons.map((item) => ({ id: item.id, label: item.name }));
    runInAction(() => {
      this.comparisons = response.data.comparisons;
      this.options = { ...this.options, comparisons: comparisonOptions };
    });
    return response;
  };

  getGenes = async (search, field) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_GENES), {
      params: {
        datatype: this.values.datatype,
        dataset_id: this.values.dataset.id,
        comparison_ids: this.values.comparisons.map((item) => item.id).join(','),
        search: search,
        limit: 10,
      },
    });
    if (response.status === 200) {
      const newOptions = [...this.values[field], ...response.data.genes];
      runInAction(() => {
        this.options = { ...this.options, [field]: newOptions };
      });
    }
    return response;
  };

  getDownloadLinks = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_LINKS), {
      params: this.values.dataset ? { dataset_id: this.values.dataset.id } : {},
    });
    runInAction(() => {
      this.downloadLinks = response.data;
    });
    return response;
  };

  getGeneLists = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS));
    const geneListOptions = response.data.map((item) => ({ id: item, label: item }));
    runInAction(() => {
      this.geneLists = response.data;
      this.options = { ...this.options, geneList: geneListOptions };
    });
    return response;
  };

  getGeneListGenes = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS_GENES, this.values.geneList));
    runInAction(() => {
      this.values = { ...this.values, geneListGenes: response.data };
    });
    return response;
  };

  getVolcano = async () => {
    const existingPlots = Object.keys(this.volcanoData);
    const comparisonIds = this.values.comparisons.map((comparison) => comparison.id.toString());

    for (const comparison of comparisonIds) {
      if (!existingPlots?.includes(comparison)) {
        const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_VOLCANO), {
          params: { dataset_id: this.values.dataset.id, comparison_ids: comparison },
        });
        runInAction(() => {
          const updatedPlot = updateVolcano(response.data[0].figure, 0, 1, 4);
          const formattedPlot = { ...response.data[0], figure: updatedPlot, sliderValues: SLIDER_VALUES };
          this.volcanoData = {
            ...this.volcanoData,
            [comparison]: formattedPlot,
          };
        });
      }
    }
    for (const loadedPlot of existingPlots) {
      if (!comparisonIds.includes(loadedPlot)) {
        runInAction(() => {
          delete this.volcanoData[loadedPlot];
        });
      }
    }
  };

  getTableData = async (page, search, comparisons, orderBy, order) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_TABLE), {
      params: {
        dataset_type: this.values.datatype,
        dataset_id: this.values.dataset.id,
        ids: comparisons.map((comparison) => comparison.id).join(','),
        limit: 10,
        offset: page * 10,
        search: search,
        // sorting: orderBy + '.' + order,
      },
    });
    runInAction(() => {
      this.tableData = response.data;
    });
    return response;
  };

  getSelectedVolcanoGenes = async (comparisonId, genes) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_SELECTED_VOLCANO_GENES), {
      method: 'POST',
      data: {
        dataset_type: this.values.datatype,
        dataset_id: this.values.dataset.id,
        comparison_id: Number(comparisonId),
        genes,
      },
    });
    runInAction(() => {
      this.selectedVolcanoGenes = { ...this.selectedVolcanoGenes, [comparisonId]: response.data };
    });
    return response;
  };

  getViolin = async () => {
    const existingViolins = Object.keys(this.violinData);
    const geneIds = this.values.expressionGenes.map((gene) => gene.id.toString());

    for (const gene of geneIds) {
      if (!existingViolins?.includes(gene)) {
        const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_VIOLIN), {
          params: { dataset_id: this.values.dataset.id, gene_ids: gene },
        });
        runInAction(() => {
          this.violinData = { ...this.violinData, [gene]: response.data };
        });
      }
    }
    existingViolins.forEach((violin) => {
      if (!geneIds.includes(violin)) {
        runInAction(() => {
          delete this.violinData[violin];
        });
      }
    });
  };

  getSharedData = async (species, datatype, datasetId, comparisonIds, volcanoGenes, expressionGenes) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.EXPERIMENTS_SHARE), {
      params: {
        ...(species && { species: species }),
        ...(datatype && { datatype: datatype }),
        ...(datasetId && { dataset_id: datasetId }),
        ...(comparisonIds && { comparison_ids: comparisonIds }),
        ...(volcanoGenes && { volcano_genes: volcanoGenes }),
        ...(expressionGenes && { expression_genes: expressionGenes }),
      },
    });

    if (response.status === 200) {
      const { data } = response;

      runInAction(() => {
        this.options = {
          ...this.options,
          species: data.species.map((item) => ({ id: item, label: item })),
          datatype: data.datatypes.map((item) => ({ id: item, label: item })),
          dataset: data.datasets.map((item) => ({
            description: item.description,
            id: item.id,
            label: item.display_name,
            species: item.species,
          })),
          comparisons: data.comparisons.map((item) => ({ id: item.id, label: item.name })),
          volcanoGenes: !isEmpty(data.volcano_genes)
            ? data.volcano_genes.map((item) => ({ id: item.id, name: item.name }))
            : [],
          expressionGenes: !isEmpty(data.expression_genes)
            ? data.expression_genes.map((item) => ({ id: item.id, name: item.name }))
            : [],
        };
      });

      const newValues = {
        comparisons: [],
        dataset: null,
        datatype: datatype,
        expressionGenes: [],
        species: [],
        volcanoGenes: [],
      };

      species
        .split(',')
        .forEach((speciesType) =>
          newValues.species.push(this.options.species.find((speciesTypeOption) => speciesTypeOption.id === speciesType))
        );
      newValues.dataset = this.options.dataset.find((dataset) => dataset.id === Number(datasetId));
      comparisonIds
        .split(',')
        .map((id) => Number(id))
        .forEach((comparisonId) =>
          newValues.comparisons.push(
            this.options.comparisons.find((comparisonOption) => comparisonOption.id === comparisonId)
          )
        );
      if (!isEmpty(this.options.volcanoGenes)) {
        const volcanoGenesData = volcanoGenes?.split(',');
        volcanoGenesData.forEach((gene) =>
          newValues.volcanoGenes.push(this.options.volcanoGenes.find((geneOption) => geneOption.name === gene))
        );
      }
      if (!isEmpty(this.options.expressionGenes)) {
        const expressionGenesData = expressionGenes?.split(',');
        expressionGenesData.forEach((gene) =>
          newValues.expressionGenes.push(this.options.expressionGenes.find((geneOption) => geneOption.name === gene))
        );
      }

      runInAction(() => {
        this.values = {
          ...this.values,
          ...newValues,
        };
      });
    }
  };

  setSliderValue = (value, comparisonId, field) => {
    this.volcanoData[comparisonId].sliderValues[field] = value;
  };

  setVolcano = (newData, id) => {
    this.volcanoData[id].figure = newData;
  };

  setValues = (newData, field) => {
    this.values = { ...this.values, [field]: newData };
  };

  reset = () => {
    this.comparisons = null;
    this.datasets = null;
    this.datatypes = null;
    this.downloadLinks = null;
    this.geneLists = null;
    this.options = {
      comparisons: [],
      dataset: [],
      datatype: [],
      expressionGenes: [],
      geneList: [],
      species: [],
      volcanoGenes: [],
    };
    this.selectedVolcanoGenes = {};
    this.species = null;
    this.tableData = null;
    this.values = {
      comparisons: [],
      dataset: '',
      datatype: '',
      expressionGenes: [],
      geneList: '',
      geneListGenes: [],
      species: [],
      volcanoGenes: [],
    };
    this.violinData = {};
    this.volcanoData = {};
  };
}
