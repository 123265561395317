import { useState, useCallback, useMemo, useEffect } from 'react';
import axiosInstance from 'interceptors';

const useAxiosLoading = () => {
  const [counter, setCounter] = useState(0);

  const inc = useCallback(() => setCounter((counter) => counter + 1), [setCounter]);
  const dec = useCallback(() => setCounter((counter) => counter - 1), [setCounter]);

  const interceptors = useMemo(
    () => ({
      request: (config) => {
        inc();
        return config;
      },
      response: (response) => {
        dec();
        return response;
      },
      error: (error) => {
        dec();
        return Promise.reject(error);
      },
    }),
    [inc, dec]
  );

  useEffect(() => {
    axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
    axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      axiosInstance.interceptors.request.eject(interceptors.request);
      axiosInstance.interceptors.request.eject(interceptors.error);
      axiosInstance.interceptors.response.eject(interceptors.response);
      axiosInstance.interceptors.response.eject(interceptors.error);
    };
  }, [interceptors]);

  return [counter > 0];
};

export default useAxiosLoading;
