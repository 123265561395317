import { makeAutoObservable, runInAction } from 'mobx';

import { BACKEND_ROUTES } from 'constants';
import { backendUrlBuilder, makeRequest } from 'helpers';

export default class TargetGenesStore {
  tableData = null;
  genes = [];
  gene = '';

  constructor() {
    makeAutoObservable(this);
  }

  getGenes = async (search) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.TARGET_GENE_GENE), {
      params: { search: search, limit: 10 },
    });
    if (response.status === 200) {
      const newOptions = response.data.genes.map((gene) => ({ id: gene, label: gene }));
      runInAction(() => {
        this.genes = newOptions;
      });
    }
    return response;
  };

  getData = async (gene) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.TARGET_DATA), {
      params: { gene_or_rs_id: gene.id, data_resolution: 'gene_level' },
    });
    runInAction(() => {
      this.tableData = response.data;
    });
    return response;
  };

  setGene = (gene) => {
    this.gene = gene;
  };
}
