import { useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Dropdown, Field } from 'components/common';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './CreateGroupModal.scss';

const CreateGroupModal = observer(() => {
  const { GroupStore, ModalStore, UserStore } = useStore();
  const { createGroup } = GroupStore;
  const { isCreateGroupOpen, toggleModal } = ModalStore;
  const { userOptions } = UserStore;

  const [groupName, setGroupName] = useState('');
  const [usersValue, setUsersValue] = useState([]);

  const closeModal = () => () => {
    toggleModal(MODALS.CREATE_GROUP);
  };

  const onGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const onUserChange = (value) => {
    setUsersValue(value);
  };

  const onSave = () => async () => {
    const saveData = {
      name: groupName,
      admin_group: false,
      default_group: false,
      datasets: [],
      users: usersValue.map((user) => user.id),
    };
    const response = await createGroup(saveData);
    if (response.status === 200) {
      setGroupName('');
      toggleModal(MODALS.CREATE_GROUP);
    }
  };

  return (
    <Modal open={isCreateGroupOpen} onClose={closeModal()}>
      <div className='create_group_modal_container'>
        <div className='create_group_modal_title_container'>
          <Typography variant='title'>Create new group</Typography>
        </div>
        <div className='create_group_modal_content'>
          <Field label='Group name' onChange={onGroupNameChange} value={groupName} />
          <Typography variant='small_title'>Group users</Typography>
          <div className='create_group_modal_collapse_container'>
            <Dropdown
              className='create_group_modal_dropdown'
              label='Select user'
              onChange={onUserChange}
              options={userOptions}
              value={usersValue}
              variant='autocomplete_multiple'
            />
          </div>
          <div className='create_group_modal_button_container'>
            <Button className='create_group_modal_cancel' onClick={closeModal()} variant='outlined'>
              Cancel
            </Button>
            <Button className='create_group_modal_save' onClick={onSave()} variant='contained'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default CreateGroupModal;
