import { makeAutoObservable, runInAction } from 'mobx';

import { backendUrlBuilder, makeRequest } from 'helpers';
import { BACKEND_ROUTES } from 'constants';

export default class GeneListStore {
  geneLists = [];
  geneListsOptions = [];
  genes = null;

  constructor() {
    makeAutoObservable(this);
  }

  getGeneLists = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS));
    const options = response.data.map((list) => ({ id: list, label: list }));
    runInAction(() => {
      this.geneLists = response.data;
      this.geneListsOptions = options;
    });
    return response;
  };

  getGeneListGenes = async (geneListName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS_GENES, geneListName));
    runInAction(() => {
      this.genes = response.data;
    });
    return response;
  };

  createGeneList = async (newData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS_CREATE), {
      data: newData,
      method: 'POST',
    });
    if (response.status === 200) {
      runInAction(() => {
        this.geneLists = [response.data.genes_group_name, ...this.geneLists];
        this.geneListsOptions = [
          ...this.geneListsOptions,
          { id: response.data.genes_group_name, label: response.data.genes_group_name },
        ];
      });
    }
    return response;
  };

  updateGeneList = async (newData) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS_CREATE), {
      data: newData,
      method: 'PUT',
    });
    if (response.status === 200) {
      const optionsCopy = [...this.geneListsOptions];
      const oldOptionIndex = optionsCopy.findIndex((option) => option.id === newData.target);
      optionsCopy.splice(oldOptionIndex, 1, { id: newData.genes_group_name, label: newData.genes_group_name });
      runInAction(() => {
        this.geneLists.splice(this.geneLists.indexOf(response.data.target), 1);
        this.geneLists.unshift(response.data.genes_group_name);
        this.genes = response.data.gene_names;
        this.geneListsOptions = optionsCopy;
      });
    }
    return response;
  };

  deleteGeneList = async (geneListName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS_DELETE, geneListName), {
      method: 'DELETE',
    });
    if (response.status === 200) {
      runInAction(() => {
        this.geneLists.splice(this.geneLists.indexOf(geneListName), 1);
        this.genes = null;
      });
    }
    return response;
  };
}
