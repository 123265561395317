import { NavLink } from 'react-router-dom';

import { GeneListButton } from 'components/common';

import './SubpageTabs.scss';

const SubpageTabs = ({ data }) => {
  const renderTabs = () => {
    return data.map((tab, index) => (
      <NavLink
        className={({ isActive }) => (isActive ? 'subpage_tab_link active' : 'subpage_tab_link')}
        key={index}
        to={tab.link}
      >
        {tab.label}
      </NavLink>
    ));
  };

  return (
    <div className='subpage_tabs_container'>
      <div className='subpage_tabs_wrapper'>{renderTabs()}</div>
      <GeneListButton />
    </div>
  );
};

export default SubpageTabs;
