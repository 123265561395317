import axiosInstance from 'interceptors';

const makeRequest = async (url, options = {}) => {
  const { method = 'get', data = null, params = {} } = options;
  try {
    const response = await axiosInstance({
      url,
      method,
      data,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error making request:', error);
    throw new Error('Failed to make the request.');
  }
};

export default makeRequest;
