import { makeAutoObservable } from 'mobx';

export default class SnackStore {
  isSnackOpened = false;
  message = '';
  severity = 'success';

  constructor() {
    makeAutoObservable(this);
  }

  closeSnack = () => {
    this.isSnackOpened = false;
  };

  openSuccessSnack = (message) => {
    this.isSnackOpened = true;
    this.message = message;
    this.severity = 'success';
  };

  openErrorSnack = (message) => {
    this.isSnackOpened = true;
    this.message = message;
    this.severity = 'error';
  };

  openWarningSnack = (message) => {
    this.isSnackOpened = true;
    this.message = message;
    this.severity = 'warning';
  };
}
