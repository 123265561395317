import { makeAutoObservable, runInAction } from 'mobx';

import { BACKEND_ROUTES } from 'constants';
import { backendUrlBuilder, isEmpty, makeRequest, updateFourWayPlot } from 'helpers';

export default class FourWayStore {
  availableQuadrants = null;
  options = {
    datatype: [],
    dataset: [],
    geneList: [],
    genes: [],
    xComparison: [],
    yComparison: [],
  };
  plot = null;
  selectedGenes = [];
  tableData = null;
  tableHeader = null;
  values = {
    datatype: '',
    geneList: '',
    geneListGenes: [],
    genes: [],
    xComparison: '',
    xDataset: null,
    xSliderMax: '1.96',
    xSliderMin: '-1.96',
    yComparison: '',
    yDataset: null,
    ySliderMax: '1.96',
    ySliderMin: '-1.96',
  };

  constructor() {
    makeAutoObservable(this);
  }

  cleanOnDatatypeChange = () => {
    this.availableQuadrants = null;
    this.options = {
      ...this.options,
      dataset: [],
      geneList: [],
      genes: [],
      xComparison: [],
      yComparison: [],
    };
    this.plot = null;
    this.selectedGenes = [];
    this.tableData = null;
    this.tableHeader = null;
    this.values = {
      ...this.values,
      geneList: '',
      geneListGenes: [],
      genes: [],
      xComparison: '',
      xDataset: null,
      xSliderMax: '1.96',
      xSliderMin: '-1.96',
      yComparison: '',
      yDataset: null,
      ySliderMax: '1.96',
      ySliderMin: '-1.96',
    };
  };

  cleanOnXDatasetChange = () => {
    this.availableQuadrants = null;
    this.options = {
      ...this.options,
      geneList: [],
      genes: [],
      xComparison: [],
    };
    this.plot = null;
    this.selectedGenes = [];
    this.tableData = null;
    this.tableHeader = null;
    this.values = {
      ...this.values,
      geneList: '',
      geneListGenes: [],
      genes: [],
      xComparison: '',
      xDataset: null,
      xSliderMax: '1.96',
      xSliderMin: '-1.96',
      ySliderMax: '1.96',
      ySliderMin: '-1.96',
    };
  };

  cleanOnYDatasetChange = () => {
    this.availableQuadrants = null;
    this.options = {
      ...this.options,
      geneList: [],
      genes: [],
      yComparison: [],
    };
    this.plot = null;
    this.selectedGenes = [];
    this.tableData = null;
    this.tableHeader = null;
    this.values = {
      ...this.values,
      geneList: '',
      geneListGenes: [],
      genes: [],
      yComparison: '',
      yDataset: null,
      xSliderMax: '1.96',
      xSliderMin: '-1.96',
      ySliderMax: '1.96',
      ySliderMin: '-1.96',
    };
  };

  clearPlot = () => {
    runInAction(() => {
      this.values = {
        ...this.values,
        xSliderMax: '1.96',
        xSliderMin: '-1.96',
        ySliderMax: '1.96',
        ySliderMin: '-1.96',
        geneList: '',
        geneListGenes: [],
        genes: [],
      };
      this.selectedGenes = [];
    });
  };

  downloadQuadrantFile = async (sign) => {
    const response = await makeRequest(
      backendUrlBuilder(
        BACKEND_ROUTES.FWP_DOWNLOAD_QUADRANT,
        this.values.xDataset.id,
        this.values.xComparison ? this.values.xComparison : '',
        this.values.yDataset.id,
        this.values.yComparison ? this.values.yComparison : '',
        this.values.datatype
      ),
      {
        params: {
          xaxis: `[${this.values.xSliderMin}, ${this.values.xSliderMax}]`,
          yaxis: `[${this.values.ySliderMin}, ${this.values.ySliderMax}]`,
          sign,
        },
      }
    );
    return response;
  };

  getDatatypes = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_DATATYPES));
    const datatypeOptions = response.data.data_types.map((datatype) => ({ id: datatype, label: datatype }));
    runInAction(() => {
      this.options = { ...this.options, datatype: datatypeOptions };
    });
    return response;
  };

  getDatasets = async (datatype) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_DATASETS), {
      params: { datatype: datatype },
    });
    const datasetOptions = response.data.datasets
      .map((dataset) => ({
        description: dataset.description,
        id: dataset.display_name,
        label: dataset.display_name,
        species: dataset.species,
      }))
      .sort((a, b) => a.species.localeCompare(b.species));
    runInAction(() => {
      this.options = { ...this.options, dataset: datasetOptions };
    });
    return response;
  };

  getComparisons = async (datatype, dataset, field) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_COMPARISONS), {
      params: { datatype: datatype, dataset: dataset.id },
    });
    let comparisonOptions = null;
    if (response.status === 200) {
      comparisonOptions = response.data.comparisons.map((comparison) => ({
        id: comparison.name,
        label: comparison.name,
      }));
      runInAction(() => {
        this.options = { ...this.options, [field]: comparisonOptions };
      });
    }
    return [comparisonOptions, response];
  };

  getPlot = async (datatype, xDataset, xComparison, yDataset, yComparison) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_PLOT), {
      params: {
        data_type: datatype,
        x_dataset_name: xDataset.id,
        x_comparison_name: xComparison,
        y_dataset_name: yDataset.id,
        y_comparison_name: yComparison,
      },
    });
    const [updatedFigure, quadrants] = updateFourWayPlot(
      response.data.figure,
      [Number(this.values.xSliderMin), Number(this.values.xSliderMax)],
      [Number(this.values.ySliderMin), Number(this.values.ySliderMax)],
      this.values.geneListGenes,
      this.values.genes.map((gene) => gene.label)
    );
    runInAction(() => {
      this.availableQuadrants = quadrants;
      this.plot = { ...response.data, figure: updatedFigure };
    });
  };

  getTable = async (datatype, xDataset, xComparison, yDataset, yComparison, page, search) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_TABLE), {
      params: {
        data_type: datatype,
        x_dataset: xDataset.id,
        x_comparison: xComparison,
        y_dataset: yDataset.id,
        y_comparison: yComparison,
        start: page * 10,
        limit: 10,
        search: search,
      },
    });
    runInAction(() => {
      this.tableData = response.data;
      if (!isEmpty(response.data?.data)) {
        this.tableHeader = Object.keys(response.data.data[0]);
      }
    });
    return response;
  };

  downloadTableData = async (datatype, xDataset, xComparison, yDataset, yComparison, limit) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_TABLE), {
      params: {
        data_type: datatype,
        x_dataset: xDataset.id,
        x_comparison: xComparison,
        y_dataset: yDataset.id,
        y_comparison: yComparison,
        limit: limit,
      },
    });
    return response;
  };

  getSelectedGenes = async (xDataset, xComparison, yDataset, yComparison, dataType, selectedGenes) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.FWP_SELECTED_GENES), {
      method: 'POST',
      data: {
        x_dataset: xDataset.id,
        x_comparison: xComparison,
        y_dataset: yDataset.id,
        y_comparison: yComparison,
        data_type: dataType,
        selected_genes: selectedGenes,
      },
    });
    if (response.status === 200) {
      runInAction(() => {
        this.selectedGenes = response.data.table;
      });
    }
    return response;
  };

  getGeneLists = async () => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS));
    const geneListOptions = response.data.map((item) => ({ id: item, label: item }));
    runInAction(() => {
      this.geneLists = response.data;
      this.options = { ...this.options, geneList: geneListOptions };
    });
    return response;
  };

  getGeneListGenes = async (geneListName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_LISTS_GENES, geneListName));
    runInAction(() => {
      this.values = { ...this.values, geneListGenes: response.data };
    });
    return response;
  };

  getGenes = async (search) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_NAMES), {
      params: { search: search, limit: 10 },
    });
    const newOptions = response.data.gene_unique_names.map((gene) => ({ id: gene, label: gene }));
    runInAction(() => {
      this.options = { ...this.options, genes: newOptions };
    });
    return response;
  };

  setPlot = (updatedFigure, updatedAvailableQuadrants) => {
    this.plot = { ...this.plot, figure: updatedFigure };
    this.availableQuadrants = updatedAvailableQuadrants;
  };

  setValues = (newData, field) => {
    this.values = { ...this.values, [field]: newData };
  };
}
