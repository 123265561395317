import { makeAutoObservable, runInAction } from 'mobx';

import { backendUrlBuilder, makeRequest } from 'helpers';
import { BACKEND_ROUTES } from 'constants';

export default class GeneStore {
  activeDataset = null;
  geneOptions = [];
  datasets = [];
  geneInfo = null;
  gene = null;
  heatmap = null;
  violinData = null;

  constructor() {
    makeAutoObservable(this);
  }

  downloadViolin = async (geneId, cellType) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.DOWNLOAD_VIOLIN), {
      params: {
        dataset_id: this.activeDataset.id,
        gene_id: geneId,
        cellType: cellType ?? '',
      },
    });
    return response;
  };

  getGenes = async (search) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_NAMES), {
      params: { search: search, limit: 10 },
    });
    const newOptions = response.data?.gene_unique_names
      ? response.data.gene_unique_names.map((gene) => ({ id: gene, label: gene }))
      : [];
    runInAction(() => {
      this.geneOptions = newOptions;
    });
    return response;
  };

  getGenesDetails = async (geneName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_DETAILS, geneName));
    runInAction(() => {
      this.geneInfo = response.data.gene_info;
      this.datasets = response.data.datasets;
    });
    return response;
  };

  getDatasets = async (geneName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_DATASETS), {
      params: { gene_name: geneName },
    });
    runInAction(() => {
      this.datasets = response.data.datasets;
    });
    return response;
  };

  getHeatmap = async (geneName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_HEATMAP), {
      params: { gene_name: geneName },
    });
    runInAction(() => {
      this.heatmap = response.data;
    });
    return response;
  };

  getViolin = async (geneName, datasetName) => {
    const response = await makeRequest(backendUrlBuilder(BACKEND_ROUTES.GENES_VIOLIN), {
      params: { gene_name: geneName, dataset_name: datasetName },
    });
    runInAction(() => {
      this.violinData = response.data;
    });
    return response;
  };

  setActiveDataset = (dataset) => {
    this.activeDataset = dataset;
  };

  setGene = (gene) => {
    this.gene = gene;
  };

  resetPlots = () => {
    this.heatmap = null;
    this.violinData = null;
  };
}
