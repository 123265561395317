import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './DatasetsSearchPanel.scss';

const DatasetsSearchPanel = observer(() => {
  const { DatasetStore, ModalStore } = useStore();
  const { datasets, datasetSearch, flushCache, setDatasetSearch } = DatasetStore;
  const { toggleModal } = ModalStore;

  const onFlushCache = () => () => {
    flushCache();
  };

  const openUploadModal = () => () => {
    toggleModal(MODALS.UPLOAD_DATASET);
  };

  const onSearch = (event) => {
    setDatasetSearch(event.target.value);
  };

  return (
    <div className='dataset_search_panel_container'>
      <div className='dataset_search_panel_info'>
        <Typography variant='title'>Datasets</Typography>
        <span className='dataset_search_panel_quantity'>{Object.keys(datasets).length}</span>
      </div>
      <div className='dataset_search_panel_actions'>
        <Field
          icon='search'
          iconPosition='end'
          label='Search'
          onChange={onSearch}
          value={datasetSearch}
          variant='filled'
        />
        <Button
          className='dataset_search_panel_button'
          color='primary'
          onClick={openUploadModal()}
          startIcon={<FileUploadIcon />}
          variant='contained'
        >
          Upload Dataset
        </Button>
        <Button
          className='dataset_search_panel_button'
          color='primary'
          onClick={onFlushCache()}
          startIcon={<CachedIcon />}
          variant='contained'
        >
          Flush Cache
        </Button>
      </div>
    </div>
  );
});

export default DatasetsSearchPanel;
