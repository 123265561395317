import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { SubpageTabs } from 'components/common';
import { TableSection, TargetGenesSidebar } from 'components/main/target_genes';

import { isEmpty } from 'helpers';
import { useStore } from 'store';

const TargetGenesPage = observer(() => {
  const { TargetGenesStore } = useStore();
  const { gene, getData, getGenes, setGene } = TargetGenesStore;

  const pageData = [
    {
      link: '/targets/genes',
      label: 'Gene',
    },
    {
      link: '/targets/snp',
      label: 'SNP',
    },
  ];

  const [geneSearch, setGeneSearch] = useState();

  const handleGeneSearch = (value) => {
    setGeneSearch(value);
  };

  const handleGeneChange = (newData) => {
    setGene(newData);
  };

  useEffect(() => {
    if (!isEmpty(geneSearch)) {
      getGenes(geneSearch);
    }
  }, [geneSearch, getGenes]);

  useEffect(() => {
    if (gene) {
      getData(gene);
    }
  }, [getData, gene]);

  return (
    <div className='targets_gene_container page_container'>
      <SubpageTabs data={pageData} />
      <div className='page_wrapper with_sidebar'>
        <TargetGenesSidebar onGeneChange={handleGeneChange} onSearch={handleGeneSearch} searchValue={geneSearch} />
        <div className='page_main fixed_sidebar'>
          <TableSection />
        </div>
      </div>
    </div>
  );
});

export default TargetGenesPage;
