import { observer } from 'mobx-react';

import { useStore } from 'store';

import './SnpDetail.scss';

const SnpDetails = observer(() => {
  const { TargetSnpStore } = useStore();
  const { tableData } = TargetSnpStore;

  const renderData = () => {
    return tableData.extra_data.map((row, index) => {
      if (row.name === 'Open.Target.URL.SNP-level') {
        return null;
      }
      return (
        <div className='snp_details_row' key={index}>
          <span>{row.name}</span> <span>{row.explanation}</span> <span>{row.value}</span>
        </div>
      );
    });
  };

  const renderLink = () => {
    const openTargetLink = tableData.extra_data.find((row) => row.name === 'Open.Target.URL.SNP-level').value;
    return (
      <div className='snp_detail_link_container'>
        <div>
          GWAS catalogue link:{' '}
          <a className='snp_detail_link' target='_blank' href={tableData.gwas_link} rel='noreferrer'>
            {tableData.gwas_link}
          </a>
        </div>
        <div>
          Open Targets link:{' '}
          <a className='snp_detail_link' target='_blank' href={openTargetLink} rel='noreferrer'>
            {openTargetLink}
          </a>
        </div>
      </div>
    );
  };

  if (!tableData?.extra_data) {
    return null;
  }

  return (
    <div className='snp_details_container'>
      {renderData()}
      {renderLink()}
    </div>
  );
});

export default SnpDetails;
