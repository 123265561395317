import { observer } from 'mobx-react';

import Plot from 'react-plotly.js';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';
import { FourWayGeneTable } from 'features/four_way';

import { PLOT_PARAMS, QUADRANT_SIGNS } from 'constants';
import { debounce, downloadFile } from 'helpers';
import { DownloadSingleQuadrantIcon } from 'static/icons';
import { useStore } from 'store';

import './PlotData.scss';

const PlotData = observer(() => {
  const { FourWayStore } = useStore();
  const { availableQuadrants, clearPlot, downloadQuadrantFile, getSelectedGenes, plot, setValues, values } =
    FourWayStore;

  const downloadQuadrant = (quadrant) => async () => {
    const response = await downloadQuadrantFile(QUADRANT_SIGNS[quadrant]);
    const fileName = `${values.datatype}&${quadrant}&
    ${values.xDataset.label}&${values.xComparison ? values.xComparison : ''}&${values.yDataset.label}&${
      values.yComparison ? values.yComparison : ''
    }.csv`;
    downloadFile(response.data, fileName);
  };

  const onFieldChange = (field) => (event) => {
    setValues(event.target.value, field);
  };

  const onClearPlotClick = () => () => {
    clearPlot();
  };

  const onGeneSelect = () =>
    debounce(async (event) => {
      const boxedGenes = [...new Set(event.points.map((gene) => gene.customdata[0]))]
        .sort((a, b) => a.localeCompare(b))
        .join(',');
      getSelectedGenes(
        values.xDataset,
        values.xComparison,
        values.yDataset,
        values.yComparison,
        values.datatype,
        boxedGenes
      );
    }, 300);

  const renderQuadrantButtons = () => {
    return Object.keys(availableQuadrants).map((quadrant, index) => {
      if (availableQuadrants[quadrant]) {
        return (
          <Button
            className={quadrant}
            color='primary'
            key={index}
            onClick={downloadQuadrant(quadrant)}
            startIcon={<DownloadIcon />}
            endIcon={<DownloadSingleQuadrantIcon />}
            variant='outlined'
          />
        );
      }
      return null;
    });
  };

  if (!plot) {
    return null;
  }

  return (
    <div className='plot_data_container'>
      <div className='plot_data_wrapper'>
        <div className='plot_data_plot_wrapper'>
          <Plot
            config={{ responsive: true }}
            data={plot.figure.data}
            layout={{ ...plot.figure.layout, autosize: true, dragmode: 'select' }}
            onSelecting={onGeneSelect()}
            useResizeHandler
          />
        </div>
        <div className='plot_data_side_wrapper'>
          <Typography variant='small_title'>Download quadrants</Typography>
          <div className='plot_data_qudrant_actions'>{renderQuadrantButtons()}</div>
          <Typography variant='small_title'>Plot details</Typography>
          <div className='plot_data_details'>
            <div className='plot_data_details_row'>
              <span className='plot_data_details_key'>Pearson r:</span>
              <span className='plot_data_details_value'>{plot.regression[PLOT_PARAMS.PCC]}</span>
            </div>
            <div className='plot_data_details_row'>
              <span className='plot_data_details_key'>p-value:</span>
              <span className='plot_data_details_value'>{plot.regression[PLOT_PARAMS.P]}</span>
            </div>
            <div className='plot_data_details_row'>
              <span className='plot_data_details_key'>R-squared:</span>
              <span className='plot_data_details_value'>{plot.regression[PLOT_PARAMS.R]}</span>
            </div>
          </div>
          <div className='axis_params_container'>
            <div className='axis_params_row'>
              <Field
                className='plot_param_input'
                label={`X-axis min (min: ${plot.sliders.x_slider.min})`}
                onChange={onFieldChange('xSliderMin')}
                type='number'
                value={values.xSliderMin}
              />
              <Field
                className='plot_param_input'
                label={`X-axis max (max: ${plot.sliders.x_slider.max})`}
                onChange={onFieldChange('xSliderMax')}
                type='number'
                value={values.xSliderMax}
              />
            </div>
            <div className='axis_params_row'>
              <Field
                className='plot_param_input'
                label={`Y-axis min (min: ${plot.sliders.y_slider.min})`}
                onChange={onFieldChange('ySliderMin')}
                type='number'
                value={values.ySliderMin}
              />
              <Field
                className='plot_param_input'
                label={`Y-axis max (max: ${plot.sliders.y_slider.max})`}
                onChange={onFieldChange('ySliderMax')}
                type='number'
                value={values.ySliderMax}
              />
            </div>
          </div>
          <div className='four_way_params'>
            <Button
              className='four_way_params_clear_button'
              color='primary'
              onClick={onClearPlotClick()}
              variant='outlined'
            >
              Clear selection
            </Button>
          </div>
        </div>
      </div>
      <FourWayGeneTable />
    </div>
  );
});

export default PlotData;
