import { useState } from 'react';
import { observer } from 'mobx-react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Dropdown, Field } from 'components/common';

import { SPECIES_ICONS } from 'constants';
import { useStore } from 'store';

import './GenesSidebar.scss';
import { isEmpty } from 'helpers';

const GenesSidebar = observer(({ onDatasetChange, onGeneChange, onSearch, searchValue, sortedDatasets }) => {
  const { GeneStore } = useStore();
  const { activeDataset, gene, geneOptions } = GeneStore;

  const [activeTab, setActiveTab] = useState('All');
  const [datasetSearch, setDatasetSearch] = useState('');

  const onActiveTabChange = (value) => () => {
    setActiveTab(value);
  };

  const onDatasetSearch = (event) => {
    setDatasetSearch(event.target.value.toLowerCase());
  };

  const renderDatasetList = () => {
    return Object.entries(sortedDatasets[activeTab]).map(([datatype, datasetList]) => {
      const filteredDatasetList = datasetList.filter((dataset) =>
        dataset.display_name.toLowerCase().includes(datasetSearch)
      );
      return (
        <>
          {!isEmpty(filteredDatasetList) && <div className='genes_sidebar_datatype_header'>{datatype}</div>}
          <div>
            {filteredDatasetList.map((item, index) => {
              const datasetClass =
                item.display_name === activeDataset?.display_name
                  ? 'genes_sidebar_dataset active'
                  : 'genes_sidebar_dataset';
              return (
                <Tooltip title={item.description} arrow key={index} placement='left'>
                  <div className={datasetClass} onClick={() => onDatasetChange(item)}>
                    <span className='gene_sidebar_dataset_label'>{item.display_name}</span>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </>
      );
    });
  };

  const renderTabName = (speciesName) => {
    if (speciesName === 'All') {
      return <span>All</span>;
    }
    const TabName = SPECIES_ICONS[speciesName];
    return <TabName />;
  };

  const renderTabs = () => {
    const availableSpecies = Object.keys(sortedDatasets);

    return availableSpecies.map((species, index) => {
      const datatypes = Object.keys(sortedDatasets[species]);
      let count = 0;
      datatypes.forEach((datatype) => (count += sortedDatasets[species][datatype].length));
      return (
        <div
          className={species === activeTab ? 'gene_tab active' : 'gene_tab'}
          key={index}
          onClick={onActiveTabChange(species)}
        >
          {renderTabName(species)}
          <span className='gene_tab_count'>{count}</span>
        </div>
      );
    });
  };

  return (
    <div className='genes_sidebar_container'>
      <div className='gene_sidebar_controls_container'>
        <Typography className='gene_sidebar_title' variant='title'>
          Gene Search
        </Typography>
        <Dropdown
          inputValue={searchValue}
          field='gene'
          label='Gene'
          variant='autocomplete_single'
          onInputChange={onSearch}
          onChange={onGeneChange}
          options={geneOptions}
          value={gene}
        />
        {sortedDatasets && (
          <>
            <Typography className='gene_sidebar_subtitle' variant='small_title'>
              Datasets
            </Typography>
            <div className='gene_tabs_container'>{renderTabs()}</div>
            <Field
              icon='search'
              iconPosition='end'
              label='Search'
              onChange={onDatasetSearch}
              variant='filled'
              value={datasetSearch}
            />
            <div className='genes_sidebar_datasets_container'>{renderDatasetList()}</div>
          </>
        )}
      </div>
    </div>
  );
});

export default GenesSidebar;
