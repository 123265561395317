import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@mui/material/styles';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
// import ReactGA from 'react-ga4';

import {
  DatasetsPage,
  ExperimentsPage,
  FourWayPage,
  GenesPage,
  GroupsPage,
  HomePage,
  MultigenePage,
  TargetGenesPage,
  TargetSnpPage,
  UsersPage,
} from 'pages';

import { Loader, Navbar, Snack, SecureRoute } from 'components/common';

import MainTheme from 'theme';
import { useStore } from 'store';

import './scss/reset.scss';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  responseType: 'code',
  scopes: ['offline_access', 'openid', 'email'],
});

const history = createBrowserHistory();

const restoreOriginalUri = async (_oktaAuth) => {
  window.location.href = '/';
};

const App = () => {
  const { AuthStore } = useStore();
  const { getUserData, setAccessToken, setAccessTokenExpireDate, setAuthStatus, setUserEmail } = AuthStore;

  useEffect(() => {
    const onAuthStateChange = async (authState) => {
      if (authState.accessToken) {
        const {
          accessToken: { accessToken, expiresAt },
        } = authState;
        setAccessToken(accessToken);
        setAccessTokenExpireDate(expiresAt);
        setAuthStatus(true);
        const email = (await oktaAuth.token.getUserInfo()).email;
        if (email) {
          setUserEmail(email);
          getUserData(email);
        }
      }
    };

    oktaAuth.authStateManager.subscribe(onAuthStateChange);

    return () => {
      oktaAuth.authStateManager.unsubscribe(onAuthStateChange);
    };
  }, [getUserData, setAccessToken, setAccessTokenExpireDate, setAuthStatus, setUserEmail]);

  const buildProvidersTree = (componentsWithProps) => {
    const initialComponent = ({ children }) => <>{children}</>;
    return componentsWithProps.reduce((AccumulatedComponents, [Provider, props = {}]) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <Provider {...props}>{children}</Provider>
          </AccumulatedComponents>
        );
      };
    }, initialComponent);
  };

  const ProvidersTree = buildProvidersTree([
    [StrictMode],
    [BrowserRouter, { history }],
    [ThemeProvider, { theme: MainTheme }],
    [Security, { oktaAuth, restoreOriginalUri }],
  ]);

  return (
    <ProvidersTree>
      <Navbar />
      <Snack />
      <Loader />
      <Routes>
        <Route path='/' exact={true} element={<HomePage />} />
        <Route path='/login/callback' element={<LoginCallback />} />

        <Route
          path='/experiments'
          element={
            <SecureRoute history={history}>
              <ExperimentsPage />
            </SecureRoute>
          }
        />
        <Route
          path='/genes'
          element={
            <SecureRoute history={history}>
              <GenesPage />
            </SecureRoute>
          }
        />
        <Route
          path='/analysis/four-way'
          element={
            <SecureRoute history={history}>
              <FourWayPage />
            </SecureRoute>
          }
        />
        <Route
          path='/analysis/multigene'
          element={
            <SecureRoute history={history}>
              <MultigenePage />
            </SecureRoute>
          }
        />
        <Route
          path='/targets/snp'
          element={
            <SecureRoute history={history}>
              <TargetSnpPage />
            </SecureRoute>
          }
        />
        <Route
          path='/targets/genes'
          element={
            <SecureRoute history={history}>
              <TargetGenesPage />
            </SecureRoute>
          }
        />
        <Route
          path='/admin'
          element={
            <SecureRoute history={history}>
              <div>Admin</div>
            </SecureRoute>
          }
        />

        <Route
          path='/admin/users'
          element={
            <SecureRoute history={history}>
              <UsersPage />
            </SecureRoute>
          }
        />
        <Route
          path='/admin/groups'
          element={
            <SecureRoute history={history}>
              <GroupsPage />
            </SecureRoute>
          }
        />
        <Route
          path='/admin/datasets'
          element={
            <SecureRoute history={history}>
              <DatasetsPage />
            </SecureRoute>
          }
        />
      </Routes>
    </ProvidersTree>
  );
};
// ReactGA.initialize('G-BWZP2B4Y98');
const root = ReactDOM.createRoot(document.getElementById('root'));
// const SendAnalytics = ()=> {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }

root.render(<App />);

export default oktaAuth;
