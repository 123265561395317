import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { Collapse, Dropdown, Field, GeneSelectTabs } from 'components/common';

import { useStore } from 'store';

import './MultigeneSidebar.scss';

const MultigeneSidebar = observer(
  ({
    activeTab,
    addDatasetTreatmentData,
    datasetTreatmentData,
    geneList,
    onDatasetChange,
    onFileCreate,
    onGeneListChange,
    onQueryNameChange,
    onCustomGenesBlur,
    onCustomGenesChange,
    onTabChange,
    onTreatmentChange,
    queryName,
    specificGenes,
    specificGenesError,
  }) => {
    const { MultigeneStore } = useStore();
    const { datasetOptions, geneListOptions } = MultigeneStore;

    const renderDatasetTreatmentBlocks = () => {
      return datasetTreatmentData.map((item, index) => {
        return (
          <Collapse key={index} title={'Dataset ' + (index + 1)}>
            <Dropdown
              field={index}
              label='Dataset'
              onChange={onDatasetChange}
              options={datasetOptions}
              value={item.dataset}
            />
            <Dropdown
              field={index}
              label='Treatments'
              onChange={onTreatmentChange}
              options={item.treatmentOptions}
              value={item.treatment}
            />
          </Collapse>
        );
      });
    };

    return (
      <div className='multigene_sidebar_container'>
        <Typography className='multigene_sidebar_title' variant='title'>
          Multi-gene Expression
        </Typography>
        <Field label='Query name' onChange={onQueryNameChange} value={queryName} />
        <Typography className='multigene_sidebar_subtitle' variant='small_title'>
          Genes
        </Typography>

        <GeneSelectTabs
          activeTab={activeTab}
          geneList={geneList}
          geneListOptions={geneListOptions}
          onGeneListChange={onGeneListChange}
          onCustomGenesBlur={onCustomGenesBlur}
          onCustomGenesChange={onCustomGenesChange}
          onTabChange={onTabChange}
          specificGenes={specificGenes}
          specificGenesError={specificGenesError}
        />

        <Typography className='multigene_sidebar_subtitle' variant='small_title'>
          Dataset + Treatments
        </Typography>

        {renderDatasetTreatmentBlocks()}

        <Button
          className='multigene_sidebar_add'
          onClick={() => addDatasetTreatmentData()}
          startIcon={<AddIcon />}
          color='primary'
        >
          Add Dataset
        </Button>
        <Button className='multigene_sidebar_generate' color='primary' onClick={onFileCreate} variant='contained'>
          Generate File
        </Button>
        <Button className='multigene_sidebar_reset' color='primary' startIcon={<CloseIcon />}>
          Reset
        </Button>
      </div>
    );
  }
);

export default MultigeneSidebar;
