import { useState } from 'react';
import { observer } from 'mobx-react';
import Plotly from 'plotly.js/dist/plotly';
import createPlotlyComponent from 'react-plotly.js/factory';

import { debounce, volcanoAddAnnotations } from 'helpers';
import { useStore } from 'store';

Plotly.setPlotConfig({ logging: 0 });
const Plot = createPlotlyComponent(Plotly);

const VolcanoPlot = observer(({ volcanoId }) => {
  const { ExperimentStore } = useStore();
  // Import of unused value is needed to rerender Plotly plot
  const { getSelectedVolcanoGenes, setVolcano, volcanoData } = ExperimentStore;

  const [clickedGenes, setClickedGenes] = useState([]);

  const volcano = volcanoData[volcanoId];
  const { figure } = volcano;
  const { data, layout } = figure;

  const onGeneClick = () => (event) => {
    // .slice is used to crop gene name, starting from '<br>GENE: '
    const clickedGene = event.points[0].text.slice(10);
    const newClickedGenes = [];
    if (!clickedGenes.includes(clickedGene)) {
      newClickedGenes.push(...clickedGenes, clickedGene);
    } else {
      newClickedGenes.push(...[...clickedGenes].filter((gene) => gene !== clickedGene));
    }
    setClickedGenes(newClickedGenes);
    const annotatedData = volcanoAddAnnotations(figure, [], [], newClickedGenes);
    setVolcano(annotatedData, volcanoId);
  };

  const onGeneSelect = () =>
    debounce(async (event) => {
      const boxedGenes = [...new Set(event.points.map((gene) => gene.text.slice(10)))]
        .sort((a, b) => a.localeCompare(b))
        .join(',');
      getSelectedVolcanoGenes(volcanoId, boxedGenes);
    }, 300);

  return (
    <Plot
      config={{ responsive: true }}
      data={data}
      layout={layout}
      onClick={onGeneClick()}
      onSelecting={onGeneSelect()}
      style={{ height: '600px', width: '100%' }}
      useResizeHandler
    />
  );
});

export default VolcanoPlot;
