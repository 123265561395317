import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';

import { SPECIES_ICONS } from 'constants';
import { useStore } from 'store';

import './GeneInfo.scss';

const GeneInfo = observer(() => {
  const { GeneStore } = useStore();
  const { geneInfo } = GeneStore;

  const renderLinks = () => {
    return geneInfo.links.map((linkBlock, index) => {
      return (
        <div className='gene_links_section' key={index}>
          <div className='gene_links_wrapper'>
            {linkBlock.map((link, linkIndex) => {
              return (
                <div className='gene_link_source' key={linkIndex}>
                  <span className='gene_link_name'>{link.title}:</span>
                  <a className='gene_link_link' href={link.link} target='_blank' rel='noreferrer'>
                    {link.label}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  const renderSynonyms = () => {
    return geneInfo.synonyms.map((item, index) => {
      const SpeciesIcon = SPECIES_ICONS[item.specie];
      return (
        <div className='gene_species_synonyms' key={index}>
          <SpeciesIcon />
          <span>{item.synonyms}</span>
        </div>
      );
    });
  };

  if (!geneInfo) {
    return null;
  }

  return (
    <div className='gene_info_container'>
      <div className='gene_header_container'>
        <div className='gene_header_info'>
          <Typography className='gene_header_header' variant='great_title'>
            {geneInfo.title}
          </Typography>
          <Typography variant='small_title'>{geneInfo.protein}</Typography>
        </div>
        <Button className='gene_header_button' color='primary' startIcon={<StarBorderIcon />}>
          Add to Favorites
        </Button>
      </div>

      <div className='gene_links_container'>{renderLinks()}</div>
      <div className='gene_synonyms_container'>
        <Typography variant='small_title'>Synonyms</Typography>
        <div className='gene_synonyms'>{renderSynonyms()}</div>
      </div>
      {geneInfo.description && <div className='gene_description_container'>{geneInfo.description}</div>}
    </div>
  );
});

export default GeneInfo;
