import {
  AnalysisIcon,
  ChartIcon,
  DatasetIcon,
  GeneIcon,
  GroupIcon,
  TargetIcon,
  TubeIcon,
  UserIcon,
} from 'static/icons';

const ADMIN_ROUTES = [
  { name: 'Groups', link: '/admin/groups', icon: UserIcon },
  { name: 'Users', link: '/admin/users', icon: GroupIcon },
  { name: 'Datasets', link: '/admin/datasets', icon: DatasetIcon },
  { name: 'Charts', link: '/', icon: ChartIcon },
];

const MAIN_ROUTES = [
  { name: 'Experiments', link: '/experiments', icon: TubeIcon },
  { name: 'Genes', link: '/genes', icon: GeneIcon },
  { name: 'Analysis', link: '/analysis/four-way', icon: AnalysisIcon },
  { name: 'Targets', link: '/targets/genes', icon: TargetIcon },
];

const BACKEND_ROUTES = {
  DATASETS_CHECK_MAPPING: 'admin-uploaded-datasets/check-expression',
  DATASETS_COVARIATES: 'admin-uploaded-datasets/covariates-mapping',
  DATASETS_DATASETS: 'admin-uploaded-datasets/available-datasets-list',
  DATASETS_DATATYPES: 'admin-uploaded-datasets/data-types',
  DATASETS_DELETE: 'admin-uploaded-datasets/delete',
  DATASETS_GET: 'admin-uploaded-datasets/',
  DATASETS_PROBES: 'admin-uploaded-datasets/available-probes',
  DATASETS_SPECIES: 'admin-uploaded-datasets/available-species',
  DATASETS_UPDATE: 'admin-uploaded-datasets/update',
  DATASETS_UPLOAD: 'admin-uploaded-datasets/start-upload',

  DOWNLOAD_VIOLIN: 'experiments/violin/graph-data',

  EXPERIMENTS_COMPARISONS: 'experiments/comparisons',
  EXPERIMENTS_DATASETS: 'experiments/datasets',
  EXPERIMENTS_DATATYPES: 'experiments/datatypes',
  EXPERIMENTS_DOWNLOAD_VOLCANO_TABLE: 'experiments/comparisons/table-download',
  EXPERIMENTS_GENES: 'experiments/genes',
  EXPERIMENTS_LINKS: 'experiments/download',
  EXPERIMENTS_SELECTED_VOLCANO_GENES: 'experiments/comparisons/selected-genes-table',
  EXPERIMENTS_SHARE: 'copy-link/experiments',
  EXPERIMENTS_SPECIES: 'experiments/species',
  EXPERIMENTS_TABLE: 'experiments/comparisons/table',
  EXPERIMENTS_VIOLIN: 'experiments/violin',
  EXPERIMENTS_VOLCANO: 'experiments/volcano',

  FLUSH_CACHE: 'admin/cache',

  FWP_COMPARISONS: 'fwp/comparisons',
  FWP_DATASETS: 'fwp/datasets',
  FWP_DATATYPES: 'fwp/datatypes',
  FWP_DOWNLOAD_QUADRANT: 'quadrant-downloads',
  FWP_PLOT: 'fwp/plot',
  FWP_SELECTED_GENES: 'fwp/data-table',
  FWP_TABLE: 'fwp/data-table',

  GENES_DATASETS: 'genes/datasets',
  GENES_DETAILS: 'genes/detail',
  GENES_HEATMAP: 'genes/odhm',
  GENES_NAMES: 'genes/names-unique',
  GENES_VIOLIN: 'genes/violin',

  GENES_LISTS_CREATE: 'general/genes-lists',
  GENES_LISTS_DELETE: 'general/genes-lists',
  GENES_LISTS_EDIT: 'general/genes-lists',
  GENES_LISTS_GENES: 'general/genes-list-genes',
  GENES_LISTS: 'general/genes-lists',

  GROUPS_DELETE: 'admin-groups/delete',
  GROUPS_GET: 'admin-groups/',
  GROUPS_PATCH: 'admin-groups/update',
  GROUPS_POST: 'admin-groups/create',
  GROUPS_PUT: 'admin-groups/update',

  MGE_CREATE: 'mge/create-result-file',
  MGE_DATASETS: 'mge/datasets',
  MGE_DOWNLOAD: 'multi-gene-download',
  MGE_DELETE: 'delete-expression-file',
  MGE_FILES: 'mge/files',
  MGE_REFRESH: 'general/user-file-detail/',
  MGE_TREATMENTS: 'mge/treatments',

  TARGET_CREATE: 'target/make-targets-file',
  TARGET_DELETE: 'target/delete-target-file',
  TARGET_DROPDOWNS: 'target/dropdowns',
  TARGET_FILES: 'target/target-user-files',
  TARGET_REFRESH: 'general/user-file-detail/',
  TARGET_DATA: 'target/single-cell-data',

  TARGET_GENE_GENE: 'target/single-gene',

  TARGET_SNP_RS_ID: 'target/snp-rs-ids',

  TARGET_UPDATE: 'target/target-user-files',

  USERS_DELETE: 'admin-users/delete',
  USERS_INFO: 'admin-users/detail',
  USERS_GET: 'admin-users/',
};

export { ADMIN_ROUTES, BACKEND_ROUTES, MAIN_ROUTES };
