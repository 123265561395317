import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { useStore } from 'store';
import { MODALS } from 'constants';

import './EditQueryName.scss';

const EditQueryName = observer(({ initialQueryName, onChange, onUpdate, queryName }) => {
  const { ModalStore } = useStore();
  const { isEditQueryNameOpen, toggleModal } = ModalStore;

  return (
    <Modal open={isEditQueryNameOpen} onClose={() => toggleModal(MODALS.EDIT_QUERY_NAME)}>
      <div className='edit_query_name_modal_container'>
        <div className='edit_query_name_modal_title_container'>
          <Typography variant='title'>Edit Query Name</Typography>
        </div>
        <div className='edit_query_name_modal_content'>
          <Field className='query_name' label='Query name' onChange={onChange} value={queryName} />
        </div>
        <div className='edit_query_name_modal_button_container'>
          <Button
            className='edit_query_name_modal_cancel'
            onClick={() => toggleModal(MODALS.EDIT_QUERY_NAME)}
            varian='outlined'
          >
            Cancel
          </Button>
          <Button className='edit_query_name_modal_save' onClick={() => onUpdate(initialQueryName)} variant='contained'>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default EditQueryName;
