import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const UserIcon = ({ color = COLORS.GREY, className }) => {
  return (
    <SvgIcon className={className}>
      <circle cx='12' cy='9' r='5' stroke={color} fill='none' strokeWidth='2' />
      <path
        d='M19 21C19 17.134 15.866 14 12 14C8.13401 14 5 17.134 5 21'
        stroke={color}
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </SvgIcon>
  );
};
