import SvgIcon from '@mui/material/SvgIcon';

import { COLORS } from 'constants';

export const DownloadSingleQuadrantIcon = ({ color = COLORS.TEXT_DARK, className }) => {
  return (
    <SvgIcon className={className}>
      <path d='M5 12H19' stroke={color} fill='none' strokeWidth='2' strokeLinecap='round' />
      <path d='M12 19L12 5' stroke={color} fill='none' strokeWidth='2' strokeLinecap='round' />
      <rect x='4' y='4' width='5' height='5' rx='1' fill={color} />
    </SvgIcon>
  );
};
