import { makeAutoObservable, runInAction } from 'mobx';

export default class LoadingStore {
  externalLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setExternalLoading = () => {
    runInAction(() => {
      this.externalLoading = !this.externalLoading;
    });
  };
}
