import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import { classConcat } from 'helpers';

import './Field.scss';

const Field = ({
  className,
  error,
  errorText,
  icon,
  iconPosition,
  label,
  multiline,
  onBlur = () => {},
  onChange,
  rows = 1,
  type = 'text',
  value,
  variant = 'filled',
}) => {
  const ICONS = {
    search: SearchIcon,
  };

  const getIconProps = () => {
    const Icon = ICONS[icon];
    if (iconPosition === 'start') {
      return {
        startAdornment: (
          <InputAdornment position='start'>
            <Icon />
          </InputAdornment>
        ),
      };
    }
    if (iconPosition === 'end') {
      return {
        endAdornment: (
          <InputAdornment position='end'>
            <Icon />
          </InputAdornment>
        ),
      };
    }
    return {};
  };

  return (
    <TextField
      className={classConcat('field', className)}
      error={error}
      InputProps={getIconProps()}
      helperText={error && errorText}
      label={label}
      multiline={multiline}
      onBlur={(event) => onBlur(event)}
      onChange={(event) => onChange(event)}
      rows={rows}
      type={type}
      variant={variant}
      value={value}
    />
  );
};

export default Field;
