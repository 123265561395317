import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { ExperimentsSidebar, ExperimentsTable } from 'components/main/experiments';
import { GeneList, TopMenu, ViolinSection } from 'components/common';

import { VolcanoSection } from 'features/experiments';

import { GENE_TYPES, MODALS } from 'constants';
import { isEmpty, volcanoAddAnnotations } from 'helpers';
import { useStore } from 'store';

import 'scss/pages.scss';

const ExperimentsPage = observer(() => {
  const { ExperimentStore, ModalStore } = useStore();
  const { toggleModal } = ModalStore;
  const {
    downloadLinks,
    downloadViolin,
    getComparisons,
    getDatasets,
    getDatatypes,
    getDownloadLinks,
    getGeneListGenes,
    getGenes,
    getSharedData,
    getSpecies,
    getTableData,
    getViolin,
    getVolcano,
    options,
    reset,
    setVolcano,
    values,
    violinData,
    volcanoData,
  } = ExperimentStore;

  const [geneSearch, setGeneSearch] = useState({
    volcanoGenes: '',
    expressionGenes: '',
  });
  const [page, setPage] = useState(0);
  const [shared, setShared] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const breadcrumbs = [
    {
      link: '/experiments',
      text: 'Experiments',
    },
    {
      text: 'Experiment details',
    },
  ];

  const handleGeneSearch = (value, field) => {
    setGeneSearch({ ...geneSearch, [field]: value });
  };

  const annotateVolcanoGenes = useCallback(() => {
    const volcanoPlotData = Object.entries(volcanoData);
    const volcanoGenes = values.volcanoGenes.map((gene) => gene.name);
    volcanoPlotData.forEach((plotData) => {
      const [id, data] = plotData;
      const annotatedData = volcanoAddAnnotations(data.figure, volcanoGenes, values.geneListGenes);
      setVolcano(annotatedData, id);
    });
  }, [values.geneListGenes, setVolcano, values.volcanoGenes, volcanoData]);

  const getOptionsViaLink = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const speciesValues = params.get('species');
    const datatypeValues = params.get('datatype');
    const datasetValues = params.get('dataset_id');
    const comparisonValues = params.get('comparisons_ids');
    const volcanoGenesValues = params.get('volcanoGenes');
    const expressionGenesValues = params.get('expressionGenes');

    getSharedData(
      speciesValues,
      datatypeValues,
      datasetValues,
      comparisonValues,
      volcanoGenesValues,
      expressionGenesValues
    );
  }, [getSharedData]);

  const handleOpenAddToFavoritesModal = () => {
    toggleModal(MODALS.ADD_EXPERIMENT);
  };

  const handleCopyLink = () => {
    const queryParams = {
      species: !isEmpty(values?.species) ? `?species=${values.species.map((item) => item.id).join(',')}` : '',
      datatype: !isEmpty(values?.datatype) ? `&datatype=${values.datatype}` : '',
      dataset: !isEmpty(values?.dataset) ? `&dataset_id=${values.dataset.id}` : '',
      comparisons: !isEmpty(values?.comparisons)
        ? `&comparisons_ids=${values.comparisons.map((comparison) => comparison.id).join(',')}`
        : '',
      volcanoGenes: !isEmpty(values?.volcanoGenes)
        ? `&volcanoGenes=${values.volcanoGenes.map((gene) => gene.name).join(',')}`
        : '',
      expressionGenes: !isEmpty(values?.expressionGenes)
        ? `&expressionGenes=${values.expressionGenes.map((gene) => gene.name).join(',')}`
        : '',
    };
    const link =
      window.location.origin +
      window.location.pathname +
      queryParams.species +
      queryParams.datatype +
      queryParams.dataset +
      queryParams.comparisons +
      queryParams.volcanoGenes +
      queryParams.expressionGenes;
    navigator.clipboard.writeText(link);
  };

  const handleTableSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTableGeneFilterChange = (event) => {
    setTableFilter(event.target.value);
  };

  const handleTablePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleReset = () => {
    reset();
  };

  // Defining what to be loaded first depending on url
  useEffect(() => {
    if (window.location.search && !shared) {
      setShared(true);
      getOptionsViaLink();
    } else if (isEmpty(options?.species) && !shared) {
      getSpecies();
    }
  }, [shared, getOptionsViaLink, getSpecies, options.species]);

  // Getting datatypes
  useEffect(() => {
    if (!isEmpty(values?.species) && !shared) {
      getDatatypes();
    }
  }, [getDatatypes, shared, values.species]);

  // Getting datasets
  useEffect(() => {
    if (!isEmpty(values?.datatype) && !shared) {
      getDatasets();
    }
  }, [getDatasets, shared, values.datatype, values.species]);

  // Getting comparisons
  useEffect(() => {
    if (values?.dataset && isEmpty(values?.comparisons)) {
      getComparisons();
    }
  }, [getComparisons, values.dataset, values.comparisons]);

  // Getting volcano genes
  useEffect(() => {
    if (!isEmpty(geneSearch?.volcanoGenes)) {
      getGenes(geneSearch.volcanoGenes, GENE_TYPES.VOLCANO);
    }
  }, [values.dataset, values.datatype, geneSearch.volcanoGenes, getGenes, values.comparisons]);

  // Getting expression genes
  useEffect(() => {
    if (!isEmpty(geneSearch?.expressionGenes)) {
      getGenes(geneSearch.expressionGenes, GENE_TYPES.VIOLIN);
    }
  }, [values.dataset, values.datatype, geneSearch.expressionGenes, getGenes, values.comparisons]);

  // Getting volcano data
  useEffect(() => {
    if (!isEmpty(values.comparisons)) {
      getVolcano();
    }
  }, [getVolcano, values.comparisons, volcanoData]);

  // Getting volcano table data
  useEffect(() => {
    if (!isEmpty(values.comparisons)) {
      getTableData(page, tableFilter, values.comparisons, orderBy, order);
    }
  }, [getTableData, page, tableFilter, order, orderBy, values.comparisons]);

  // Getting violin plot
  useEffect(() => {
    if (!isEmpty(values.expressionGenes)) {
      getViolin();
    }
  }, [getViolin, values.expressionGenes]);

  // Getting download links
  useEffect(() => {
    getDownloadLinks();
  }, [getDownloadLinks, values.dataset]);

  // Annotating volcano genes
  useEffect(() => {
    annotateVolcanoGenes();
  }, [annotateVolcanoGenes, values.geneListGenes, values.volcanoGenes]);

  // Getting genes included in gene list
  useEffect(() => {
    if (values.geneList) {
      getGeneListGenes();
    }
  }, [getGeneListGenes, values.geneList]);

  return (
    <div className='experiments_container page_container'>
      <TopMenu breadcrumbs={breadcrumbs} />
      <div className='page_wrapper with_sidebar'>
        <ExperimentsSidebar
          links={downloadLinks}
          geneSearch={geneSearch}
          onGeneSearch={handleGeneSearch}
          openAddToFavoritesModal={handleOpenAddToFavoritesModal}
          onReset={handleReset}
          onCopyLink={handleCopyLink}
        />
        <div className='page_main'>
          <VolcanoSection />
          <ExperimentsTable
            filterValue={tableFilter}
            onFilterChange={handleTableGeneFilterChange}
            onPageChange={handleTablePageChange}
            onSort={handleTableSort}
            order={order}
            orderBy={orderBy}
            page={page}
          />
          <ViolinSection data={violinData} downloadViolin={downloadViolin} />
        </div>
      </div>
      <GeneList />
    </div>
  );
});

export default ExperimentsPage;
