import { Link, NavLink, useLocation } from 'react-router-dom';

import { ADMIN_ROUTES, COLORS, MAIN_ROUTES } from 'constants';
import { Logo } from 'static/images/Logo';

import './Navbar.scss';

const Navbar = () => {
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  const renderLinks = (linkMap) => {
    return linkMap.map((item, index) => {
      return (
        <div className='navbar_link_container' key={index}>
          <NavLink className={({ isActive }) => (isActive ? 'navbar_link active' : 'navbar_link')} to={item.link}>
            {({ isActive }) => {
              return (
                <>
                  <item.icon className='navbar_icon' color={isActive ? COLORS.MAIN : COLORS.GREY} />
                  {item.name}
                </>
              );
            }}
          </NavLink>
        </div>
      );
    });
  };

  return (
    <div className='navbar_container'>
      <div className='navbar_logo_container'>
        <Link className='navbar_logo_link' to='/'>
          <Logo className='navbar_logo' />
        </Link>
      </div>
      {isAdmin ? renderLinks(ADMIN_ROUTES) : renderLinks(MAIN_ROUTES)}
    </div>
  );
};

export default Navbar;
