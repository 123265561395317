import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Dropdown, Field } from 'components/common';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './EditDatasetModal.scss';

const EditDatasetModal = observer(() => {
  const { DatasetStore, GroupStore, ModalStore } = useStore();
  const { activeDataset, updateDataset } = DatasetStore;
  const { groupOptions } = GroupStore;
  const { isEditDatasetOpen, toggleModal } = ModalStore;

  const [datasetName, setDatasetName] = useState('');
  const [groupsValue, setGroupsValue] = useState([]);

  const closeModal = () => () => {
    toggleModal(MODALS.EDIT_DATASET);
  };

  const onDatasetNameChange = (event) => {
    setDatasetName(event.target.value);
  };

  const onGroupChange = (value) => {
    setGroupsValue(value);
  };

  const prepareGroups = useCallback(
    (permissions) => {
      const result = [];
      permissions.forEach((permission) => result.push(groupOptions.find((item) => item.label === permission.name)));
      if (result.findIndex((item) => item.label === 'Admins') === -1) {
        result.unshift(groupOptions.find((item) => item.label === 'Admins'));
      }
      return result;
    },
    [groupOptions]
  );

  const saveDataset = () => async () => {
    const updateData = {
      dataset_id: activeDataset.dataset_id,
      display_name: datasetName,
      groups: groupsValue.map((group) => group.id),
    };
    const response = await updateDataset(updateData);
    if (response.status === 200) {
      toggleModal(MODALS.EDIT_DATASET);
    }
  };

  useEffect(() => {
    if (activeDataset) {
      setDatasetName(activeDataset?.display_name);
      setGroupsValue(prepareGroups(activeDataset?.permissions));
    }
  }, [activeDataset, activeDataset?.display_name, activeDataset?.permissions, prepareGroups]);

  if (!activeDataset) {
    return null;
  }

  return (
    <Modal open={isEditDatasetOpen} onClose={closeModal()}>
      <div className='edit_dataset_modal_container'>
        <div className='edit_dataset_modal_title_container'>
          <Typography variant='title'>Edit Dataset</Typography>
        </div>
        <div className='edit_dataset_modal_content'>
          <Field label='Display name' onChange={onDatasetNameChange} value={datasetName} />
          <div className='edit_dataset_modal_group_container'>
            <Typography variant='small_title'>Group permissions</Typography>
            <Dropdown
              className='edit_dataset_modal_dropdown'
              disabledChip='Admins'
              label='Groups'
              onChange={onGroupChange}
              options={groupOptions}
              value={groupsValue}
              variant='autocomplete_multiple'
            />
          </div>
          <div className='edit_dataset_modal_button_container'>
            <Button className='edit_dataset_modal_cancel' onClick={closeModal()} variant='outlined'>
              Cancel
            </Button>
            <Button className='edit_dataset_modal_save' onClick={saveDataset()} variant='contained'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default EditDatasetModal;
