import { useState } from 'react';
import { observer } from 'mobx-react';
import S3 from 'aws-sdk/clients/s3';

import { Collapse, Dropdown } from 'components/common';
import Button from '@mui/material/Button';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { downloadFile, isEmpty } from 'helpers';
import { useStore } from 'store';

import './ExperimentsSidebar.scss';

const ExperimentsSidebar = observer(
  ({ geneSearch, links, onGeneSearch, onReset, openAddToFavoritesModal, onCopyLink }) => {
    const { ExperimentStore, GeneListStore, LoadingStore } = useStore();
    const {
      cleanOnComparisonChange,
      clearOnDatasetChange,
      cleanOnnDatatypeChange,
      cleanOnSpeciesChange,
      options,
      setValues,
      values,
    } = ExperimentStore;
    const { geneListsOptions } = GeneListStore;
    const { setExternalLoading } = LoadingStore;

    const [datasetSearch, setDatasetSearch] = useState('');

    const onDatasetSearch = (value) => {
      setDatasetSearch(value);
    };

    const onSpeciesChange = (event, field) => {
      cleanOnSpeciesChange();
      setValues(event, field);
    };

    const onDatatypeChange = (event, field) => {
      cleanOnnDatatypeChange();
      setValues(event.target.value, field);
    };

    const onDatasetChange = (event, field) => {
      clearOnDatasetChange();
      setValues(event, field);
    };

    const onComparisonChange = (event, field) => {
      if (isEmpty(event)) {
        cleanOnComparisonChange();
      }
      setValues(event, field);
    };

    const onGeneListChange = (event, field) => {
      setValues(event.target.value, field);
    };

    const onLinkDownload = (bucket, key, name) => () => {
      const s3 = new S3();
      s3.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: 'us-west-2',
      });
      const params = {
        Bucket: bucket,
        Key: key,
      };
      const fileExtention = key.split('.')[1];
      setExternalLoading();
      s3.getObject(params, (err, data) => {
        if (err) {
          console.error(err, err.stack);
          setExternalLoading();
        } else {
          setExternalLoading();
          downloadFile(data.Body, name + '.' + fileExtention);
        }
      });
    };

    const renderLinks = () => {
      return links.map((link, index) => {
        if (!link.hasOwnProperty('bucket')) {
          return (
            <a className='experiments_sidebar_link' href={link.path} key={index} rel='noreferrer' target='_blank'>
              {link.label}
              <ArrowOutwardIcon color='primary' />
            </a>
          );
        }
        return (
          <span
            className='experiments_sidebar_link'
            key={index}
            onClick={onLinkDownload(link.bucket, link.path, link.label)}
          >
            {link.label}
            <ArrowOutwardIcon color='primary' />
          </span>
        );
      });
    };

    return (
      <div className='experiments_sidebar_container'>
        <div className='experiments_sidebar_title_container'>
          <Typography className='experiments_sidebar_title' variant='title'>
            Experiment
          </Typography>
          <IconButton onClick={() => onCopyLink()}>
            <ContentCopyIcon color='primary' />
          </IconButton>
        </div>
        <Dropdown
          field='species'
          label='Species'
          variant='autocomplete_multiple'
          onChange={onSpeciesChange}
          options={options.species}
          value={values.species}
        />
        <Dropdown
          field='datatype'
          label='Data Type'
          onChange={onDatatypeChange}
          options={options.datatype}
          value={values.datatype}
        />
        <Dropdown
          field='dataset'
          groupBy={(option) => option.species}
          inputValue={datasetSearch}
          label='Dataset'
          onChange={onDatasetChange}
          onInputChange={onDatasetSearch}
          options={options.dataset}
          renderOption={(props, option, ownerState) => (
            <Tooltip key={ownerState.index} title={option.description} arrow placement='left'>
              <li {...props}>{option.label}</li>
            </Tooltip>
          )}
          value={values.dataset}
          variant='autocomplete_single'
        />
        <Dropdown
          field='comparisons'
          max={3}
          label='Comparisons'
          variant='autocomplete_multiple'
          onChange={onComparisonChange}
          options={options.comparisons}
          value={values.comparisons}
        />
        {values.datatype !== 'CRISPR (pooled)' && (
          <Collapse title='Expression Plot'>
            <Dropdown
              field='expressionGenes'
              inputValue={geneSearch.expressionGenes}
              label='Genes - Expression Plot'
              max={3}
              onChange={setValues}
              onInputChange={onGeneSearch}
              optionLabel='name'
              options={options.expressionGenes}
              value={values.expressionGenes}
              variant='autocomplete_multiple'
            />
          </Collapse>
        )}

        <Collapse title='Annotations'>
          <Dropdown
            field='volcanoGenes'
            inputValue={geneSearch.volcanoGenes}
            label='Genes - Volcano Plot'
            variant='autocomplete_multiple'
            onChange={setValues}
            onInputChange={onGeneSearch}
            optionLabel='name'
            options={options.volcanoGenes}
            value={values.volcanoGenes}
          />
          <Dropdown
            field='geneList'
            label='Annotate with Gene List'
            onChange={onGeneListChange}
            options={geneListsOptions}
            value={values.geneList}
          />
        </Collapse>
        <Collapse title='Links'>
          <div className='experiments_sidebar_link_container'>{links && renderLinks()}</div>
        </Collapse>
        <div className='experiments_sidebar_actions'>
          {/* <Button
            className='experiments_sidebar_favorite'
            color='primary'
            onClick={() => openAddToFavoritesModal()}
            startIcon={<StarBorderIcon />}
          >
            Add
          </Button> */}
          <Button
            className='experiments_sidebar_reset'
            color='primary'
            onClick={() => onReset()}
            startIcon={<CloseIcon />}
          >
            Reset
          </Button>
        </div>
      </div>
    );
  }
);

export default ExperimentsSidebar;
