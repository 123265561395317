import { createContext, useContext } from 'react';

import DatasetStore from './admin/datasets';
import GroupStore from './admin/groups';
import UserStore from './admin/users';

import ExperimentStore from './main/experiments';
import GeneStore from './main/genes';
import FourWayStore from './main/four_way';
import MultigeneStore from './main/multigene';
import TargetGenesStore from './main/target_genes';
import TargetSnpStore from './main/target_snp';

import AuthStore from './utils/auth';
import GeneListStore from './utils/gene_list';
import LoadingStore from './utils/loading';
import ModalStore from './utils/modals';
import SnackStore from './utils/snacks';

const store = {
  DatasetStore: new DatasetStore(),
  GroupStore: new GroupStore(),
  UserStore: new UserStore(),

  ExperimentStore: new ExperimentStore(),
  GeneStore: new GeneStore(),
  FourWayStore: new FourWayStore(),
  MultigeneStore: new MultigeneStore(),
  TargetGenesStore: new TargetGenesStore(),
  TargetSnpStore: new TargetSnpStore(),

  AuthStore: new AuthStore(),
  GeneListStore: new GeneListStore(),
  LoadingStore: new LoadingStore(),
  ModalStore: new ModalStore(),
  SnackStore: new SnackStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};

export default store;
