import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Confirmation from 'components/common/modals/confirmation/Confirmation';

import { MODALS } from 'constants';
import { debounce } from 'helpers';
import { useStore } from 'store';

import './DatasetsTable.scss';

const DatasetsTable = observer(() => {
  const { DatasetStore, ModalStore } = useStore();
  const { datasets, datasetSearch, deleteDataset, setActiveDataset } = DatasetStore;
  const { isEditDatasetOpen, toggleModal } = ModalStore;

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [renderData, setRenderData] = useState([]);

  const onSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const openEditModal = (datasetId) => () => {
    setActiveDataset(datasetId);
    toggleModal(MODALS.EDIT_DATASET);
  };

  const openConfirmationModal = (datasetId) => () => {
    setActiveDataset(datasetId);
    toggleModal(MODALS.CONFIRMATION);
  };

  const onDeleteDataset = (id) => () => {
    deleteDataset(id);
    toggleModal(MODALS.CONFIRMATION);
  };

  const prepareData = useCallback((data, search, sortingDirection, sortedColumn) => {
    const dataCopy = [...Object.values(data)];
    const filteredDatasets = dataCopy.filter((dataset) => dataset.display_name.toLowerCase().includes(search));
    if (
      typeof parseFloat(filteredDatasets[0]?.[sortedColumn]) === 'number' &&
      !isNaN(filteredDatasets[0]?.[sortedColumn])
    ) {
      if (sortingDirection === 'asc') {
        filteredDatasets.sort((a, b) => a[sortedColumn] - b[sortedColumn]);
      } else {
        filteredDatasets.sort((a, b) => b[sortedColumn] - a[sortedColumn]);
      }
    } else {
      if (sortingDirection === 'asc') {
        filteredDatasets.sort((a, b) => a[sortedColumn].localeCompare(b[sortedColumn]));
      } else {
        filteredDatasets.sort((a, b) => b[sortedColumn].localeCompare(a[sortedColumn]));
      }
    }
    setRenderData(filteredDatasets);
  }, []);

  const debouncedPrepareData = useMemo(() => debounce(prepareData, 300), [prepareData]);

  const renderPermissions = (permissions) => {
    return permissions.map((item) => item.name).join(', ');
  };

  const renderStatus = (status) => {
    return <span className={`dataset_table_status_${status}`}>{status}</span>;
  };

  const renderActions = (status, datasetId, id) => {
    if (status !== 'ERROR') {
      return (
        <>
          <Button
            className='dataset_table_edit'
            color='primary'
            onClick={openEditModal(datasetId)}
            startIcon={<EditIcon color='primary' />}
          >
            Edit
          </Button>
          {id && (
            <Button
              className='dataset_table_delete'
              color='error'
              onClick={openConfirmationModal(id)}
              startIcon={<DeleteIcon color='error' />}
            >
              Delete
            </Button>
          )}
        </>
      );
    }
  };

  const renderDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString('en-US');
  };

  useEffect(() => {
    if (!isEditDatasetOpen) {
      debouncedPrepareData(datasets, datasetSearch, order, orderBy);
    }
  }, [isEditDatasetOpen, datasets, datasetSearch, order, orderBy, debouncedPrepareData]);

  return (
    <>
      <Confirmation
        confirmText='Delete'
        onConfirm={onDeleteDataset()}
        title='Are you sure you want to delete this record?'
      />
      <TableContainer className='dataset_table_container' component={Paper}>
        <Table className='dataset_table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'dataset_name'}
                  direction={orderBy === 'dataset_name' ? order : 'asc'}
                  onClick={onSort('dataset_name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'display_name'}
                  direction={orderBy === 'display_name' ? order : 'asc'}
                  onClick={onSort('display_name')}
                >
                  Display Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'owner'}
                  direction={orderBy === 'owner' ? order : 'asc'}
                  onClick={onSort('owner')}
                >
                  Owner
                </TableSortLabel>
              </TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell align='center'>
                <TableSortLabel
                  active={orderBy === 'task_status'}
                  direction={orderBy === 'task_status' ? order : 'asc'}
                  onClick={onSort('task_status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel
                  active={orderBy === 'updated_at'}
                  direction={orderBy === 'updated_at' ? order : 'asc'}
                  onClick={onSort('updated_at')}
                >
                  Last update
                </TableSortLabel>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData.map((row, index) => {
              return (
                <TableRow className='dataset_table_row' hover key={index}>
                  <TableCell>{row.dataset_name}</TableCell>
                  <TableCell>{row.display_name}</TableCell>
                  <TableCell>{row.owner}</TableCell>
                  <TableCell>{renderPermissions(row.permissions)}</TableCell>
                  <TableCell align='center'>{renderStatus(row.task_status)}</TableCell>
                  <TableCell>{renderDate(row.updated_at)}</TableCell>
                  <TableCell align='right' className='dataset_table_action_cell'>
                    {renderActions(row.task_status, row.dataset_id, row.id)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default DatasetsTable;
