import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';

import { Field } from 'components/common';

import { DEFAULT_VOLCANO_AXIS, VOLCANO_PARAMS } from 'constants';
import { updateVolcano } from 'helpers';
import { useStore } from 'store';

import './VolcanoParams.scss';

const VolcanoParams = observer(({ volcanoId }) => {
  const { ExperimentStore } = useStore();
  const { setVolcano, volcanoData, clearVolcanoPlot } = ExperimentStore;

  const [paramValues, setParamValues] = useState({
    detection_level: '0',
    effect_size_bounds: '1',
    significance_threshold: '4',
  });

  const volcano = volcanoData[volcanoId];
  const { figure, sliders, detection_lvl } = volcano;

  const onClearVolcanoClick = () => () => {
    setParamValues(DEFAULT_VOLCANO_AXIS);
    clearVolcanoPlot(volcanoId);
  };

  const onFieldChange = (field) => (event) => {
    setParamValues({ ...paramValues, [field]: event.target.value });
  };

  const renderInputs = () => {
    const renderData = Object.entries(sliders);
    return renderData.map(([paramName, params], index) => (
      <Field
        className='volcano_param_input'
        key={index}
        label={`${VOLCANO_PARAMS[paramName]} (max value: ${params.max})`}
        onChange={onFieldChange(paramName)}
        type='number'
        value={paramValues[paramName]}
      />
    ));
  };

  const updatePlot = () => {
    const updatedVolcano = updateVolcano(
      figure,
      Number(paramValues.detection_level),
      Number(paramValues.effect_size_bounds),
      Number(paramValues.significance_threshold),
      detection_lvl
    );
    setVolcano(updatedVolcano, volcanoId);
  };

  useEffect(() => {
    updatePlot();
    // eslint-disable-next-line
  }, [paramValues]);

  return (
    <div className='volcano_params_container'>
      <div>{renderInputs()}</div>
      <div>
        <Button color='primary' onClick={onClearVolcanoClick()} variant='outlined'>
          Clear selection
        </Button>
      </div>
    </div>
  );
});

export default VolcanoParams;
