import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Dropdown, Field } from 'components/common';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './EditGroupModal.scss';

const EditGroupModal = observer(() => {
  const { GroupStore, ModalStore, UserStore } = useStore();
  const { activeGroup, updateGroup } = GroupStore;
  const { isEditGroupOpen, toggleModal } = ModalStore;
  const { userOptions } = UserStore;

  const [groupName, setGroupName] = useState('');
  const [usersValue, setUsersValue] = useState([]);

  const closeModal = () => () => {
    toggleModal(MODALS.EDIT_GROUP);
  };

  const onGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const onUsersChange = (value) => {
    setUsersValue(value);
  };

  const prepareUsers = useCallback(
    (users) => {
      const result = [];
      users.forEach((user) => result.push(userOptions.find((item) => item.id === user.id)));
      return result;
    },
    [userOptions]
  );

  const saveGroup = () => async () => {
    const updateData = {
      ...activeGroup,
      name: groupName,
      users: usersValue.map((user) => user.id),
      datasets: activeGroup.datasets.map((dataset) => dataset.id),
    };
    const response = await updateGroup(updateData);
    if (response.status === 200) {
      toggleModal(MODALS.EDIT_GROUP);
    }
  };

  useEffect(() => {
    if (activeGroup) {
      setGroupName(activeGroup.name);
      setUsersValue(prepareUsers(activeGroup.users));
    }
  }, [activeGroup, prepareUsers]);

  return (
    <Modal open={isEditGroupOpen} onClose={closeModal()}>
      <div className='edit_group_modal_container'>
        <div className='edit_group_modal_title_container'>
          <Typography variant='title'>Edit group</Typography>
        </div>
        <div className='edit_group_modal_content'>
          <Field label='Group name' onChange={onGroupNameChange} value={groupName} />
          <Typography variant='small_title'>Group users</Typography>
          <div className='edit_group_modal_collapse_container'>
            <Dropdown
              className='edit_group_modal_dropdown'
              label='Select user'
              onChange={onUsersChange}
              options={userOptions}
              value={usersValue}
              variant='autocomplete_multiple'
            />
          </div>
          <div className='edit_group_modal_button_container'>
            <Button className='edit_group_modal_cancel' onClick={closeModal()} variant='outlined'>
              Cancel
            </Button>
            <Button className='edit_group_modal_save' onClick={saveGroup()} variant='contained'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default EditGroupModal;
