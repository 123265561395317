import { GeneListIcon } from 'static/icons';

import { COLORS } from 'constants';
import { useStore } from 'store';

import './GeneListButton.scss';

const GeneListButton = () => {
  const { ModalStore } = useStore();
  const { toggleGeneList } = ModalStore;
  return (
    <button className='gene_list_button' onClick={() => toggleGeneList()}>
      <GeneListIcon color={COLORS.MAIN} />
    </button>
  );
};

export default GeneListButton;
