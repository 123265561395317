const _getShape = (figure, name) => {
  for (const shape of figure.layout.shapes) {
    if (shape.name === name) {
      return shape;
    }
  }
  return null;
};

const _makeThresholdShape = (figure, threshold) => {
  const shapeName = 'genomewide_line';
  const shape = _getShape(figure, shapeName);
  return Object.assign({}, shape, {
    y0: threshold,
    y1: threshold,
  });
};

const _makeEffectSizeLeftShape = (figure, leftBound) => {
  const shapeName = 'effect size min line';
  const shape = _getShape(figure, shapeName);
  return Object.assign({}, shape, {
    x0: leftBound,
    x1: leftBound,
    y0: figure.layout.yaxis?.range?.[0],
    y1: figure.layout.yaxis?.range?.[1],
  });
};

const _makeEffectSizeRightShape = (figure, rightBound) => {
  const shapeName = 'effect size max line';
  const shape = _getShape(figure, shapeName);
  return Object.assign({}, shape, {
    x0: rightBound,
    x1: rightBound,
    y0: figure.layout.yaxis?.range?.[0],
    y1: figure.layout.yaxis?.range?.[1],
  });
};

const updateShapes = (figure, effectSize, threshold) => {
  const [leftBound, rightBound] = [-effectSize, effectSize];
  const thresholdShape = _makeThresholdShape(figure, threshold);
  const leftBoundShape = _makeEffectSizeLeftShape(figure, leftBound);
  const rightBoundShape = _makeEffectSizeRightShape(figure, rightBound);
  const clonedLayout = Object.assign({}, figure.layout, {
    shapes: [thresholdShape, leftBoundShape, rightBoundShape],
    showlegend: true,
    legend: {
      orientation: 'h',
      bgcolor: '#f2f5fa',
      x: 1,
      xanchor: 'right',
      y: 1.02,
      yanchor: 'bottom',
    },
  });
  return Object.assign({}, figure, {
    layout: clonedLayout,
  });
};

const prepareGenesForGeneLists = (list) => {
  return list.map((gene) => gene.split(' ')[1]);
};

const _createTrace = (color, name, markerSize, visible) => {
  return {
    marker: {
      color: color,
      size: markerSize,
    },
    mode: 'markers',
    name: name,
    text: [],
    x: [],
    y: [],
    type: 'scattergl',
    visible: visible,
  };
};

const _getData = (figure, color) => {
  for (const trace of figure.data) {
    if (trace.marker.color === color) {
      trace.name = color;
      return trace;
    }
  }
  return null;
};

const _getOrCreateData = (figure, color, name, markerSize, visible) => {
  const trace = _getData(figure, color);
  if (!!trace) {
    trace.name = name;
    return trace;
  }
  return _createTrace(color, name, markerSize, visible);
};

const updateData = (figure, detectionLevel, effectSize, threshold, detectionLevelData) => {
  const [leftBound, rightBound] = [-effectSize, effectSize];
  const redData = _getOrCreateData(figure, 'red', 'red', 7, true);
  const blueData = _getOrCreateData(figure, 'rgb(30,144,255)', 'blue', 7, true);
  const blackData = _getOrCreateData(figure, 'black', 'black', 7, true);
  const hiddenData = _getOrCreateData(figure, 'rgb(240 255 255)', 'hidden', 7, false);
  const currentX = redData.x.concat(blackData.x, blueData.x, hiddenData.x);
  const currentY = redData.y.concat(blackData.y, blueData.y, hiddenData.y);
  const currentText = redData.text.concat(blackData.text, blueData.text, hiddenData.text);
  const newBlackX = [];
  const newBlackY = [];
  const newBlackText = [];
  const newRedX = [];
  const newRedY = [];
  const newRedText = [];
  const newBlueX = [];
  const newBlueY = [];
  const newBlueText = [];
  const newHiddenX = [];
  const newHiddenY = [];
  const newHiddenText = [];
  const size = Math.max(currentX.length, currentY.length, currentText.length);
  for (let i = 0; i < size; i++) {
    const [x, y, text] = [currentX[i], currentY[i], currentText[i]];
    // const dtl = detectionLevel ? detectionLevel[i] : 1;
    const isVisible = detectionLevel <= detectionLevelData[i];
    if (y >= threshold && (x <= leftBound || (x >= rightBound && x < 0)) && isVisible) {
      newBlueX.push(x);
      newBlueY.push(y);
      newBlueText.push(text);
    } else if (y >= threshold && (x <= leftBound || x >= rightBound) && isVisible) {
      newRedX.push(x);
      newRedY.push(y);
      newRedText.push(text);
    } else if (isVisible) {
      newBlackX.push(x);
      newBlackY.push(y);
      newBlackText.push(text);
    } else if (!isVisible) {
      newHiddenX.push(x);
      newHiddenY.push(y);
      newHiddenText.push(text);
    }
  }
  const newRedData = Object.assign({}, redData, {
    x: newRedX,
    y: newRedY,
    text: newRedText,
  });
  const newBlackData = Object.assign({}, blackData, {
    x: newBlackX,
    y: newBlackY,
    text: newBlackText,
  });
  const newBlueData = Object.assign({}, blueData, {
    x: newBlueX,
    y: newBlueY,
    text: newBlueText,
  });
  const newHiddenData = Object.assign({}, hiddenData, {
    x: newHiddenX,
    y: newHiddenY,
    text: newHiddenText,
  });
  const updatedData = Object.assign({}, figure, {
    data: [newRedData, newBlackData, newBlueData, newHiddenData],
    layout: { ...figure.layout, autosize: true, autorange: true, dragmode: 'select' },
  });
  return [updatedData, prepareGenesForGeneLists(newBlueData.text), prepareGenesForGeneLists(newRedData.text)];
};

const updateVolcano = (figure, detectionLevel, effectSize, threshold, detectionLevelData = []) => {
  const formattedShapes = updateShapes(figure, effectSize, threshold);
  const [updatedFigure] = updateData(formattedShapes, detectionLevel, effectSize, threshold, detectionLevelData);
  return updatedFigure;
};

export default updateVolcano;
