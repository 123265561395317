import { observer } from 'mobx-react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Field } from 'components/common';

import { useStore } from 'store';
import { MODALS } from 'constants';

import './AddExperimentToFavorites.scss';

const AddExperimentToFavorites = observer(() => {
  const { ModalStore } = useStore();
  const { isAddExperimentOpen, toggleModal } = ModalStore;

  return (
    <Modal open={isAddExperimentOpen} onClose={() => toggleModal(MODALS.ADD_EXPERIMENT)}>
      <div>
        <div className='experiment_favorites_modal'>
          <div className='experiment_favorites_modal_title_container'>
            <Typography variant='title'>Add to Favorites</Typography>
          </div>
          <div className='experiment_favorites_modal_content'>
            <Field className='experiment_favorites_modal_name' label='Experiment name' />
          </div>
          <div className='experiment_favorites_modal_button_container'>
            <Button
              className='experiment_favorites_modal_cancel'
              onClick={() => toggleModal(MODALS.ADD_EXPERIMENT)}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button className='experiment_favorites_modal_save' variant='contained'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default AddExperimentToFavorites;
