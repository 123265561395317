import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Link } from 'react-router-dom';

import './Breadcrumbs.scss';

const BreadcrumbsComponent = ({ crumbs }) => {
  return (
    <div className='breadcrumbs_container'>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
        {crumbs.map((crumb) => {
          if (crumb.hasOwnProperty('link')) {
            return (
              <Link key={crumb.text} className='breadcrumbs_active_link' to={crumb.link}>
                {crumb.text}
              </Link>
            );
          }
          return (
            <Typography key={crumb.text} className='breadcrums_current_link'>
              {crumb.text}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsComponent;
