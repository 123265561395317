import { useState } from 'react';
import { observer } from 'mobx-react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { Collapse, Dropdown } from 'components/common';

import { MODALS } from 'constants';
import { useStore } from 'store';

import './UploadDatasetModal.scss';

const UploadDatasetModal = observer(() => {
  const { DatasetStore, ModalStore } = useStore();
  const { checkCovariatesMapping, getCovariates, uploadDataset, uploadOptions } = DatasetStore;
  const { isUploadDatasetOpen, toggleModal } = ModalStore;

  const [covariateOptions, setCovariateOptions] = useState([
    {
      id: 'empty',
      label: 'empty',
    },
  ]);
  const [covariateValues, setCovariateValues] = useState({
    age: 'empty',
    cell_type: 'empty',
    cluster_name: 'empty',
    donor: 'empty',
    genotype: 'empty',
    lane: 'empty',
    organism_id: 'empty',
    plot_name: 'empty',
    primary: 'empty',
    sample_id: 'empty',
    tissue: 'empty',
    treatment: 'empty',
  });
  const [isMappingChecked, setIsMappingChecked] = useState(false);
  const [uploadValues, setUploadValues] = useState({
    datatype: [],
    dataset: [],
    probes: [],
    species: [],
  });

  const closeModal = () => () => {
    reset();
    toggleModal(MODALS.UPLOAD_DATASET);
  };

  const getCovariateData = async (dataset) => {
    const covariateData = await getCovariates(dataset);
    setCovariateOptions(covariateData?.data?.source_fields?.map((field) => ({ id: field, label: field })));
    const predictions = Object.entries(covariateData?.data?.fields_prediction);
    const formattedPredictions = {};
    for (const prediction of predictions) {
      if (Array.isArray(prediction[1])) {
        formattedPredictions[prediction[0]] = prediction[1][0];
      } else {
        formattedPredictions[prediction[0]] = prediction[1];
      }
    }
    setCovariateValues(formattedPredictions);
  };

  const onCheckMapping = async () => {
    const response = await checkCovariatesMapping(uploadValues.dataset, uploadValues.datatype);
    if (response.status === 200) {
      setIsMappingChecked(true);
    }
  };

  const onUploadDropdownChange = async (event, field) => {
    setUploadValues({ ...uploadValues, [field]: event.target.value });
    // Get covariates data when dataset is selected
    if (field === 'dataset') {
      await getCovariateData(event.target.value);
    }
  };

  const onMappingDropdownChange = (event, field) => {
    setCovariateValues({ ...covariateValues, [field]: event.target.value });
  };

  const onUpload = async () => {
    if (!uploadValues === 3 && !isMappingChecked) {
      return;
    }
    const uploadData = {
      upload_to_s3: true,
      experimental_data_type: uploadValues.datatype.toString(),
      dropdown_label: uploadValues.dataset,
      s3_files: {
        genes_data_path: uploadValues.probes,
      },
      species: uploadValues.species,
      covariates_map: covariateValues,
    };
    const response = await uploadDataset(uploadData);
    if (response.status === 200 || response.status === 202) {
      reset();
      toggleModal(MODALS.UPLOAD_DATASET);
    }
  };

  const renderMappingButton = () => {
    if (uploadValues.datatype === 3) {
      return null;
    }
    if (isMappingChecked) {
      return (
        <Button className='upload_dataset_modal_checked' disabled startIcon={<CheckCircleIcon />}>
          Checked
        </Button>
      );
    }
    return (
      <Button
        className='upload_dataset_modal_check'
        onClick={() => onCheckMapping()}
        startIcon={<ArrowForwardIosIcon />}
        variant='contained'
      >
        Check Expression Mappings
      </Button>
    );
  };

  const reset = () => {
    setCovariateOptions([
      {
        id: 'empty',
        label: 'empty',
      },
    ]);
    setCovariateValues({
      age: 'empty',
      cell_type: 'empty',
      cluster_name: 'empty',
      donor: 'empty',
      genotype: 'empty',
      lane: 'empty',
      organism_id: 'empty',
      plot_name: 'empty',
      primary: 'empty',
      sample_id: 'empty',
      tissue: 'empty',
      treatment: 'empty',
    });
    setUploadValues({
      datatype: [],
      dataset: [],
      probes: [],
      species: [],
    });
    setIsMappingChecked(false);
  };

  return (
    <Modal open={isUploadDatasetOpen} onClose={closeModal()}>
      <div className='upload_dataset_modal_container'>
        <div className='upload_dataset_modal_title_container'>
          <Typography variant='title'>Upload Dataset</Typography>
        </div>
        <div className='upload_dataset_modal_content'>
          <Dropdown
            field='datatype'
            label='Datatype'
            onChange={onUploadDropdownChange}
            options={uploadOptions?.datatype}
            value={uploadValues?.datatype}
          />
          <Dropdown
            field='dataset'
            label='Dataset'
            onChange={onUploadDropdownChange}
            options={uploadOptions?.dataset}
            value={uploadValues?.dataset}
          />
          <Dropdown
            field='probes'
            label='Probes file'
            onChange={onUploadDropdownChange}
            options={uploadOptions?.probes}
            value={uploadValues?.probes}
          />
          <Dropdown
            field='species'
            label='Species'
            onChange={onUploadDropdownChange}
            options={uploadOptions?.species}
            value={uploadValues?.species}
          />
          <Collapse title='Assign Covariate Mappings'>
            <div className='upload_dataset_modal_collapse_container'>
              <Dropdown
                className='upload_dataset_modal_dropdown'
                field='cell_type'
                label='cell_type'
                onChange={onMappingDropdownChange}
                options={covariateOptions}
                value={covariateValues.cell_type}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                field='primary'
                label='primary'
                onChange={onMappingDropdownChange}
                options={covariateOptions}
                value={covariateValues.primary}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                field='treatment'
                label='treatment'
                onChange={onMappingDropdownChange}
                options={covariateOptions}
                value={covariateValues.treatment}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                field='donor'
                label='donor'
                onChange={onMappingDropdownChange}
                options={covariateOptions}
                value={covariateValues.donor}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='sample_id'
                label='sample_id'
                options={covariateOptions}
                value={covariateValues.sample_id}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='organism_id'
                label='organism_id'
                options={covariateOptions}
                value={covariateValues.organism_id}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='plot_name'
                label='plot_name'
                options={covariateOptions}
                value={covariateValues.plot_name}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='tissue'
                label='tissue'
                options={covariateOptions}
                value={covariateValues.tissue}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='genotype'
                label='genotype'
                options={covariateOptions}
                value={covariateValues.genotype}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='age'
                label='age'
                options={covariateOptions}
                value={covariateValues.age}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='lane'
                label='lane'
                options={covariateOptions}
                value={covariateValues.lane}
              />
              <Dropdown
                className='upload_dataset_modal_dropdown'
                onChange={onMappingDropdownChange}
                field='cluster_name'
                label='cluster_name'
                options={covariateOptions}
                value={covariateValues.cluster_name}
              />
            </div>
          </Collapse>
          {renderMappingButton()}
          <div className='upload_dataset_modal_button_container'>
            <Button className='upload_dataset_modal_cancel' onClick={closeModal()} variant='outlined'>
              Cancel
            </Button>
            <Button className='upload_dataset_modal_save' onClick={() => onUpload()} variant='contained'>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default UploadDatasetModal;
